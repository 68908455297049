import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

import splashUrl from './assets/splash.svg'
import logo from '../../assets/icons/logo.svg'
import { useNavbarTabLayout } from '../../providers/LayoutTabProvider'
import { UserMenu } from './UserMenu'

const Navbar = ({ noMenu }) => {
  const auth = useAuth()
  const navigate = useNavigate()

  const { tabs, isSelected, setIsSelected } = useNavbarTabLayout()
  const showTab = tabs !== null

  return (
    <nav className='sticky top-0 z-20 bg-white-bg pl-9 py-5 px-6 flex gap-10 items-center justify-between flex-wrap drop-shadow-[0px_4px_10px_rgba(197,197,197,0.15)]'>
      <Link to='/'>
        <img src={logo} alt='logo' />
      </Link>

      {auth.isAuthenticated && showTab && (
        <div className='hidden xs:flex gap-6 items-center px-3 leading-6'>
          {Object.keys(tabs).map((tab, i) => {
            const { label, pathUrl } = tabs[tab]
            return (
              <div
                key={i}
                className={classNames(
                  'relative text-center text-base cursor-pointer font-medium bg-cover',
                  isSelected === tab && 'text-primary-700 w-24',
                  isSelected !== tab && 'text-[#D5D3D9]',
                )}
                onClick={() => {
                  setIsSelected(tab)
                  if (pathUrl) navigate(pathUrl)
                }}
              >
                {isSelected === tab && (
                  <img
                    src={splashUrl}
                    alt=''
                    className='absolute inset-0 -z-10 -translate-y-1/4 scale-110 pointer-events-none'
                  />
                )}
                <span className='relative'>{label}</span>
              </div>
            )
          })}
        </div>
      )}
      {auth.isAuthenticated && !noMenu && <UserMenu />}
    </nav>
  )
}

export default Navbar
