import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as EditIcon } from './assets/edit.svg'
import { ReactComponent as DownIcon } from '../../../../assets/icons/down.svg'
import { getDifficultyLevels } from '../PracticeSkills/ProblemAssessment/utils'
import { CARREER_PROBLEMS_DIFFICULTIES_COLORS } from './utils'

export const CARD_MIN_WIDTH = 290
export const CARD_MAX_HEIGHT = 240

const difficulties = getDifficultyLevels()

export const ProblemItem = ({ problem, onEdit = () => {} }) => {
  const notesContainerRef = useRef()
  const editButtonRef = useRef()
  const linkRef = useRef()
  const expandRef = useRef()
  const [showMore, setShowMore] = useState(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const resizeCallback = () => {
      const el = notesContainerRef.current
      setShowMore(expanded || el.offsetHeight < el.scrollHeight)
    }
    // initial run
    resizeCallback()

    window.addEventListener('resize', resizeCallback)
    return () => window.removeEventListener('resize', resizeCallback)
    /** Keep problem as a dependency, if the text contents change, then
     * it needs to validate if it should be expandable or not */
  }, [expanded, problem.name, problem.notes])

  const handleExpandClick = event => {
    const { target } = event
    // don't change state if everything isn't overflowing
    if (!showMore) return
    // dont trigger state change if other valid click events trigger
    if (editButtonRef.current.contains(target) || linkRef.current.contains(target)) return
    // toggle the expanded state
    setExpanded(exp => !exp)
  }
  const handleKeyUp = event => {
    const { target } = event
    // don't change state if everything isn't overflowing
    if (!showMore) return
    // dont trigger state change if other valid click events trigger
    if (editButtonRef.current.contains(target) || linkRef.current.contains(target)) return
    //check if the input is enter code related
    if (event.code !== 'Enter' && event.code !== 'NumpadEnter' && event.code !== 'Space')
      return
    // toggle the expanded state
    setExpanded(exp => !exp)
  }
  const handleKeyDown = event => {
    //check if the input is enter code related
    if (event.code !== 'Enter' && event.code !== 'NumpadEnter' && event.code !== 'Space')
      return
    event.preventDefault()
  }

  const dateCreated = new Date(problem.createdAt).toLocaleDateString(undefined, {
    dateStyle: 'short',
  })
  const difficultyCode = problem.difficulty && problem.difficulty.toUpperCase()

  return (
    <div
      role='gridcell'
      tabIndex={0}
      onClick={handleExpandClick}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      className={classNames(
        `group/problem flex flex-col gap-2.5 min-w-[${CARD_MIN_WIDTH}px]`,
        'rounded-xl bg-gradient-to-r from-primary-600/10 to-primary-600/10 bg-white',
        'p-5 shadow-md shadow-primary-700/20 text-xs',
        !expanded && 'max-h-60',
      )}
    >
      <div className='flex flex-wrap items-baseline font-sans font-medium tracking-tight text-base'>
        <span className='grow'>{problem.name}</span>
        <button
          onClick={onEdit}
          ref={editButtonRef}
          className='text-text-500 invisible group-hover/problem:visible group-focus-within/problem:visible p-1.5 rounded-full hover:bg-black/10 focus-within:bg-black/10'
        >
          <EditIcon />
        </button>
        {showMore && (
          <button
            ref={expandRef}
            className='text-text-500 invisible group-hover/problem:visible group-focus-within/problem:visible p-1.5 rounded-full hover:bg-black/10 focus-within:bg-black/10'
          >
            <DownIcon className={classNames(expanded && 'rotate-180')} />
          </button>
        )}
      </div>
      <div className='flex flex-col gap-2.5 overflow-hidden' ref={notesContainerRef}>
        <a
          ref={linkRef}
          href={problem.link}
          target='_blank'
          rel='noreferrer'
          className='text-text-link hover:underline text-ellipsis break-words'
        >
          {problem.link}
        </a>
        {difficultyCode ? (
          <div
            className={classNames(
              'self-start inline-block py-2 px-6 rounded-full text-black bg-opacity-10 border',
              CARREER_PROBLEMS_DIFFICULTIES_COLORS[difficultyCode],
            )}
          >
            {difficulties.find(({ value }) => value === difficultyCode)?.label}
          </div>
        ) : null}

        <div className='font-sans font-medium tracking-tight'>Notes</div>
        <p>{problem.notes}</p>
      </div>
      <div className='text-neutral-500'>{dateCreated}</div>
    </div>
  )
}
