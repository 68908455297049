import { useState } from 'react'
import {
  RtfFormat,
  useContentEditableBoxEditor,
} from '../../../inputs/ContentEditableBox'
import { ReactEditor } from 'slate-react'
import { Editor, Transforms } from 'slate'
import { useEffect } from 'react'

export const useUpdateTextEditor = ({ todo, editing }) => {
  const [value, setValue] = useState(
    typeof todo.title === 'string'
      ? [RtfFormat.paragraph([RtfFormat.text(todo.title)])]
      : todo.title,
  )
  const editor = useContentEditableBoxEditor()

  /* Activates the editor text field */
  useEffect(() => {
    if (editing && editor) {
      /* Displays cursor upon activating the editor */
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
      })
      ReactEditor.focus(editor)
    }
  }, [editing, editor])

  return {
    value,
    setValue,
    editor,
  }
}
