import { DAY_IN_MS } from '../../constants'
import { to0001 } from '../../utils/time'

export function getLastWeightInTodoList(todos = []) {
  return todos.length > 0 ? todos[todos.length - 1].weight : 0
}

export function getTodoWeightFromDirectSiblings(todos, index, weightIncrement) {
  if (todos.length === 0) {
    return {
      before: 0,
      newWeight: weightIncrement,
      after: weightIncrement * 2,
    }
  }
  if (index >= todos.length) {
    const lastWeight = getLastWeightInTodoList(todos)
    return {
      before: lastWeight,
      newWeight: lastWeight + weightIncrement,
      after: lastWeight + weightIncrement * 2,
    }
  }

  const before = index >= 0 ? todos[index].weight : 0
  const after =
    index + 1 < todos.length ? todos[index + 1].weight : before + weightIncrement

  /**
   * equivalent to Math.floor((l + h) / 2) but faster
   * ref: https://stackoverflow.com/questions/12125421/why-does-a-shift-by-0-truncate-the-decimal
   */
  const newWeight =
    index === todos.length
      ? getLastWeightInTodoList(todos) + weightIncrement
      : (before + after) >>> 1

  return { before, newWeight, after }
}

export const getTodoDateValue = todo => todo.todoDate || todo.legacyDate || todo.date

export function constructNewDateForTodoInColumn(todo, formId) {
  const header = todo.computedInTodoColumnId
  if (header === formId) return getTodoDateValue(todo)
  if (formId === 'later') return undefined
  return new Date(
    to0001().getTime() +
      // add a millisecond day if it's for tomorrow
      (formId === 'tomorrow' && DAY_IN_MS),
  )
}

export const sortOrderTodoDate = (a, b) => {
  if (getTodoDateValue(a) && getTodoDateValue(b)) {
    const timeA = new Date(getTodoDateValue(a)).getTime()
    const timeB = new Date(getTodoDateValue(b)).getTime()
    return timeA - timeB
  } else if (getTodoDateValue(a)) {
    return -1
  } else if (getTodoDateValue(b)) {
    return 1
  }
}

export const sortOrderTodoWeight = (a, b) => {
  const weightDiff = a.weight - b.weight
  return weightDiff
}

export const sortOrderTodoWeightAndTodoDate = (a, b) => {
  const weightOrdinalOrderDiff = sortOrderTodoWeight(a, b)
  if (weightOrdinalOrderDiff !== 0) return weightOrdinalOrderDiff
  /**
   * 99% of the time, the sort by todoDate will never occur because we rebalance the weights
   * whenever a collision occurs. However, in the off-chance that an write operation fails and their
   * is a collision, then this algorithm will sort by todoDate to ensure todos due sooner show up before
   * todos due later.
   */
  return sortOrderTodoDate(a, b)
}

export const closestDueTodosFirst = () => {
  const today = Date.now()

  return (a, b) => {
    const aDiffToToday = new Date(a.dueDate).getTime() - today
    const bDiffToToday = new Date(b.dueDate).getTime() - today
    return aDiffToToday - bDiffToToday
  }
}
