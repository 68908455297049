import classNames from 'classnames'
import { useEffect, useState, useTransition } from 'react'

import Todos from '../../Todos'
import Agenda from '../../Agenda'
import { Modal } from '../../common/Modal'
import { CalendarConnect } from '../../CalendarSync'
import Calendar from '../../Calendar'
import { FileUploader } from '../../FileUploader'
import useAuth from '../../../hooks/useAuth'
import { TextReminders } from '../../GuidedTour/TextReminders'
import { TooltipButton } from './TooltipButton'
import { useToggleBooleanUserPreference } from '../../../hooks/toggleBooleanUserPreference'
import { isPhoneNumberValid } from '../../../utils/phoneNumber'
import {
  CALENDAR_TAB,
  KEY_CALENDAR_TAB,
  KEY_TODOS_TAB,
  AGENDA_VIEW,
  TODOS_TAB,
  AGENDA_TAB,
  USER_PREFERENCES,
  DISCORD_LINK,
} from '../../../constants'

import waveUrl from './assets/wave.svg'
import uploadUrl from './assets/upload.svg'
import smsTextUrl from './assets/sms-text-notification.svg'
import { ReactComponent as BoardIcon } from './assets/board.svg'
import { ReactComponent as CalendarIcon } from './assets/calendar.svg'
import { ReactComponent as AgendaIcon } from './assets/agenda.svg'
import { ReactComponent as BottomLeftBackground } from './assets/background-1.svg'
import { ReactComponent as TopRightBackground } from './assets/background-2.svg'
import { ReactComponent as DiscordIcon } from './assets/discord.svg'
import { ReactComponent as GoogleCalendarIcon } from '../../../assets/icons/google-calendar.svg'

const tabs = {
  [KEY_TODOS_TAB]: {
    label: TODOS_TAB,
    Component: Todos,
  },
  [KEY_CALENDAR_TAB]: {
    label: CALENDAR_TAB,
    Component: Calendar,
  },
  [AGENDA_VIEW]: {
    label: AGENDA_TAB,
    Component: Agenda,
  },
}

const TodoTabs = () => {
  const { user, initialized } = useAuth()
  const { loading, toggleBooleanUserPreference } = useToggleBooleanUserPreference()
  const [calendarModalOpen, setCalendarModalOpen] = useState(false)
  const [textRemindersModalOpen, setTextRemindersModalOpen] = useState(false)
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false)

  const [, startTransition] = useTransition()
  const [tab, setTab] = useState(KEY_TODOS_TAB)

  const selectTab = tab => {
    startTransition(() => {
      setTab(tab)
    })
  }

  useEffect(() => {
    if (!initialized) return
    // once we initialized the auth state, set the dashboard tab
    // to their default tab
    const { defaultTab = KEY_TODOS_TAB } = user.preferences
    const newSelectedTab = tabs[defaultTab] ? defaultTab : KEY_TODOS_TAB

    selectTab(newSelectedTab)
    // we don't want to sync on user data updates, this is only to load the users
    // default tab on auth loading completed
  }, [initialized])

  const handleToggleIsCalendarSynced = async e => {
    if (user?.googleApiRefreshToken) {
      return await toggleBooleanUserPreference(USER_PREFERENCES.IS_CALENDAR_SYNCED)
    }

    setCalendarModalOpen(true)
    e.preventDefault()
  }

  const handleToggleTextReminders = async event => {
    event.preventDefault()

    if (!isPhoneNumberValid(user.phoneNumber)) {
      return setTextRemindersModalOpen(true)
    }

    return toggleBooleanUserPreference(USER_PREFERENCES.TEXT_WARNINGS_ENABLED)
  }

  const { preferences: { isCalendarSynced, textWarningsEnabled } = {} } = user

  const gcalSyncActive = user?.googleApiRefreshToken && isCalendarSynced

  const gcalTooltipText = gcalSyncActive
    ? `Add due dates to your todos to sync to your ${user.email} Google Calendar.`
    : `Turn on to sync your upcoming deadlines to your ${user.email} Google Calendar`

  const textRemindersText = textWarningsEnabled
    ? `We'll send you text reminders to ${
        user.phoneNumber ?? '(000) 000-000'
      } of upcoming important dates added in the Later tab`
    : `Turn on text reminders to ${
        user.phoneNumber ?? '(000) 000-000'
      } of upcoming important dates added in the Later tab`
  const discordTooltipText = `Access Edlyft’s Discord community of CS students`

  const TabComponent = tabs[tab].Component

  return (
    <>
      <div className='w-full grow lg:px-8 py-4 relative'>
        <BottomLeftBackground className='hidden lg:block absolute bottom-0 left-0' />
        <TopRightBackground className='hidden lg:block absolute top-[-98px] right-0' />
        <div className='flex flex-col gap-10 h-full bg-white-bg/70 mx-4 xs:mx-8 lg:mx-44 relative'>
          <div className='flex flex-wrap gap-3'>
            <div className='grow text-lg max-xs:hidden'>
              <img
                src={waveUrl}
                className='inline-block mr-2 align-text-bottom'
                alt='hand waving'
              />
              {/* <span className=''> */}
              Hi <span className='text-primary-600 font-medium'>{user?.firstName}</span>,
              let's get stuff done today!
              {/* </span> */}
            </div>
            <div className='grow flex flex-wrap items-center justify-start xs:justify-end gap-6 xs:gap-3'>
              <a href={DISCORD_LINK} target='_blank' rel='noopener noreferrer'>
                <TooltipButton
                  className='flex items-center justify-center active:!bg-white-bg-dark'
                  tooltipText={discordTooltipText}
                  onClick={() => {}}
                  delayClosing={false}
                >
                  <DiscordIcon />
                </TooltipButton>
              </a>
              <TooltipButton
                className='flex items-center justify-center active:!bg-white-bg-dark'
                id='syllabus-upload-button'
                onClick={() => setShowFileUploadDialog(true)}
              >
                <img src={uploadUrl} alt='Start importing important due dates' />
              </TooltipButton>
              <TooltipButton
                className='flex items-center justify-center'
                id='google-calendar-button'
                tooltipText={gcalTooltipText}
                active={gcalSyncActive}
                onClick={handleToggleIsCalendarSynced}
                disabled={loading}
              >
                <GoogleCalendarIcon
                  className='w-5 inline-block grow-0 shrink-0'
                  title='google calendar syncing'
                />
              </TooltipButton>
              <TooltipButton
                className='flex items-center justify-center'
                id='text-reminders-button'
                tooltipText={textRemindersText}
                active={textWarningsEnabled}
                onClick={handleToggleTextReminders}
                disabled={loading}
              >
                <img src={smsTextUrl} alt='Manage sms notifications' />
              </TooltipButton>
            </div>
          </div>
          <div className='hidden xs:flex flex-wrap justify-end gap-3 text-sm tracking-tight text-text-700'>
            <button
              onClick={() => selectTab(KEY_TODOS_TAB)}
              className={classNames(
                'flex items-center gap-1 p-2 rounded-md hover:bg-white-bg-light',
                KEY_TODOS_TAB === tab &&
                  '!bg-white-bg-light text-primary-700 font-medium',
              )}
            >
              <BoardIcon className='w-3' title='Kanban board tab' /> Board
            </button>
            <button
              onClick={() => selectTab(KEY_CALENDAR_TAB)}
              className={classNames(
                'flex items-center gap-1 p-2 rounded-md hover:bg-white-bg-light',
                KEY_CALENDAR_TAB === tab &&
                  '!bg-white-bg-light text-primary-700 font-medium',
              )}
            >
              <CalendarIcon className='w-3' title='Calendar tab' /> Calendar
            </button>
            <button
              onClick={() => selectTab(AGENDA_VIEW)}
              className={classNames(
                'flex items-center gap-1 p-2 rounded-md hover:bg-white-bg-light',
                AGENDA_VIEW === tab && '!bg-white-bg-light text-primary-700 font-medium',
              )}
            >
              <AgendaIcon className='w-3' title='Agenda tab' /> Agenda
            </button>
          </div>
          <div className='-mt-5 grow rounded-tl-[46px] rounded-tr-[46px]'>
            <TabComponent />
          </div>
        </div>
      </div>
      <Modal
        isOpen={calendarModalOpen}
        onClose={() => setCalendarModalOpen(false)}
        title='Calendar sync settings'
        className='bg-white-bg pt-6 px-9 pb-8 min-h-[346px] w-[652px] max-sm:w-screen rounded-[20px]'
      >
        <CalendarConnect />
      </Modal>
      <Modal
        isOpen={textRemindersModalOpen}
        onClose={() => setTextRemindersModalOpen(false)}
      >
        <TextReminders onSuccessButtonClick={() => setTextRemindersModalOpen(false)} />
      </Modal>
      <FileUploader
        show={showFileUploadDialog}
        close={() => setShowFileUploadDialog(false)}
      />
    </>
  )
}

export default TodoTabs
