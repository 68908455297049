import { useTodos } from '../providers/Todo'
import MonthView from './Todo/AgendaView/MonthView'
import { useMemo, useState } from 'react'
import Day from './Todo/AgendaView/Day'
import { differenceInCalendarMonths } from 'date-fns'

const Agenda = () => {
  const { allNonPastDueTodosWithDueDatesSorted } = useTodos()

  const d = new Date()
  const [dateViewing, setDateViewing] = useState(d)
  const [canGoPrev, setCanGoPrev] = useState(false)
  const [canGoHome, setCanGoHome] = useState(false)
  const [canGoNext, setCanGoNext] = useState(d.getMonth() !== 11)

  const todosForMonth = useMemo(() => {
    const todosForThisMonth = []

    let daysInCurrMonth = new Date(
      dateViewing.getFullYear(),
      dateViewing.getMonth() + 1,
      0,
    ).getDate()

    for (let i = dateViewing.getDate(); i <= daysInCurrMonth; i++) {
      todosForThisMonth.push(
        allNonPastDueTodosWithDueDatesSorted.todosAll.filter(
          todo =>
            new Date(todo.dueDate).getDate() === i &&
            new Date(todo.dueDate).getMonth() === dateViewing.getMonth(),
        ),
      )
    }

    return todosForThisMonth
  }, [dateViewing, allNonPastDueTodosWithDueDatesSorted])

  const moveDate = moveParam => {
    const newDate = new Date(
      dateViewing.getFullYear(),
      dateViewing.getMonth() + moveParam,
      1,
    )
    if (differenceInCalendarMonths(newDate, new Date()) === 0)
      newDate.setDate(new Date().getDate())
    setDateViewing(newDate)
    //check if we are at december
    setCanGoNext(newDate.getMonth() === 11 ? false : true)
    //check if we are at the current month
    setCanGoPrev(newDate.getMonth() === d.getMonth() ? false : true)
    //if we can
    setCanGoHome(newDate.getMonth() - d.getMonth() > 1 ? true : false)
  }

  const nextMonth = () => {
    // if we are already at december, do not go further
    if (dateViewing.getMonth() === 11) {
      setCanGoNext(false)
      return
    }
    //update date
    moveDate(1)
  }
  const previousMonth = () => {
    // if we are already at the current month, do not go back
    if (dateViewing.getMonth() === d.getMonth()) {
      setCanGoPrev(false)
      return
    }
    //update date
    moveDate(-1)
  }

  const goToCurrentMonth = () => {
    const date = new Date()
    setCanGoPrev(false)
    setCanGoHome(false)
    setCanGoNext(date.getMonth() !== 11)
    setDateViewing(date)
  }

  const days = todosForMonth.map((curr, i) => {
    return (
      <Day
        key={i}
        date={
          new Date(
            dateViewing.getFullYear(),
            dateViewing.getMonth(),
            dateViewing.getDate() + i,
          )
        }
        todos={curr}
      />
    )
  })

  return (
    <>
      <div>
        <div className='flex justify-evenly items-center w-100'>
          <MonthView
            date={dateViewing}
            goToNextMonth={nextMonth}
            goToPreviousMonth={previousMonth}
            canGoNext={canGoNext}
            canGoPrev={canGoPrev}
            canGoHome={canGoHome}
            setCanGoHome={setCanGoHome}
            goToCurrentMonth={goToCurrentMonth}
          />
        </div>
        <div>{days}</div>
      </div>
    </>
  )
}

export default Agenda
