import { useMutation, useQueryClient } from 'react-query'
import useAuth from '../../../../hooks/useAuth'
import api from '../../../../utils/api/interview-prep-problems'

const updateCache = (queryClient, queryKey, newProblem) => {
  queryClient.setQueryData(queryKey, (prev = []) => {
    const problemList = prev.map(obj => (obj.id === newProblem.id ? newProblem : obj))

    // just in case the problem didn't exist prior
    if (!problemList.includes(newProblem)) problemList.push(newProblem)
    return problemList
  })
}

export function useWriteInterviewPrepProblemMutation(
  shouldCreate,
  shouldAddProblemsToCache = true,
) {
  const { userId, trackCareerProgress } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(
    problem => (shouldCreate || !problem.id ? api.post(problem) : api.put(problem)),
    {
      onSuccess: async newProblem => {
        await trackCareerProgress({ userId, section: 3, value: new Date() })

        updateCache(queryClient, ['default-problems', userId], newProblem)

        shouldAddProblemsToCache &&
          updateCache(queryClient, ['interview-prep-problems', userId], newProblem)
      },
    },
  )
}
