export function binarySearch(
  array = [],
  target,
  comparator = (a, b) => (a < b ? -1 : a > b ? 1 : 0),
) {
  let left = 0
  let right = array.length - 1
  while (left <= right) {
    /**
     * equivalent to Math.floor((l + h) / 2) but faster
     * ref: https://stackoverflow.com/questions/12125421/why-does-a-shift-by-0-truncate-the-decimal
     */
    const middle = (left + right) >>> 1
    const comparison = comparator(array[middle], target)
    if (comparison < 0) {
      left = middle + 1
    } else if (comparison > 0) {
      right = middle - 1
    } else {
      return middle
    }
  }
  return ~left
}

export function binaryInsert(array, target, comparator, { duplicate = false } = {}) {
  let searchIndex = binarySearch(array, target, comparator)
  if (searchIndex >= 0) {
    /* if the binarySearch return value was zero or positive, a matching object was found */
    if (!duplicate) {
      return searchIndex
    }
  } else {
    /* if the return value was negative, the bitwise complement of the return value is the correct index for this object */
    searchIndex = ~searchIndex
  }
  array.splice(searchIndex, 0, target)
  return searchIndex
}
