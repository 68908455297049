/**
 * @template T
 * @typedef {Object} MetricsJob
 * @property {String} userId
 * @property {T extends import("../utils/metrics").__MetricOperation ? T : never} operation
 */

import { safePostMetrics } from '../utils/api/metrics'

/**
 *
 */
class InternalMetricsApi {
  /**
   * @template T
   * @type {MetricsJob<T>[]}
   */
  #jobQueue = []
  #timeout

  /**
   * the fn to queue a low prio callback
   * @type {(cb: () => void, timeout: number) => number}
   */
  #requestCallbackFn
  /**
   * the fn to cancel the low prio callback
   * @type {(id: number) => void}
   */
  #cancelCallbackFn
  /**
   * the id of the currently queue low prio callback
   * @type {number | undefined}
   */
  #id

  constructor(timeout = 5000) {
    this.#timeout = timeout

    const { requestCallbackFn, cancelCallbackFn } = this.#getCallbackAPI()
    this.#requestCallbackFn = requestCallbackFn
    this.#cancelCallbackFn = cancelCallbackFn
  }

  #getCallbackAPI() {
    return window.requestIdleCallback
      ? {
          requestCallbackFn: (cb, timeout) => requestIdleCallback(cb, { timeout }),
          cancelCallbackFn: cancelIdleCallback.bind(window),
        }
      : {
          requestCallbackFn: setTimeout.bind(window),
          cancelCallbackFn: clearTimeout.bind(window),
        }
  }

  /**
   * @template T
   * @param {...MetricsJob<T>} jobs
   */
  queue(...jobs) {
    // debounce if a callback exists
    if (this.#id) {
      this.#cancelCallbackFn(this.#id)
      this.#id = undefined
    }

    this.#jobQueue.push(...jobs.map(({ userId, operation }) => ({ userId, operation })))

    // queue the job
    this.#id = this.#requestCallbackFn(this.#handlePush, this.#timeout)
  }

  #handlePush = async () => {
    const userOperations = {}

    let job
    while ((job = this.#jobQueue.pop())) {
      if (!userOperations[job.userId]) userOperations[job.userId] = []
      userOperations[job.userId].push(job.operation)
    }

    await Promise.all(
      Object.entries(userOperations).map(([userId, operations]) =>
        safePostMetrics(userId, operations),
      ),
    )
  }
}

const MetricsApi = new InternalMetricsApi()

export default MetricsApi
