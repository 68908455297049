import * as React from 'react'
import {
  useFloating,
  offset,
  flip,
  shift,
  useListNavigation,
  useHover,
  useTypeahead,
  useInteractions,
  useRole,
  useClick,
  useDismiss,
  autoUpdate,
  safePolygon,
  FloatingPortal,
  useFloatingTree,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useMergeRefs,
  FloatingNode,
  FloatingFocusManager,
} from '@floating-ui/react'
import { MenuItem } from './MenuItem'

export const MenuComponent = React.forwardRef(
  (
    {
      children,
      label,
      contentEl,
      classNames,
      isOpen: controlledIsOpen,
      setIsOpen: controlledSetIsOpen,
      ...props
    },
    forwardedRef,
  ) => {
    const [unControlledIsOpen, unControlledSetIsOpen] = React.useState(false)
    // need to use parent open state or the menu will use it's own
    const isOpen = controlledIsOpen || unControlledIsOpen
    const setIsOpen = controlledSetIsOpen || unControlledSetIsOpen

    const [activeIndex, setActiveIndex] = React.useState(null)
    const [allowHover, setAllowHover] = React.useState(false)

    const listItemsRef = React.useRef([])
    const listContentRef = React.useRef(
      React.Children.map(children, child =>
        React.isValidElement(child) && child.type === MenuItem ? child.props.label : null,
      ),
    )

    const tree = useFloatingTree()
    const nodeId = useFloatingNodeId()
    const parentId = useFloatingParentNodeId()
    const isNested = parentId != null

    const { refs, context, strategy, x, y } = useFloating({
      nodeId,
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: isNested ? 'right-start' : 'bottom-start',
      middleware: [
        offset({ mainAxis: isNested ? 0 : 4, alignmentAxis: isNested ? -4 : 0 }),
        flip(),
        shift(),
      ],
      whileElementsMounted: autoUpdate,
    })
    const floatingStyles = {
      zIndex: 1000,
      position: strategy,
      left: x ?? 0,
      top: y ?? 0,
    }

    const hover = useHover(context, {
      enabled: isNested && allowHover,
      delay: { open: 75 },
      handleClose: safePolygon({
        blockPointerEvents: true,
      }),
    })
    const click = useClick(context, {
      event: 'mousedown',
      toggle: !isNested || !allowHover,
      ignoreMouse: isNested,
    })
    const role = useRole(context, { role: 'menu' })
    const dismiss = useDismiss(context)
    const listNavigation = useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    })
    const typeahead = useTypeahead(context, {
      enabled: isOpen,
      listRef: listContentRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    })

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
      hover,
      click,
      role,
      dismiss,
      listNavigation,
      typeahead,
    ])

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    React.useEffect(() => {
      if (!tree) return

      function handleTreeClick() {
        setIsOpen(false)
      }

      function onSubMenuOpen(event) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setIsOpen(false)
        }
      }

      tree.events.on('click', handleTreeClick)
      tree.events.on('menuopen', onSubMenuOpen)

      return () => {
        tree.events.off('click', handleTreeClick)
        tree.events.off('menuopen', onSubMenuOpen)
      }
    }, [tree, nodeId, parentId])

    React.useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit('menuopen', { parentId, nodeId })
      }
    }, [tree, isOpen, nodeId, parentId])

    // Determine if "hover" logic can run based on the modality of input. This
    // prevents unwanted focus synchronization as menus open and close with
    // keyboard navigation and the cursor is resting on the menu.
    React.useEffect(() => {
      function onPointerMove({ pointerType }) {
        if (pointerType !== 'touch') {
          setAllowHover(true)
        }
      }

      function onKeyDown() {
        setAllowHover(false)
      }

      window.addEventListener('pointermove', onPointerMove, {
        once: true,
        capture: true,
      })
      window.addEventListener('keydown', onKeyDown, true)
      return () => {
        window.removeEventListener('pointermove', onPointerMove, {
          capture: true,
        })
        window.removeEventListener('keydown', onKeyDown, true)
      }
    }, [allowHover])

    const referenceRef = useMergeRefs([refs.setReference, forwardedRef])

    return (
      <FloatingNode id={nodeId}>
        <button
          ref={referenceRef}
          data-open={isOpen ? '' : undefined}
          {...getReferenceProps({
            ...props,
            onClick(event) {
              event.stopPropagation()
            },
            ...(isNested && {
              // Indicates this is a nested <Menu /> acting as a <MenuItem />.
              role: 'menuitem',
            }),
          })}
        >
          {contentEl || label}{' '}
          {isNested && (
            <span aria-hidden style={{ marginLeft: 10 }}>
              ➔
            </span>
          )}
        </button>
        <FloatingPortal>
          {isOpen && (
            <FloatingFocusManager
              context={context}
              // Prevent outside content interference.
              modal={false}
              // Only initially focus the root floating menu.
              initialFocus={isNested ? -1 : 0}
              // Only return focus to the root menu's reference when menus close.
              returnFocus={!isNested}
            >
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                className={classNames?.menu}
                {...getFloatingProps()}
              >
                {React.Children.map(
                  children,
                  (child, index) =>
                    React.isValidElement(child) &&
                    React.cloneElement(
                      child,
                      getItemProps({
                        tabIndex: activeIndex === index ? 0 : -1,
                        ref(node) {
                          listItemsRef.current[index] = node
                        },
                        onClick: async event => {
                          await child.props.onClick?.(event)
                          if (!event.defaultPrevented) return tree?.events.emit('click')
                        },
                        // Allow focus synchronization if the cursor did not move.
                        onMouseEnter() {
                          if (allowHover && isOpen) {
                            setActiveIndex(index)
                          }
                        },
                      }),
                    ),
                )}
              </div>
            </FloatingFocusManager>
          )}
        </FloatingPortal>
      </FloatingNode>
    )
  },
)
