import { DAY_IN_MS } from '../constants'
import { to0001 } from '../utils/time'

const threeDaysInMs = 3 * DAY_IN_MS
const weekInMs = 7 * DAY_IN_MS
const twoWeeksInMs = 2 * weekInMs

const options = [
  { label: '3 days', value: threeDaysInMs },
  { label: '1 week', value: weekInMs },
  { label: '2 weeks', value: twoWeeksInMs },
]

export const useManageTodoStartDate = todo => {
  const getStartDate = () =>
    new Date(todo.dueDate).getTime() - new Date(todo.todoDate).getTime()

  const getTodoDateDiffFromToday = () =>
    new Date(todo.todoDate).getTime() - to0001().getTime()

  const getTodoDateDiffFromTodayInDays = () => {
    const timeDiff = getTodoDateDiffFromToday()

    return timeDiff / DAY_IN_MS
  }

  const getStartDateInDays = () => {
    const timeDiff = getStartDate()

    return timeDiff / DAY_IN_MS
  }

  const getStartingIndex = () => {
    const timeDiff = getStartDate()

    return options.findIndex(({ value }) => value === timeDiff)
  }

  const getTodoStartInDaysOrLess = (days = 1) => {
    if (!todo.dueDate || !todo.todoDate || todo.dueDate === todo.todoDate) {
      return false
    }

    const todoDayDiffInDays = getTodoDateDiffFromTodayInDays()

    return todoDayDiffInDays <= days
  }

  return {
    getStartDate,
    getStartDateInDays,
    getStartingIndex,
    getTodoStartInDaysOrLess,
    options,
  }
}
