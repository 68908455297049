import { ReactComponent as LockIcon } from './assets/lock.svg'

export const LockScreen = () => {
  const handleScrollToSectionTwo = () => {
    const element = document.getElementById('section-two')

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className='absolute inset-0 bg-black/90 flex justify-center items-center'>
      <div className='text-center text-white space-y-3.5'>
        <div className='inline-flex items-center justify-center rounded-full w-24 h-24 bg-text-300 '>
          <LockIcon className='inline-block' />
        </div>
        <h2 className='font-semibold text-2xl'>
          Complete at least one practice problem above
          <br />
          to unlock Edlyft's list of internships
        </h2>
        <div className='text-xs'>
          Get access to internship applications for top-rated companies
        </div>
        <div>
          <button
            onClick={handleScrollToSectionTwo}
            className='inline-flex gap-2 justify-center items-center p-3
      text-center font-medium leading-[120%] text-primary-700 rounded-md
      w-10 md:w-20 lg:w-40 bg-primary-300 enabled:focus-visible:bg-primary-200 enabled:hover:bg-primary-200
      disabled:bg-neutral-200'
          >
            Try a problem
          </button>
        </div>
      </div>
    </div>
  )
}
