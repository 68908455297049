import * as Slider from '@radix-ui/react-slider'

export const ProgressBar = ({
  min = 0,
  max = 100,
  step = 1,
  disabled,
  percentage,
  setPercentage,
  altered,
}) => {
  const onChange = ([value]) => {
    setPercentage(value)
  }

  return (
    <div className='flex justify-center items-center gap-1 font-display font-normal text-xs text-text-700 w-[100px]'>
      <Slider.Root
        className='relative flex items-center bg-gray-300 w-28 h-0.5'
        min={min}
        max={max}
        step={step}
        value={[percentage]}
        disabled={disabled}
        onValueChange={onChange}
      >
        <Slider.Track className='bg-gray-300 relative grow h-full'>
          <Slider.Range className='absolute bg-text-green h-full' />
        </Slider.Track>

        {altered ? (
          <Slider.Thumb className='hover:!bg-white hover:!bg-gradient-to-t hover:from-text-green/60 hover:to-text-green/60 [&:not(:focus)]:bg-none [&:not(:focus)]:bg-gray-300 [&:not(:focus)]:hidden group-hover:block focus:outline-none h-2.5 w-2.5 rounded-full bg-gradient-to-t from-text-green/80 to-text-green/80 bg-white' />
        ) : (
          <Slider.Thumb className='block bg-gray-300 h-2.5 w-2.5 rounded-full hover:bg-gradient-to-t hover:from-text-green/60 hover:to-text-green/60 hover:bg-white' />
        )}
      </Slider.Root>
      {altered ? <p>{percentage}%</p> : null}
    </div>
  )
}
