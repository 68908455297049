import { useForm } from 'react-hook-form'
import { PhoneInput } from '../PhoneInput'
import useAuth from '../../../../hooks/useAuth'
import { USER_PREFERENCES } from '../../../../constants'
import { useToasts } from '../../../../context/ToastsContext'
import { ActionButton } from '../../../ActionButton'
import { isPhoneNumberValid } from '../../../../utils/phoneNumber'

export const PhoneForm = ({
  isOnboarding,
  buttonText,
  buttonTextSuccess,
  buttonTextDone,
  loading,
  success,
  onClick,
}) => {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()
  const { user, updateUser } = useAuth()
  const addToast = useToasts()

  const onSubmit = async data => {
    const { phoneNumber } = data

    try {
      const { preferences, phoneNumber: lastUserPhoneNumber, ...userProps } = user
      const lastPhoneNumberWasInvalid = !isPhoneNumberValid(lastUserPhoneNumber)
      const newPreferences = {
        ...preferences,
        [USER_PREFERENCES.TEXT_WARNINGS_ENABLED]: lastPhoneNumberWasInvalid
          ? true
          : false,
      }

      const updatedUser = {
        ...userProps,
        phoneNumber,
        preferences: newPreferences,
      }

      await updateUser(updatedUser)
    } catch (err) {
      console.error(err)

      addToast({
        description: err?.message || 'An unknown error occurred, please contact us!',
        variant: 'error',
      })
    }
  }

  return success && !isOnboarding ? (
    <ActionButton
      text={buttonText}
      successText={buttonTextDone}
      disabled={loading}
      success={success}
      onClick={onClick}
    />
  ) : (
    <form
      className='flex flex-col items-center gap-2.5 font-display w-full xs:w-fit'
      onSubmit={handleSubmit(onSubmit)}
    >
      <PhoneInput
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        isSubmitting={isSubmitting}
      />
      <ActionButton
        type='submit'
        text={buttonText}
        successText={buttonTextSuccess}
        disabled={loading}
        success={success}
      />
    </form>
  )
}
