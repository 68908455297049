import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react'
import { forwardRef } from 'react'
import { MenuComponent } from './MenuComponent'

export const Menu = forwardRef((props, ref) => {
  const parentId = useFloatingParentNodeId()

  if (parentId === null) {
    return (
      <FloatingTree>
        <MenuComponent {...props} ref={ref} />
      </FloatingTree>
    )
  }

  return <MenuComponent {...props} ref={ref} />
})
