import { ChevronLeftIcon } from '@heroicons/react/20/solid'

interface LeftArrowProps {
  onClick: () => void
  disabled?: boolean
}

export const LeftArrow = ({ onClick, disabled = false }: LeftArrowProps) => {
  return (
    <button
      className='flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full'
      type='button'
      disabled={disabled}
      onClick={onClick}
      aria-label={disabled ? '' : 'Go to previous step'}
    >
      <ChevronLeftIcon className='text-text-700 h-7' />
    </button>
  )
}
