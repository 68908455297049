import { Auth } from 'aws-amplify'
import { H } from 'highlight.run'
import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import AuthContext, {
  STATE_AUTHENTICATED,
  STATE_LOGGING_IN,
  STATE_NOT_AUTHENTICATED,
  STATE_REGISTERING,
} from '../context/Auth'
import useAuth from '../hooks/useAuth'
import MetricsApi from '../services/MetricsApi'
import { authorizedApiFetch } from '../utils/api'
import userApi from '../utils/api/user'
import * as AuthCustom from '../utils/auth'
import { answerChallengeChannel } from '../utils/channels'
import ApiResponseError from '../utils/errors/ApiResponseError'
import { AddSignInLogMetricOperation } from '../utils/metrics'

const AuthProvider = ({ children }) => {
  const [state, setState] = useState(STATE_NOT_AUTHENTICATED)
  const [user, setUser] = useState()
  const [cognitoUser, setCognitoUser] = useState()
  const [loadAirtable, setLoadAirtable] = useState(false)
  const [error, setError] = useState()
  const [initialized, setInitialized] = useState(false)
  const receiveAnswerChannelRef = useRef()

  const isAuthenticated = state === STATE_AUTHENTICATED

  useEffect(() => {
    const init = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
        const id = authenticatedUser.attributes.sub
        const email = authenticatedUser.attributes.email
        H.identify(email, { id })
        const userResponse = await authorizedApiFetch(`/api/user?userId=${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        if (userResponse.ok) {
          const apiUser = await userResponse.json()
          setCognitoUser(authenticatedUser)
          setUser(apiUser)
          setLoadAirtable(!apiUser.registrationRecordId)
          setError()
          setState(STATE_AUTHENTICATED)
        } else {
          if (userResponse.status === 404) {
            const postUserData = {
              id,
              email,
            }
            const postUserResponse = await authorizedApiFetch('/api/user', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postUserData),
            })
            if (postUserResponse.ok) {
              const apiUser = await postUserResponse.json()
              setCognitoUser(authenticatedUser)
              setUser(apiUser)
              setLoadAirtable(!apiUser.registrationRecordId)
              setError()
              setState(STATE_AUTHENTICATED)
            } else {
              const error = await postUserResponse.json()
              throw new ApiResponseError({
                response: postUserResponse,
                body: error,
              })
            }
          } else {
            const error = await userResponse.json()
            throw new ApiResponseError({
              response: userResponse,
              body: error,
            })
          }
        }
        MetricsApi.queue({
          userId: id,
          operation: new AddSignInLogMetricOperation(),
        })
      } catch (err) {
      } finally {
        setInitialized(true)
      }
    }
    init()

    return () => {
      if (receiveAnswerChannelRef.current) {
        receiveAnswerChannelRef.current.close()
      }
    }
  }, [])

  const register = async email => {
    try {
      const { userSub, user: cognitoUser } = await AuthCustom.register({
        email,
      })
      const { username } = cognitoUser
      const postUserData = {
        id: userSub,
        email: username,
      }
      const userResponse = await userApi.createUser(postUserData)
      if (userResponse.ok) {
        const apiUser = await userResponse.json()
        setCognitoUser(cognitoUser)
        setUser(apiUser)
        setLoadAirtable(true)
        setError()
        setState(STATE_REGISTERING)
      } else {
        const error = await userResponse.json()
        setError(error)
      }
      await signIn(email)
    } catch (err) {
      setError(err)
      throw err
    }
  }
  const signIn = async email => {
    try {
      const signedInUser = await AuthCustom.login(email)
      setCognitoUser(signedInUser)
      setState(STATE_LOGGING_IN)
      setError()
      const channel = answerChallengeChannel()
      channel.onmessage = async event => {
        window.focus()
        const { username, answer } = event.data
        if (signedInUser.getUsername() === username) {
          await _answerChallenge(signedInUser, answer)
          channel.close()
          receiveAnswerChannelRef.current = undefined
        }
      }
      receiveAnswerChannelRef.current = channel
    } catch (err) {
      setError(err)
      throw err
    }
  }
  const _answerChallenge = async (_user, code) => {
    try {
      const authenticatedUser = await AuthCustom.answerCustomChallenge(_user, code)
      const userInfo = await Auth.currentUserInfo()
      const { sub: id, email } = userInfo.attributes
      H.identify(email, { id })
      const userResponse = await userApi.getUser(id)
      if (userResponse.ok) {
        const apiUser = await userResponse.json()
        setCognitoUser(authenticatedUser)
        setUser(apiUser)
        setLoadAirtable(!apiUser.registrationRecordId)
        setState(STATE_AUTHENTICATED)
        setError()
      } else {
        if (userResponse.status === 404) {
          const postUserData = {
            id,
            email,
          }
          const postUserResponse = await userApi.createUser(postUserData)
          if (postUserResponse.ok) {
            const apiUser = await postUserResponse.json()
            setCognitoUser(authenticatedUser)
            setUser(apiUser)
            setLoadAirtable(!apiUser.registrationRecordId)
            setError()
            setState(STATE_AUTHENTICATED)
          } else {
            const error = await postUserResponse.json()
            throw new ApiResponseError({
              response: postUserResponse,
              body: error,
            })
          }
        } else {
          const error = await userResponse.json()
          throw new ApiResponseError({
            response: userResponse,
            body: error,
          })
        }
      }
      MetricsApi.queue({
        userId: id,
        operation: new AddSignInLogMetricOperation(),
      })
    } catch (err) {
      if (err.name === 'NotAuthorizedException') {
        // they have failed the challenge and we need to reset the ui state
        setCognitoUser()
        setUser()
        setState(STATE_NOT_AUTHENTICATED)
        setError({
          message: 'Too many incorrect attempts, please try again later',
        })
        return
      }
      console.log(err)
      setError(err)
      throw err
    }
  }
  const answerChallenge = async code => _answerChallenge(cognitoUser, code)
  const signOut = async () => {
    try {
      await AuthCustom.signOut()
      setUser()
      setCognitoUser()
      setState(STATE_NOT_AUTHENTICATED)
    } catch (err) {
      setError(err)
      throw err
    }
  }
  const refreshUser = async () => {
    const id = userId
    const userResponse = await userApi.getUser(id)
    if (userResponse.ok) {
      const apiUser = await userResponse.json()
      setUser(apiUser)
      setLoadAirtable(!apiUser.registrationRecordId)
      setError()
      setState(STATE_AUTHENTICATED)
    } else {
      const error = new ApiResponseError({
        response: userResponse,
        body: await userResponse.json(),
      })
      setError(error)
      throw error
    }
  }
  const updateUser = async (userData, opts) => {
    const userResponse = await userApi.updateUser(userData, opts)
    if (userResponse.ok) {
      const apiUser = await userResponse.json()
      setUser(apiUser)
      setLoadAirtable(!apiUser.registrationRecordId)
      setError()
    } else {
      const error = new ApiResponseError({
        response: userResponse,
        body: await userResponse.json(),
      })
      setError(error)
      throw error
    }
  }
  const trackCareerProgress = async ({ userId, section, value }) => {
    const userResponse = await userApi.postCareerProgress({ userId, section, value })
    if (userResponse.ok) {
      const apiUser = await userResponse.json()
      setUser(apiUser)
      setLoadAirtable(!apiUser.registrationRecordId)
      setError()
    } else {
      const error = new ApiResponseError({
        response: userResponse,
        body: await userResponse.json(),
      })
      setError(error)
      throw error
    }
  }

  const completeGuidedTour = async ({ userId }) => {
    const userResponse = await userApi.postCompleteGuidedTour({ userId })
    if (userResponse.ok) {
      const apiUser = await userResponse.json()
      setUser(apiUser)
      setError()
    } else {
      const error = new ApiResponseError({
        response: userResponse,
        body: await userResponse.json(),
      })
      setError(error)
      throw error
    }
  }

  const userId = user?.id

  const value = {
    state,
    userId,
    user,
    cognitoUser,
    loadAirtable,
    isAuthenticated,
    initialized,
    error,
    register,
    signIn,
    answerChallenge,
    signOut,
    refreshUser,
    updateUser,
    trackCareerProgress,
    completeGuidedTour,
    setLoadAirtable,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function usePreinternApplicationQuery() {
  const { userId, isAuthenticated } = useAuth()

  return useQuery(
    ['preintern-applications', userId],
    ({ signal }) => userApi.getUserPreinternApplications(userId, { signal }),
    {
      enabled: isAuthenticated,
      initialData: [],
      select: data =>
        data.map(({ updatedAt, createdAt, ...app }) => ({
          ...app,
          createdAt: new Date(createdAt),
          updatedAt: new Date(updatedAt),
        })),
    },
  )
}

export function useWritePreinternApplicationMutation() {
  const { userId } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(
    application =>
      application.id
        ? userApi.putPreinternApplication(application)
        : userApi.postPreinternApplication(application),
    {
      onError: () => {
        // invalidate the query to try and fix the problem
        queryClient.invalidateQueries(['preintern-applications', userId])
      },
      onSuccess: newApplication => {
        queryClient.setQueryData(['preintern-applications', userId], (prev = []) => {
          const applicationList = prev.map(obj =>
            obj.id === newApplication.id ? newApplication : obj,
          )
          // just in case application didn't exist prior
          if (!applicationList.includes(newApplication))
            applicationList.push(newApplication)
          return applicationList
        })
      },
    },
  )
}

export default AuthProvider
