import { authorizedApiFetch } from '.'
import { parseJSONResponse } from './utils'

async function post(todo) {
  const response = await authorizedApiFetch(`/api/todo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(todo),
  })
  return parseJSONResponse(response)
}

async function put(todo = {}) {
  const response = await authorizedApiFetch(`/api/todo`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(todo),
  })
  return parseJSONResponse(response)
}

async function patch(todos = []) {
  const response = await authorizedApiFetch(`/api/todo`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(todos),
  })
  return response
}

/**
 * Gets the user todo items
 *
 * @param {String} userId
 * @param {Object} [param1]
 * @param {AbortSignal} [param1.signal]
 */
async function getUserList(userId, { signal } = {}) {
  const response = await authorizedApiFetch(`/api/todo?userId=${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  })
  return parseJSONResponse(response)
}

const api = {
  post,
  put,
  patch,
  getUserList,
}

export default api
