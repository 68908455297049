import { forwardRef } from 'react'

export const MenuItem = forwardRef(({ label, disabled, children, ...props }, ref) => {
  return (
    <button
      type='button'
      {...props}
      ref={ref}
      role='menuitem'
      disabled={disabled}
      aria-label={label}
    >
      {children}
    </button>
  )
})
