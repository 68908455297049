import { ReactComponent as StudentTrajectoryIcon } from './assets/student-trajectory.svg'
import BrushUp from './BrushUp'
import PracticeSkills from './PracticeSkills'
import Problems from './Problems'
import { useInterviewPrepProblemListQuery } from './hooks/useInterviewPrepProblemListQuery'
import Applications from './Applications'
import useAuth from '../../../hooks/useAuth'

const CareerTabs = () => {
  const {
    user: { careersStepThreeLastCompleted },
  } = useAuth()

  const problemListQuery = useInterviewPrepProblemListQuery()

  const anyCompletedProblem = !!careersStepThreeLastCompleted

  return (
    <div className='w-full -mt-2'>
      <section className='flex flex-col gap-3.5 items-center justify-center p-12'>
        <div className='flex justify-center items-center gap-5'>
          <div className='max-w-[450px] tracking-tight'>
            <h2 className='text-primary-700 text-4xl font-semibold mb-3.5'>
              Track your progress to getting an internship
            </h2>
            <p>
              Use this page to keep track of all things technical internship, starting
              from your first data structures class to getting your full time role
            </p>
          </div>
          <div className='lg:w-12' />
          <StudentTrajectoryIcon />
        </div>
      </section>
      <BrushUp />
      <PracticeSkills />
      <Problems problemListQuery={problemListQuery} />
      <Applications isLocked={!anyCompletedProblem} />
    </div>
  )
}

export default CareerTabs
