import { useState } from 'react'

import TodoForm from './TodoForm'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus_bold.svg'

const InsertTodo = ({ formId, nextWeight }) => {
  const [showForm, setShowForm] = useState(false)

  return (
    <div
      className={
        showForm
          ? 'h-auto opacity-100'
          : 'h-[5px] opacity-0 hover:opacity-100 hover:h-auto'
      }
      style={{ position: 'relative', zIndex: 1 }}
    >
      <div className='gap-1 items-center' style={{ display: showForm ? 'none' : 'flex' }}>
        <button
          className='p-2 -ml-2 -my-2 hover:bg-primary/5 rounded'
          aria-label='Add a todo item'
          onClick={() => setShowForm(true)}
        >
          <PlusIcon className='w-3.5 h-3.5 text-primary/30' />
        </button>
        <hr className='border border-primary/30 rounded-[20px] w-full' />
      </div>

      <TodoForm
        formId={formId}
        nextWeight={nextWeight}
        isInsert={true}
        isOpen={showForm}
        closeForm={() => setShowForm(false)}
      />
    </div>
  )
}

export { InsertTodo }
