import classNames from 'classnames'

export const Step = ({ stepIndex, currentStep, maxStep }) => {
  return (
    <li
      className={classNames(
        'flex items-center',
        stepIndex + 1 < maxStep && 'w-full',
        stepIndex + 1 < maxStep &&
          "after:content-[''] after:w-full after:h-1 after:border-b after:inline-block",
        stepIndex + 1 < currentStep
          ? 'after:border-primary-600'
          : 'after:border-[#C0BEC7]',
      )}
    >
      <div
        className={classNames(
          'flex items-center justify-center w-10 h-10 border rounded-full lg:h-12 lg:w-12 shrink-0',
          stepIndex < currentStep ? 'border-primary-600' : 'border-[#C0BEC7]',
          stepIndex < currentStep
            ? stepIndex + 1 < currentStep
              ? 'text-white'
              : 'text-primary-600'
            : 'text-[#C0BEC7]',
          stepIndex + 1 < currentStep && 'bg-primary-600',
        )}
      >
        <div
          className={classNames(
            stepIndex <= currentStep ? 'border-primary-600' : 'border-[#C0BEC7]',
          )}
        >
          {stepIndex + 1}
        </div>
      </div>
    </li>
  )
}
