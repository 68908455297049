import classNames from 'classnames'
import { forwardRef, useRef, useState } from 'react'

import { ReactComponent as LinkIcon } from '../../../assets/icons/link.svg'
import { isLink, toUrl } from './utils'

const LinkAdder = forwardRef(({ onSubmit, onCancel }, ref) => {
  const [isActive, setActive] = useState(false)
  const [invalidLink, setInvalidLink] = useState(false)
  const submittedRef = useRef(false)

  const activate = () => setActive(true)

  const onInputKeyUp = e => {
    const linkish = e.target.value
    if (e.code === 'Escape') {
      onCancel()
      setInvalidLink(false)
    }
    if (e.code === 'Enter') {
      if (!isLink(linkish)) {
        e.preventDefault()
        return setInvalidLink(true)
      }
      const url = toUrl(linkish)
      submittedRef.current = true
      setInvalidLink(false)
      onSubmit(url)
    }
  }
  const onInputKeyDown = e => {
    if (e.code !== 'Enter') return
    e.preventDefault()
  }

  const onInputBlur = e => {
    if (submittedRef.current) return
    onCancel()
    setInvalidLink(false)
    e.stopPropagation()
  }

  return (
    <div
      ref={ref}
      className={classNames(
        'absolute flex justify-start items-center',
        'w-[240px] h-[44px] px-[13.43px] gap-[11.43px]',
        'rounded-2xl bg-white shadow-[0px_4px_36px_rgba(44,37,67,0.16)]',
        'border',
        isActive && invalidLink ? 'border-red-500' : 'border-[#E3E1E5]',
      )}
      tabIndex='0'
    >
      <LinkIcon className='flex-shrink-0 w-[17.4px] h-[17.4px]' />
      {!isActive ? (
        <button
          type='button'
          className='text-xs text-text-70 whitespace-nowrap w-full text-left outline-none'
          onClick={activate}
          onFocus={activate}
          onBlur={onCancel}
          tabIndex='0'
        >
          Add hyperlink
        </button>
      ) : (
        <input
          placeholder='Paste link here'
          className='flex-auto outline-none overflow-hidden text-text-solid text-xs'
          autoFocus
          type='url'
          onKeyDown={onInputKeyDown}
          onKeyUp={onInputKeyUp}
          onBlur={onInputBlur}
          tabIndex='0'
        />
      )}
    </div>
  )
})

export default LinkAdder
