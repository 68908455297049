const CourseCard = ({ title, description, courseLink, logo, university }) => {
  return (
    <a href={courseLink} target='_blank' rel='noopener noreferrer'>
      <div className='flex flex-col justify-start items-center gap-2.5 bg-card-bg font-display not-italic text-center rounded-xl p-5 w-[290px] h-[140px]'>
        <img src={logo} alt={`${university} Logo`} />
        <div>
          <h4 className='text-primary-200 font-medium text-[12px]'>{title}</h4>
          <p className='text-white-bg font-normal text-[10px]'>{description}</p>
        </div>
      </div>
    </a>
  )
}

export default CourseCard
