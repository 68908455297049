import classNames from 'classnames'
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg'

export const ActionButton = ({
  type = 'button',
  text,
  successText,
  disabled = false,
  success,
  onClick,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'flex items-center justify-center gap-[3px] text-base font-semibold w-full xs:w-[180px] h-[40px] rounded-md',
        success
          ? 'text-white-bg bg-text-green'
          : 'text-primary-700 bg-primary-300 hover:bg-primary-200 active:bg-primary-200',
      )}
    >
      {success ? successText : text}
      {success ? <CheckIcon className='w-[13px]' /> : null}
    </button>
  )
}
