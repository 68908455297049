import { useLocalStorage } from 'usehooks-ts'
import { ProgressBar } from '../../ProgressBar'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../common/Tooltip'
import { useEffect, useRef, useState } from 'react'

export const TodoProgressBar = ({ percentage, setPercentage, showTooltip }) => {
  const [altered, setAltered] = useState(typeof percentage === 'number')

  const [progressBarShownFirstTime, setProgressBarShownFirstTime] = useLocalStorage(
    'todo-progress-bar-shown-first-time',
    false,
  )

  const [progressBarDraggedFirstTime, setProgressBarDraggedFirstTime] = useLocalStorage(
    'todo-progress-bar-dragged-first-time',
    false,
  )
  const tooltipShownTimeout = useRef()

  const isTooltipOpen =
    (!progressBarShownFirstTime && showTooltip) ||
    (!progressBarDraggedFirstTime && altered)

  useEffect(() => {
    if (!progressBarShownFirstTime && showTooltip) {
      clearTimeout(tooltipShownTimeout.current)

      tooltipShownTimeout.current = setTimeout(() => {
        setProgressBarShownFirstTime(true)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!progressBarDraggedFirstTime && altered) {
      clearTimeout(tooltipShownTimeout.current)

      tooltipShownTimeout.current = setTimeout(async () => {
        setProgressBarDraggedFirstTime(true)
      }, 3000)
    }
  }, [altered, percentage, progressBarDraggedFirstTime, setProgressBarDraggedFirstTime])

  const updatePercentage = percentage => {
    setAltered(true)
    setPercentage(percentage)
  }

  return (
    <Tooltip isOpen={isTooltipOpen} setIsOpen={() => {}} placement='bottom'>
      <TooltipTrigger>
        <ProgressBar
          min={0}
          max={100}
          step={1}
          percentage={percentage || 0}
          setPercentage={updatePercentage}
          altered={altered}
        />
      </TooltipTrigger>
      <TooltipContent className='z-50 max-w-xxs text-center py-5 px-6 bg-primary-700 fill-primary-700 rounded-xl text-white'>
        {!progressBarShownFirstTime
          ? 'You can now enter the progress you’ve made on your tasks'
          : 'Keep it up!'}
      </TooltipContent>
    </Tooltip>
  )
}
