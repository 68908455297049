import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'
import { Link } from 'react-router-dom'

import useAuth from '../../../../hooks/useAuth'
import { useNavbarTabLayout } from '../../../../providers/LayoutTabProvider'

import logo from '../../../../assets/icons/logo.svg'
import { ReactComponent as HamburgerIcon } from '../../assets/hamburger.svg'
import { MobileMenuTabs } from './MobileMenuTabs'
import { SmsToggle } from './SmsToggle'
import { ShowCompletedTodosToggle } from './ShowCompletedTodosToggle'
import { SignOutButton } from './SignOutButton'
import { CloseMenuButton } from './CloseMenuButton'

export const MobileUserMenu = ({
  isOpen,
  setIsOpen,
  toggleCompletedTask,
  toggleSmsNotifications,
  smsEnabled,
  completedTasksEnabled,
  smsToggleText,
  completedTaskToggleText,
  signOut,
  loading,
}) => {
  const { user } = useAuth()
  const { tabs, isSelected, setIsSelected } = useNavbarTabLayout()
  const showTab = tabs !== null

  return (
    <>
      <button className='text-xs rounded-md px-4 py-2' onClick={() => setIsOpen(true)}>
        <HamburgerIcon />
      </button>
      {isOpen &&
        createPortal(
          <RemoveScroll>
            <div className='flex flex-col bg-white z-50 fixed left-0 top-0 w-screen h-screen py-12 px-7'>
              <div className='flex justify-between items-center mb-10'>
                <Link to='/' onClick={() => setIsOpen(false)}>
                  <img src={logo} alt='logo' />
                </Link>
                <CloseMenuButton setIsOpen={setIsOpen} />
              </div>
              <div className='divide-y divide-text-200 font-medium text-primary-700'>
                {showTab && (
                  <MobileMenuTabs
                    tabs={tabs}
                    isSelected={isSelected}
                    setIsOpen={setIsOpen}
                    setIsSelected={setIsSelected}
                  />
                )}
                {user.registrationRecordId && (
                  <SmsToggle
                    loading={loading}
                    smsEnabled={smsEnabled}
                    toggleSmsNotifications={toggleSmsNotifications}
                    smsToggleText={smsToggleText}
                  />
                )}
                <ShowCompletedTodosToggle
                  loading={loading}
                  completedTasksEnabled={completedTasksEnabled}
                  toggleCompletedTask={toggleCompletedTask}
                  completedTaskToggleText={completedTaskToggleText}
                />
              </div>
              <SignOutButton signOut={signOut} />
            </div>
          </RemoveScroll>,
          document.body,
        )}
    </>
  )
}
