import * as Dialog from '@radix-ui/react-dialog'
import classNames from 'classnames'

export const Overlay = ({ isOpen, className, children }) => {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={classNames(
            'fixed inset-0 bg-black/30 z-50 animate-opacity-easein',
            className,
          )}
          aria-hidden='true'
        />
        {children}
      </Dialog.Portal>
    </Dialog.Root>
  )
}
