import React from 'react'
import { getUnixTime } from 'date-fns'
import CustomDay from './CustomDay'

/** Render a row in the calendar, with the days and the week number.
 *
 * @param {{
 *   displayMonth: Date;
 *   weekNumber: number;
 *   dates: Date[];
 * }} props
 */
export function Row(props) {
  return (
    <>
      {props.dates.map(date => (
        <div key={getUnixTime(date)} role='gridcell' className='h-12'>
          <CustomDay displayMonth={props.displayMonth} date={date} />
        </div>
      ))}
    </>
  )
}
