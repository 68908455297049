import { useQuery } from 'react-query'
import useAuth from '../../../../hooks/useAuth'
import api from '../../../../utils/api/interview-prep-problems'

export function useInterviewPrepProblemListQuery() {
  const { userId, isAuthenticated } = useAuth()

  return useQuery(
    ['interview-prep-problems', userId],
    ({ signal }) => api.get(userId, { signal }),
    {
      enabled: isAuthenticated,
      initialData: [],
      select: data =>
        data.map(({ updatedAt, createdAt, ...app }) => ({
          ...app,
          createdAt: new Date(createdAt),
          updatedAt: new Date(updatedAt),
        })),
    },
  )
}
