import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

const EmptyBox = ({ triggerFileSelect, addFiles }) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        addFiles(item)
      },
      collect: monitor => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    }),
    [addFiles],
  )
  const isActive = canDrop && isOver

  return (
    <div
      className={`grow flex flex-col items-center justify-center w-full min-h-[206px] rounded-lg border border-dashed ${
        isActive ? 'border-primary' : 'border-[#C1C2C0]'
      }`}
      ref={drop}
    >
      <p className='font-display mb-2 text-primary text-sm'>
        {isActive ? 'Release to drop' : 'Drag files here to upload'}
      </p>

      <small className='font-display mb-4 uppercase text-[#9592A1] text-[10px] font-medium'>
        .pdf .jpg .jpeg .docx .xls
      </small>

      <button
        className='py-2.5 px-6 bg-primary/10 text-primary text-sm font-medium rounded-xl border border-solid border-primary hover:bg-primary/20'
        onClick={triggerFileSelect}
      >
        Select files
      </button>
    </div>
  )
}

export { EmptyBox }
