export default class ApiResponseError extends Error {
  /**
   *
   * @param {object} param
   * @param {Response} param.response
   * @param {object} param.body
   */
  constructor({ response, body }) {
    super(body.message)
    this.response = response
    this.body = body
  }
}
