import { useRef } from 'react'
import { INPUT_CHANGE_SAVE_REQUEST_TIMEOUT } from '../../../../../../constants'

const Notes = ({ notes, updateNotes }) => {
  const timoutRef = useRef()

  const onKeyUp = ({ target: { value: notes } }) => {
    clearTimeout(timoutRef.current)
    timoutRef.current = setTimeout(
      () => updateNotes(notes),
      INPUT_CHANGE_SAVE_REQUEST_TIMEOUT,
    )
  }

  return (
    <textarea
      onKeyUp={onKeyUp}
      defaultValue={notes}
      className='w-[660px] h-[130px] p-[15px]  text-base font-normal bg-white focus-visible:outline-primary-200 rounded-md'
    />
  )
}

export default Notes
