import { Intro } from './Intro'
import { SignIn } from './SignIn'

const Identify = () => {
  return (
    <main className='w-full h-full grid lg:grid-cols-2'>
      <section className='bg-white-bg px-20 py-[130px] relative'>
        <Intro />
      </section>

      <section className='bg-[#013590] px-20 py-[130px]'>
        <SignIn />
      </section>
    </main>
  )
}

export { Identify }
