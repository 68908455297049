import { isSupportedCountry } from 'libphonenumber-js'

// this function gets the region data that is needed to assume the users country code
fetch('https://www.cloudflare.com/cdn-cgi/trace')
  .then(r => r.text())
  .then((data: string) => {
    const record = data
      .trim()
      .split('\n')
      .reduce(function (obj, line) {
        const pair = line.split('=')
        obj[pair[0]] = pair[1]
        return obj
      }, {} as Record<string, unknown>)

    const unsafeCountryCode = (record.loc as string) || 'US'
    isSupportedCountry(unsafeCountryCode)
      ? (countryCode = unsafeCountryCode)
      : (countryCode = 'US')
  })
  .catch(console.error)

let countryCode = 'US'

export { countryCode }
