import { Dispatch, MutableRefObject, SetStateAction, useRef } from 'react'
import { RootTodoObject } from './types'
import {
  CancelDrop,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { COLUMNS } from '../constants'
import { arrayMove } from '@dnd-kit/sortable'
import { TodoInterface } from '../../Todo/types'

type SetState<T> = Dispatch<SetStateAction<T>>

interface DndkitEventHandersArguments<T extends RootTodoObject> {
  filteredTodos: T
  localTodos: T
  setLocalTodos: SetState<T>
  setActiveId: SetState<UniqueIdentifier | null>
  recentlyMovedToNewContainer: MutableRefObject<boolean>
  weightIncrement: number
  moveTodo: (
    todoToUpdate: TodoInterface,
    toPositionIndex: number,
    weightIncrement: number,
    formId: string,
    todos: TodoInterface[],
  ) => Promise<void>
  noCollisions: MutableRefObject<boolean>
}

const findContainer = <T extends RootTodoObject>(id: UniqueIdentifier, todoObj: T) => {
  if (id in todoObj) {
    return id as keyof typeof todoObj
  }

  return Object.keys(todoObj).find((key: keyof typeof todoObj) =>
    todoObj[key]?.find(todo => id === todo.id),
  ) as keyof typeof todoObj
}

export function useDndkitEventHandlers<T extends RootTodoObject>({
  filteredTodos,
  localTodos,
  setLocalTodos,
  setActiveId,
  recentlyMovedToNewContainer,
  weightIncrement,
  moveTodo,
  noCollisions,
}: DndkitEventHandersArguments<T>) {
  // we keep a copy of the todos before the move occured, to reset the state if the move failed or was cancelled
  const clonedLocalTodos = useRef<typeof localTodos | null>(null)

  const onDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id)
    clonedLocalTodos.current = localTodos
  }

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event

    const overId = over?.id

    if (!overId) return

    const overContainer = findContainer(overId, localTodos)
    const activeContainer = findContainer(active.id, localTodos)

    if (!overContainer || !activeContainer) {
      return
    }

    if (activeContainer !== overContainer) {
      setLocalTodos(items => {
        const activeItems = items[activeContainer]
        const overItems = items[overContainer]
        const overIndex = overItems.findIndex(({ id }) => id === overId)
        const activeIndex = activeItems.findIndex(({ id }) => id === active.id)

        let newIndex: number

        if (overId in items) {
          newIndex = overItems.length + 1
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height

          const modifier = isBelowOverItem ? 1 : 0

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }

        recentlyMovedToNewContainer.current = true

        const state = {
          ...items,
          [activeContainer]: items[activeContainer].filter(({ id }) => id !== active.id),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(newIndex, items[overContainer].length),
          ],
        }
        return state
      })
    }
  }

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    const activeContainer = findContainer(active.id, localTodos)
    if (!activeContainer) {
      setActiveId(null)
      return
    }

    const overId = over?.id
    if (!overId) {
      setActiveId(null)
      return
    }

    const overContainer = findContainer(overId, localTodos)
    if (overContainer) {
      const activeIndex = localTodos[activeContainer]
        .map(({ id }) => id)
        .indexOf(active.id as string)
      const overIndex = localTodos[overContainer]
        .map(({ id }) => id)
        .indexOf(overId as string)

      const srcContainer = findContainer(active.id, filteredTodos)
      const srcIndex = filteredTodos[srcContainer]
        .map(({ id }) => id)
        .indexOf(active.id as string)

      if (srcIndex === overIndex && srcContainer === overContainer)
        return setActiveId(null)

      const todos = filteredTodos[overContainer]
      const todoToUpdate = filteredTodos[srcContainer][srcIndex]

      const activeTodoWentUp = overIndex - activeIndex < 0
      let toPositionIndex = overIndex - (activeTodoWentUp ? 1 : 0)

      if (localTodos[overContainer].length !== filteredTodos[overContainer].length) {
        // the over container has a new todo in it's column
        toPositionIndex -= activeIndex <= overIndex ? 1 : 0
      }

      const formId =
        overContainer === 'todosLater'
          ? COLUMNS.LATER
          : overContainer === 'todosTomorrow'
          ? COLUMNS.TOMORROW
          : COLUMNS.TODAY

      setLocalTodos(items => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      }))
      try {
        await moveTodo(todoToUpdate, toPositionIndex, weightIncrement, formId, todos)
      } catch {
        if (clonedLocalTodos.current) {
          setLocalTodos(clonedLocalTodos.current)
        }
      }
    }
  }

  const cancelDrop: CancelDrop = () => {
    return noCollisions.current
  }
  const onDragCancel = () => {
    if (clonedLocalTodos.current) {
      setLocalTodos(clonedLocalTodos.current)
    }

    setActiveId(null)
    clonedLocalTodos.current = null
  }

  return {
    onDragStart,
    onDragOver,
    onDragEnd,
    cancelDrop,
    onDragCancel,
  }
}
