import classNames from 'classnames'
import { useId } from 'react'
import { useFormContext } from 'react-hook-form'
import { Header } from './Header'
import { Steps } from '../../components/StepperForm'

/**
 * @type {import('react-hook-form').RegisterOptions}
 */
const requiredStringOpts = {
  required: true,
  setValueAs: value => String(value).trim(),
}

export const UserInfoContent = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const firstNameId = `firstName${useId()}`
  const lastNameId = `lastName${useId()}`
  const schoolId = `school${useId()}`

  return (
    <>
      <div className='max-xs:hidden'>
        <Header />
      </div>
      <div className='max-xs:mx-8'>
        <Steps />
      </div>
      <div className='grid grid-rows-1 xs:grid-cols-2 gap-6'>
        <h2 className='col-span-full font-semibold text-2xl mb-1.5'>
          Tell us about yourself!
        </h2>
        <div className=''>
          <label htmlFor={firstNameId}>
            First name<span className='align-super text-sm'>*</span>
          </label>
          <input
            id={firstNameId}
            className={classNames(
              'block w-full rounded-md outline outline-1 outline-transparent focus-visible:outline-primary-200 p-3.5',
              errors.firstName && '!outline-red-500',
            )}
            {...register('firstName', requiredStringOpts)}
          />
        </div>
        <div className=''>
          <label htmlFor={lastNameId}>
            Last name<span className='align-super text-sm'>*</span>
          </label>
          <input
            id={lastNameId}
            className={classNames(
              'block w-full rounded-md outline outline-1 outline-transparent focus-visible:outline-primary-200 p-3.5',
              errors.lastName && '!outline-red-500',
            )}
            {...register('lastName', requiredStringOpts)}
          />
        </div>
        <div className='col-span-full'>
          <label htmlFor={schoolId}>
            What school did you attend?<span className='align-super text-sm'>*</span>
          </label>
          <input
            id={schoolId}
            className={classNames(
              'block w-full rounded-md outline outline-1 outline-transparent focus-visible:outline-primary-200 p-3.5',
              errors.schoolName && '!outline-red-500',
            )}
            {...register('schoolName', requiredStringOpts)}
          />
        </div>
        <div className='col-span-full text-text-70'>
          <span className='align-super text-sm'>*</span>This field is required
        </div>
        {/* desktop button */}
        <div className='hidden xs:block col-span-full justify-self-end'>
          <button
            type='submit'
            className='p-3 text-center bg-primary-300 disabled:text-black disabled:bg-neutral-400 focus-visible:bg-primary-200 hover:bg-primary-200 font-medium leading-[120%] text-primary-700 rounded-md'
          >
            Continue
          </button>
        </div>
      </div>
      {/* mobile sticky button */}
      <div className='hidden max-xs:block mt-auto sticky bottom-0 left-0 pt-4 pb-12 bg-white-bg w-full'>
        <button
          type='submit'
          className='max-xs:w-full p-3 text-center bg-primary-300 disabled:text-black disabled:bg-neutral-400 focus-visible:bg-primary-200 hover:bg-primary-200 font-medium leading-[120%] text-primary-700 rounded-md'
        >
          Continue
        </button>
      </div>
    </>
  )
}
