import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react'
import { useLastEditedTodo } from '../../../providers/Todo'
import { useLocalStorage } from 'usehooks-ts'

interface TodoStartDateCallToActionHookArguments {
  todo: any
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setContent: Dispatch<SetStateAction<ReactNode>>
}

export function useTodoStartDateCallToAction({
  todo,
  setIsOpen,
  setContent,
}: TodoStartDateCallToActionHookArguments) {
  const { lastEditedTodoId, editedWithStartDateRef } = useLastEditedTodo()
  const [usedStartDate, setUsedStartDate] = useLocalStorage(
    'eot-used-todo-start-date',
    false,
  )

  const triggeredOnce = useRef(false)
  useEffect(() => {
    if (triggeredOnce.current || todo.id !== lastEditedTodoId) return

    if (editedWithStartDateRef.current && !usedStartDate) {
      setContent(
        'This todo will move columns according to the start date you have selected',
      )
      setIsOpen(true)
      setUsedStartDate(true)
    }
    triggeredOnce.current = true
  }, [lastEditedTodoId, todo, usedStartDate, setUsedStartDate])

  const editedWithStartDate = (e: boolean) => {
    editedWithStartDateRef.current = e
  }

  return {
    editedWithStartDate,
  }
}
