import { useMemo } from 'react'
import { useState } from 'react'

export function useSelectProps(defaultSelectedIndex, options) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(
    typeof defaultSelectedIndex !== 'number' || defaultSelectedIndex < 0
      ? null
      : defaultSelectedIndex,
  )

  const optionObjectList = useMemo(() => {
    const stringToOption = str => ({ value: str, label: str })
    const fillInMissing = ({ value, label = value, ...rest }) => ({
      ...rest,
      value,
      label,
    })

    return options.map(op =>
      typeof op !== 'object' ? stringToOption(op) : fillInMissing(op),
    )
  }, [options])

  return {
    isOpen,
    setIsOpen,
    selectedIndex,
    setSelectedIndex,
    options: optionObjectList,
  }
}
