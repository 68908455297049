import { useState } from 'react'
import { differenceInCalendarDays, format } from 'date-fns'
import { Calendar as RootCalendar } from './Calendar'

const isPastDate = date => {
  return differenceInCalendarDays(date, new Date()) < 0
}

const Calendar = ({
  selected: controlledSelected,
  setSelected: controlledSetSelected,
}) => {
  const [uncontrolledSelected, uncontrolledSetSelected] = useState()

  const selected = controlledSelected || uncontrolledSelected
  const setSelected = controlledSetSelected || uncontrolledSetSelected

  return (
    <RootCalendar
      mode='single'
      selected={selected}
      disabled={isPastDate}
      fromDate={new Date(new Date().setDate(1))}
      onSelect={setSelected}
      formatters={{
        formatWeekdayName: (weekday, options) => format(weekday, 'cccccc', options),
      }}
    />
  )
}

export default Calendar
