import { Switch } from '@headlessui/react'

/**
 *
 * @param {Parameters<Switch>[0] & { srText: string }} props
 */
function Toggle(props) {
  const { className, style, srText, ...rest } = props

  return (
    <Switch
      {...rest}
      className={`inline-flex w-10 p-1 items-center rounded-full
        bg-primary-500 ${rest.checked ? '' : 'bg-opacity-20'}
        disabled:bg-neutral-100 disabled:cursor-not-allowed`}
    >
      <span className='sr-only'>{srText}</span>
      <span
        className={`${
          rest.checked ? 'translate-x-4' : 'translate-x-0'
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  )
}

export default Toggle
