import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import StepperForm from '../../components/StepperForm/StepperForm'
import { UserInfoContent } from './UserInfoStep'
import { PlannedUsageContent } from './PlannedUsageStep'
import { GoalsContent } from './GoalsStep'
import MetricsApi from '../../services/MetricsApi'
import { AddEditTodoCountMetricOperation } from '../../utils/metrics'
import api from '../../utils/api/todo'
import Navbar from '../../components/Navbar'
import { isPhoneNumberValid } from '../../utils/phoneNumber'

const Onboarding = () => {
  const { isAuthenticated, loadAirtable, initialized, userId, user, updateUser } =
    useAuth()
  const [submitting, setSubmitting] = useState(false)

  if (!initialized) return <></>

  if (!isAuthenticated) return <Navigate to='/identify' />
  if (!loadAirtable && !submitting) return <Navigate to='/' />

  const steps = [UserInfoContent, PlannedUsageContent, GoalsContent]

  const onSubmit = async data => {
    try {
      setSubmitting(true)
      const { todo, ...userData } = data

      const { preferences: userDataPreferences, ...rest } = userData
      const updatedUser = {
        ...user,
        ...rest,
        preferences: {
          ...user.preferences,
          ...userDataPreferences,
          textWarningsEnabled: isPhoneNumberValid(userData.phoneNumber),
        },
      }
      await updateUser(updatedUser, { onboard: true })

      if (!!todo.title && !!todo.dueDate) {
        await api.post({ ...todo, userId })
        MetricsApi.queue({
          userId,
          operation: new AddEditTodoCountMetricOperation(1),
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className='relative bg-white-bg min-h-screen flex flex-col'>
      <Navbar noMenu />
      <main className='self-center grow flex flex-col mt-4 mx-8 lg:max-w-2xl lg:mx-20'>
        <StepperForm steps={steps} onSubmit={onSubmit} />
      </main>
    </div>
  )
}

export default Onboarding
