/**
 * Creates a function that will delay when the callback gets executed exponentially
 * every time it gets called until the backOffTime exceeds the backOffCap.
 *
 * This is used to not overload the api server with excess requests.
 * Reference: https://cloud.google.com/iot/docs/how-tos/exponential-backoff
 *
 *
 * @param {Function} callback
 * @param {Number} backOffCap
 * @returns a function with its own backoff counter to keep track of when it should fail.
 */
export const createExponentialBackOffFunction = (
  callback = async (...args) => {},
  backOffCap = 32,
) => {
  let backOffTime = 1
  return (...args) => {
    if (backOffTime > backOffCap)
      throw new Error('Cannot exceed the exponential backoff cap')
    const publishTime = 1000 * (backOffTime + Math.random())
    backOffTime *= 2
    console.log(`Backing off for ${publishTime}ms before requesting again.`)

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        callback(...args)
          .then(resolve)
          .catch(reject)
      }, publishTime)
    })
  }
}

// taken from https://www.geeksforgeeks.org/check-for-balanced-parentheses-in-an-expression/
export function bracketsAreBalanced(expr) {
  // Using ArrayDeque is faster
  // than using Stack class
  let stack = []

  // Traversing the Expression
  for (let i = 0; i < expr.length; i++) {
    let x = expr[i]

    // pass over any non-bracket characters
    if (x !== '(' && x !== '[' && x !== '{' && x !== ')' && x !== ']' && x !== '}')
      continue

    if (x === '(' || x === '[' || x === '{') {
      // Push the element in the stack
      stack.push(x)
      continue
    }

    // If current character is not opening
    // bracket, then it must be closing.
    // So stack cannot be empty at this point.
    if (stack.length === 0) return false

    let check
    switch (x) {
      case ')':
        check = stack.pop()
        if (check === '{' || check === '[') return false
        break

      case '}':
        check = stack.pop()
        if (check === '(' || check === '[') return false
        break

      case ']':
        check = stack.pop()
        if (check === '(' || check === '{') return false
        break
    }
  }

  // Check Empty Stack
  return stack.length === 0
}
