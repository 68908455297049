import { LeftArrow } from './LeftArrow'
import { PageThumbs } from './PageThumbs'
import { RightArrow } from './RightArrow'

interface CarouselProps {
  numberOfPages: number
  currentPage: number
  showPreviousButton?: boolean
  goNext: () => void
  goPrevious: () => void
  goToPage: (page: number) => void
  complete: () => void
}

export const Carousel = ({
  numberOfPages,
  currentPage,
  showPreviousButton = true,
  goNext,
  goPrevious,
  goToPage,
  complete,
}: CarouselProps) => {
  return (
    <div className='flex items-center justify-between xs:hidden'>
      {showPreviousButton ? (
        <LeftArrow onClick={goPrevious} disabled={currentPage === 1} />
      ) : (
        <div className='w-10 h-10 opacity-0' aria-hidden={true} />
      )}
      <PageThumbs
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        goToPage={goToPage}
      />
      {currentPage === numberOfPages ? (
        <button
          type='button'
          onClick={complete}
          className='bg-primary-300 hover:bg-primary-200 active:bg-primary-200 text-primary-700 w-28 h-11 rounded-md'
        >
          Got it!
        </button>
      ) : (
        <RightArrow onClick={goNext} />
      )}
    </div>
  )
}
