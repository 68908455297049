import React from 'react'
import { useDayPicker } from 'react-day-picker'
import { Head } from '../Head'
import { Row } from '../Row'

import { getMonthWeeks } from './utils/getMonthWeeks'

/**
 * Render the table with the calendar.
 *
 * @param {{
 *  ['aria-labelledby']?: string;
 *  displayMonth: Date;
 * }} props
 */
export function Table(props) {
  const {
    locale,
    classNames,
    styles,
    hideHead,
    fixedWeeks,
    weekStartsOn,
    firstWeekContainsDate,
    ISOWeek,
  } = useDayPicker()

  const weeks = getMonthWeeks(props.displayMonth, {
    useFixedWeeks: Boolean(fixedWeeks),
    ISOWeek,
    locale,
    weekStartsOn,
    firstWeekContainsDate,
  })

  return (
    <div
      className='grid grid-cols-7 auto-rows-min
        border rounded-lg border-text-200
        [&>*]:border-l [&>*:nth-child(n+8)]:border-t [&>*:nth-child(7n+1)]:border-l-0'
      role='grid'
      aria-labelledby={props['aria-labelledby']}
    >
      {!hideHead && <Head />}
      <>
        {weeks.map(week => (
          <Row
            displayMonth={props.displayMonth}
            key={week.weekNumber}
            dates={week.dates}
            weekNumber={week.weekNumber}
          />
        ))}
      </>
    </div>
  )
}
