import { ComponentType } from 'react'
import { PropsWithChildren } from 'react'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { createContext, useContext, useState } from 'react'

type SetState<T> = Dispatch<SetStateAction<T>>

export interface TabEntry {
  label: string
  Component: ComponentType
  pathUrl?: null | string
}

interface LayoutTabContextValue {
  isSelected: null | string
  setIsSelected: SetState<null | string>
  tabs: null | Record<string, TabEntry>
  setTabs: SetState<null | Record<string, TabEntry>>
}

const LayoutTabContext = createContext<LayoutTabContextValue | undefined>(undefined)

export function useNavbarTabLayout() {
  const context = useContext(LayoutTabContext)
  if (context === undefined) {
    throw new Error('useNavbarTabLayout must be used within a LayoutTabProvider')
  }

  return context
}

export function useNavbarTabs(tabs: Record<string, TabEntry>) {
  const { setTabs } = useNavbarTabLayout()

  useEffect(() => {
    setTabs(tabs)

    return () => setTabs(null)
  }, [tabs])
}

export const NavbarTabLayoutProvider = ({ children }: PropsWithChildren) => {
  const [isSelected, setIsSelected] = useState<null | string>(null)
  const [tabs, setTabs] = useState<null | Record<string, TabEntry>>(null)

  return (
    <LayoutTabContext.Provider
      value={{
        isSelected,
        setIsSelected,
        tabs,
        setTabs,
      }}
    >
      {children}
    </LayoutTabContext.Provider>
  )
}
