import DroppableTodoContainer from '../Todo/DroppableTodoContainer'
import { useTodos } from '../../providers/Todo'
import { useState } from 'react'
import classNames from 'classnames'
import { GuidedTour } from '../GuidedTour'

import { DndContext, MeasuringStrategy } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { COLUMNS, ColumnValues } from './constants'
import { useDndTodos } from './hooks/useDndTodos'
import TodoDragLayer from '../Todo/TodoDragLayer'

const Todos = () => {
  const { loadingTodos, filteredTodos } = useTodos()

  const [selectedColumn, setSelectedColumn] = useState<ColumnValues>(COLUMNS.TODAY)

  const selectColumn = (column: ColumnValues) => {
    setSelectedColumn(column)
  }

  const { contextProps, isDragging, isLoading, localTodos, weightIncrement } =
    useDndTodos({
      filteredTodos,
    })

  return (
    <>
      <GuidedTour />
      <DndContext
        {...contextProps}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        modifiers={[restrictToWindowEdges]}
      >
        <TodoDragLayer localTodos={localTodos} />
        <div
          className={classNames(
            `grid xs:grid-rows-1 gap-10`,
            selectedColumn === COLUMNS.TODAY && 'xs:grid-cols-[1.5fr_1fr_1fr]',
            selectedColumn === COLUMNS.TOMORROW && 'xs:grid-cols-[1fr_1.5fr_1fr]',
            selectedColumn === COLUMNS.LATER && 'xs:grid-cols-[1fr_1fr_1.5fr]',
          )}
        >
          {loadingTodos ? (
            <div className='flex justify-center gap-3'>
              <span className='text-sm text-gray-400'>Loading todos</span>
              <div className='animate-spin rounded-full mx-auto border-2 h-6 w-6 border-transparent border-t-gray-400' />
            </div>
          ) : (
            <>
              <DroppableTodoContainer
                formId={COLUMNS.TODAY}
                containerId='todosToday'
                isEmphasized={selectedColumn === COLUMNS.TODAY}
                onColumnSelect={selectColumn}
                todos={localTodos.todosToday}
                weightIncrement={weightIncrement}
                isDragging={isDragging}
                isLoading={isLoading}
              />
              <DroppableTodoContainer
                formId={COLUMNS.TOMORROW}
                containerId='todosTomorrow'
                isEmphasized={selectedColumn === COLUMNS.TOMORROW}
                onColumnSelect={selectColumn}
                todos={localTodos.todosTomorrow}
                weightIncrement={weightIncrement}
                isDragging={isDragging}
                isLoading={isLoading}
              />
              <DroppableTodoContainer
                id='later-column'
                containerId='todosLater'
                formId={COLUMNS.LATER}
                isEmphasized={selectedColumn === COLUMNS.LATER}
                onColumnSelect={selectColumn}
                todos={localTodos.todosLater}
                weightIncrement={weightIncrement}
                isDragging={isDragging}
                isLoading={isLoading}
              />
            </>
          )}
        </div>
        {isLoading && (
          <div className='px-5 flex justify-center gap-1 my-3'>
            <span className='text-sm text-gray-400'>Moving todo...</span>
            <div className='animate-spin rounded-full border-2 h-6 w-6 border-transparent border-t-gray-400' />
          </div>
        )}
      </DndContext>
    </>
  )
}

export default Todos
