import { ReactComponent as ProgressChart } from '../../assets/icons/progress_chart.svg'
import RadioInput from '../RadioInput'

export const PROGRESS_TRACKER_TEXT_COLORS = {
  white: 'white',
  black: 'black',
}

const ProgressTracker = ({
  color = PROGRESS_TRACKER_TEXT_COLORS.white,
  choices,
  progress,
  trackProgress,
}) => {
  const textColor =
    color === PROGRESS_TRACKER_TEXT_COLORS.white ? 'text-white-bg' : 'text-primary-black'

  return (
    <div
      className={`flex flex-col gap-2.5 w-[840px] h-[120px] font-display not-italic p-5 rounded-[10px] bg-panel-bg/[.2] ${textColor}`}
    >
      <div className='flex gap-[11px]'>
        <ProgressChart width='19px' height='19px' />
        <h6 className='font-semibold text-sm'>Track your progress!</h6>
      </div>
      <form className='flex flex-col gap-2.5 pl-[30px]'>
        {choices &&
          choices.items.map(({ id, label, value }) => (
            <RadioInput
              key={id}
              id={id}
              name={choices.name}
              label={label}
              checked={progress === value}
              onClick={() => trackProgress(value)}
            />
          ))}
      </form>
    </div>
  )
}

export default ProgressTracker
