import { DragOverlay, useDndContext } from '@dnd-kit/core'
import Todo from './Todo'
import { useMemo } from 'react'
import { createPortal } from 'react-dom'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'

const TodoDragLayer = ({ localTodos }) => {
  const { active } = useDndContext()

  const activeTodo = useMemo(() => {
    if (active === null) return null

    return Object.values(localTodos)
      .flat()
      .find(t => t.id === active.id)
  }, [active, localTodos])

  return createPortal(
    <DragOverlay modifiers={[restrictToWindowEdges]}>
      {activeTodo && <Todo todo={activeTodo} preview />}
    </DragOverlay>,
    document.body,
  )
}

export default TodoDragLayer
