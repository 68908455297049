import { useToasts } from '../../context/ToastsContext'
import useAsyncLoading from '../../hooks/useAsyncLoading'
import { calendarSync } from '../../utils/api/calendar-sync'

import { ReactComponent as CharacterIcon } from './assets/character-standing.svg'
import { ReactComponent as GoogleCalendarIcon } from '../../assets/icons/google-calendar.svg'
import { useEffect } from 'react'
import { useHighlighting } from '../../providers/HighlightingProvider'
import useAuth from '../../hooks/useAuth'
import { ActionButton } from '../ActionButton'
import { USER_PREFERENCES } from '../../constants'
import { useToggleBooleanUserPreference } from '../../hooks/toggleBooleanUserPreference'

const CalendarConnect = () => {
  const {
    user: {
      googleApiRefreshToken,
      preferences: { isCalendarSynced },
    },
  } = useAuth()
  const { toggleBooleanUserPreference, loading: toggleBooleanUserPreferenceLoading } =
    useToggleBooleanUserPreference()
  const { loading, asyncLoading } = useAsyncLoading()
  const { highlightElement, resetHighlighting } = useHighlighting()
  const addToast = useToasts()

  const gcalSyncActive = googleApiRefreshToken && isCalendarSynced

  const handleToggleIsCalendarSynced = async e => {
    if (googleApiRefreshToken) {
      return await toggleBooleanUserPreference(USER_PREFERENCES.IS_CALENDAR_SYNCED)
    }

    await syncCalendar()

    e.preventDefault()
  }

  useEffect(() => {
    highlightElement('google-calendar-button')

    return () => {
      resetHighlighting()
    }
  }, [highlightElement, resetHighlighting])

  const syncCalendar = asyncLoading(async () => {
    try {
      await calendarSync()
    } catch (err) {
      console.error(err)

      addToast({
        description: err?.message || 'An unknown error occurred, please contact us!',
        variant: 'error',
      })
    }
  })

  return (
    <div className='text-center font-sans space-y-4'>
      <div className='space-y-2.5'>
        <div className='relative inline-block mx-auto '>
          <CharacterIcon className='relative' />

          <div className='absolute right-0 -top-5 p-3.5 rounded-full bg-white'>
            <GoogleCalendarIcon
              className='w-6 h-6 inline-block'
              title='google calendar syncing'
            />
          </div>
        </div>
        <h1 className='text-3xl font-extrabold text-primary-700'>
          Sync your upcoming deadlines
          <br />
          to your Google calendar
        </h1>
        <p className='text-sm text-text-70'>
          We'll sync todos in the Later column to your Google calendar, so
          <br />
          you don't forget them
        </p>
      </div>
      <div className='flex items-center justify-center'>
        <ActionButton
          text='Sync now'
          successText='Synced'
          disabled={loading || toggleBooleanUserPreferenceLoading}
          success={gcalSyncActive}
          onClick={handleToggleIsCalendarSynced}
        />
      </div>
    </div>
  )
}

export { CalendarConnect }
