import { useState } from 'react'
import { useUpdateTodoMutation } from '../../../../providers/Todo'
import { sameDates } from '../../utils'
import { is } from '../../../../utils/object'
import { to0001 } from '../../../../utils/time'
import { getTodoDateValue } from '../../../../providers/Todo/utils'
import { serializeNodes } from '../../../inputs/ContentEditableBox'

const makeTodoDate = (todo, selectedOptionValue, dueDate) => {
  // prioritize the startDate feature first
  if (selectedOptionValue !== null)
    return new Date(to0001(dueDate).getTime() - selectedOptionValue)

  /** always return the todoDate that already exists if:
   * 1. the dueDate value is nullish
   * 2. the todo is rendered in the later column */
  if (!dueDate || todo.computedInTodoColumnId === 'later') return getTodoDateValue(todo)

  /** We don't set the todoDate to today or tomorrow since we would never see the overdue
   * tag on the todo. we leave it to what it was last set to.
   *
   * Otherwise we need to default to the selected dueDate if the todo's todoDate was undefined.
   * this is to make the todo valid by having todoDate and dueDate exist together. **/
  return getTodoDateValue(todo) ?? to0001(dueDate)
}

export function useUpdateFunctions({
  todo,
  value,
  setEditing,
  editedWithStartDate,
  selectProps,
}) {
  const [error, setError] = useState()
  const { mutateAsync, reset, isLoading: loading } = useUpdateTodoMutation()

  const deleteTodo = async () => {
    const updatedTodo = { ...todo, isDeleted: true }
    try {
      await mutateAsync(updatedTodo)
      reset()
      setError()
    } catch (error) {
      console.error(error)
      setError({ message: 'Failed to delete. Please try again', error })
    }
  }

  const editTodo = async ({ date }) => {
    const newTitle = value
    if (serializeNodes(newTitle).trim() === '') {
      editedWithStartDate(false)
      await deleteTodo()
      setEditing(false)
      return
    }

    const { selectedIndex, options } = selectProps
    const selectedOptionValue =
      selectedIndex !== null ? options[selectedIndex].value : null
    const todoDate = makeTodoDate(todo, selectedOptionValue, date)

    const sameDueDates = sameDates(date, todo.dueDate)
    const sameTodoDates = sameDates(todoDate, todo.todoDate)
    const sameTitle = is(todo.title).deepEqual(newTitle)

    if (sameTitle && sameDueDates && sameTodoDates) {
      // nothing changed, so close the editing form
      editedWithStartDate(false)
      setEditing(false)
      return
    }

    try {
      const updatedTodo = { ...todo, title: newTitle, dueDate: date, todoDate }
      editedWithStartDate(selectedIndex !== null)
      await mutateAsync(updatedTodo)
      reset()
      setEditing(false)
    } catch (error) {
      console.error(error)
      setError({ message: 'Failed to update the todo. Please try again', error })
    }
  }

  return {
    loading,
    error,
    editTodo,
  }
}
