import React, { memo } from 'react'
import { Viewport as ToastPrimitiveViewport } from '@radix-ui/react-toast'

import styles from './toast.module.css'

const ToastViewport = () => {
  return (
    <ToastPrimitiveViewport
      className={`${styles.viewport} fixed right-0 bottom-0 flex flex-col gap-2.5 m-0 list-none z-50 outline-0`}
    />
  )
}

const MemoizedToastViewport = memo(ToastViewport)

export { MemoizedToastViewport }
