import classNames from 'classnames'

interface ThumbProps {
  isFilled: boolean
  onClick: () => void
}

export const Thumb = ({ isFilled, onClick }: ThumbProps) => {
  return (
    <div
      className={classNames(
        'w-2 h-2 rounded-full',
        isFilled ? 'bg-gray-600' : 'bg-gray-300',
      )}
      onClick={onClick}
    />
  )
}
