import { ReactComponent as SignOutIcon } from '../../../../assets/icons/sign-out.svg'

export const SignOutButton = ({ signOut }) => {
  return (
    <button
      className='mt-auto disabled:cursor-not-allowed flex items-center gap-2.5 py-4 text-primary-700 font-medium active:font-bold'
      onClick={signOut}
    >
      <SignOutIcon className='h-3.5 text-primary-300' alt='sign out icon' />
      Log out
    </button>
  )
}
