import { useEffect, useRef, useState } from 'react'
import { Transforms, Editor } from 'slate'
import { ReactEditor } from 'slate-react'
import { is } from '../../../utils/object'
import useAuth from '../../../hooks/useAuth'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-empty.svg'
import useTodoForm from '../../../hooks/useTodoForm'
import { useTodos, useUpdateTodoMutation } from '../../../providers/Todo'
import ContentEditableBox, {
  useContentEditableBoxEditor,
  RtfFormat,
  renderRtfFormat,
  serializeNodes,
} from '../../inputs/ContentEditableBox'
import classNames from 'classnames'
import '../index.css'

const UpdateTodoForm = ({ todo, editing, setEditing, preview }) => {
  const [error, setError] = useState()
  const { containerRef, onSubmit, onBlur } = useTodoForm({
    todo,
  })
  const [value, setValue] = useState(
    typeof todo.title === 'string'
      ? [RtfFormat.paragraph([RtfFormat.text(todo.title)])]
      : todo.title,
  )
  const editor = useContentEditableBoxEditor()

  const { mutateAsync, reset, isLoading: loading } = useUpdateTodoMutation()

  const deleteTodo = async () => {
    const updatedTodo = { ...todo, isDeleted: true }
    try {
      await mutateAsync(updatedTodo)
      reset()
      setError()
    } catch (error) {
      console.error(error)
      setError({ message: 'Failed to delete. Please try again', error })
    }
  }

  const editTodo = async ({ date }) => {
    const newTitle = value
    if (serializeNodes(newTitle).trim() === '') {
      await deleteTodo()
      return setEditing(false)
    }

    const datesDefined = date && todo.dueDate
    const sameDates = new Date(date).getTime() === new Date(todo.dueDate).getTime()
    if (is(todo.title).deepEqual(newTitle) && datesDefined && sameDates)
      // nothing changed, so close the editing form
      return setEditing(false)

    try {
      const updatedTodo = { ...todo, title: newTitle, dueDate: date }
      await mutateAsync(updatedTodo)
      reset()
      setEditing(false)
    } catch (error) {
      console.error(error)
      setError({ message: 'Failed to update the todo. Please try again', error })
    }
  }

  const handleSubmit = onSubmit(editTodo)
  const handleBlur = onBlur(editTodo)

  /* Activates the editor text field */
  useEffect(() => {
    if (editing && editor) {
      /* Displays cursor upon activating the editor */
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
      })
      ReactEditor.focus(editor)
    }
  }, [editing, editor])

  const onTitleKeyUp = async event => {
    if (event.code === 'Enter' && !event.shiftKey) {
      await handleSubmit(event)
    }
  }

  return (
    <form
      className='w-full'
      ref={containerRef}
      onSubmit={handleSubmit}
      onBlur={handleBlur}
      disabled={loading || preview}
    >
      <div className='flex py-1 flex-col break-word todo-box'>
        <div className='font-normal flex items-start ml-6'>
          <input
            readOnly
            disabled
            checked={todo.isComplete}
            type='checkbox'
            className={'todo-input -ml-8 mr-2 w-[18px] h-[18px] align-middle'}
          />
          <ContentEditableBox
            name='title'
            required
            aria-required
            className='inline-block w-full outline-none bg-transparent resize-none align-text-top caret-[#0D58D9]'
            placeholder='Enter to-do here'
            autoFocus
            editor={editor}
            onKeyUp={onTitleKeyUp}
            value={value}
            setValue={setValue}
          />
        </div>
      </div>
      {error && <p className='text-red-500'>{error.message}</p>}
    </form>
  )
}

const AgendaTodo = ({ preview, todo }) => {
  const [editing, setEditing] = useState(false)
  const [localIsComplete, setLocalIsComplete] = useState(todo.isComplete)
  const { getNewTodoWeight } = useTodos()

  const { user } = useAuth()
  const [error, setError] = useState()

  const { mutateAsync, reset, isLoading: loading } = useUpdateTodoMutation()

  const initUpdateController = () => ({
    processing: false,
    timeoutId: 0,
    runUpdateBy: 0,
    commitUpdate: todo => async () => {},
  })
  const updateControllerRef = useRef(initUpdateController())
  const createUpdateFunction = updateObj => todo => async () => {
    // update the ref to indicate we are processing the request
    updateControllerRef.current.processing = true
    // only commit operation if the update object has new object values
    if (is(updateObj).subsetOf(todo)) {
      updateControllerRef.current = initUpdateController()
      return
    }
    // a value inside the update object was different
    const updatedTodo = { ...todo, ...updateObj }
    try {
      await mutateAsync(updatedTodo)
      reset()
      setError()
    } catch (error) {
      console.error(error)
      setError({ message: 'Failed to complete the todo. Please try again', error })
    } finally {
      updateControllerRef.current = initUpdateController()
    }
  }

  const editTodoTitle = async event => {
    return setEditing(true)
  }
  const checkOffTodo = async event => {
    const isComplete = event.target.checked || false
    // create the update todo function
    setLocalIsComplete(isComplete)
    const weight = isComplete ? getNewTodoWeight(todo) : todo.weight

    const commitUpdate = createUpdateFunction({ isComplete, weight })
    const commitUpdateWithTodo = commitUpdate(todo)
    if (!user?.preferences.showCompletedTasks) {
      clearTimeout(updateControllerRef.current.timeoutId)
      // track when the commitUpdate function with a todo
      // should trigger.
      updateControllerRef.current = {
        runUpdateBy: Date.now() + 5000,
        timeoutId: setTimeout(commitUpdateWithTodo, 5000),
        commitUpdate,
      }
      return
    }
    // if we want to show completed tasks, then run right away
    await commitUpdateWithTodo()
  }
  const deleteTodo = async () => {
    const updatedTodo = { ...todo, isDeleted: true }
    try {
      await mutateAsync(updatedTodo)
      reset()
      setError()
    } catch (error) {
      console.error(error)
      setError({ message: 'Failed to delete. Please try again', error })
    }
  }

  /**
   * This effect ensures that the todo object for the debounced update timeout
   * trigger is synchronized. If this were not the case, the PUT operation would
   * not execute the update since we would be using an old read of the todo object.
   */
  useEffect(() => {
    const { processing, timeoutId, runUpdateBy, commitUpdate } =
      updateControllerRef.current
    if (timeoutId && !processing) {
      clearTimeout(timeoutId)
      updateControllerRef.current.timeoutId = setTimeout(
        commitUpdate(todo),
        runUpdateBy - Date.now(),
      )
    }
  }, [todo])

  /**
   * This is used to clean up the checkOff timeout callback
   * in the case where the the todo component is unmounted indefinitely.
   *
   * if the timeout was to trigger, then we would be updating state that no
   * longer exists
   */
  useEffect(
    () =>
      // cleanup callback
      () =>
        clearTimeout(updateControllerRef.current.timeoutId),
    [],
  )

  const todoClass = classNames(
    'todo group py-1.5 px-5 rounded-xl',
    'hover:bg-[#0F67FF1A] focus-within:bg-transparent',
    'relative hover:z-30 focus-within:z-30',
    'hover:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)] focus-within:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)]',
    preview &&
      'drop-shadow-[-2px_3px_4px_0_rgba(66,144,243,0.08)] opacity-50 border-primary/40 bg-primary/5',
    editing && 'drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)]',
  )

  return (
    <div className={todoClass}>
      <div className='flex gap-2 items-center justify-between'>
        {editing ? (
          <UpdateTodoForm todo={todo} setEditing={setEditing} preview={preview} editing />
        ) : (
          <div className='flex flex-col break-word todo-box'>
            <div className='font-normal flex items-start ml-6'>
              <input
                onChange={checkOffTodo}
                disabled={editing || loading}
                checked={localIsComplete}
                type='checkbox'
                className='-ml-8 mr-2 w-[18px] h-[18px] align-middle'
              />
              {typeof todo.title === 'string' ? (
                <span
                  onClick={editTodoTitle}
                  className={classNames(
                    todo.isComplete && '!text-[#C1BCCC] !line-through',
                    'align-middle break-words',
                  )}
                  dangerouslySetInnerHTML={{ __html: todo.title }}
                />
              ) : (
                <span
                  onClick={editTodoTitle}
                  className={classNames(
                    todo.isComplete && '!text-[#C1BCCC] !line-through',
                    'align-middle break-words',
                  )}
                >
                  {todo?.title?.map((n, i) => renderRtfFormat(n, i))}
                </span>
              )}
            </div>
            {error && <p className='text-red-500'>{error.message}</p>}
          </div>
        )}

        {!preview && !editing && (
          <>
            <button
              onClick={deleteTodo}
              disabled={loading}
              className='self-baseline hidden group-hover:enabled:block
              group-focus-within:enabled:block group-focus-visible:enabled:block
              todo-button pb-0.5 px-1.5 rounded-md text-[#6B667B]
              enabled:hover:bg-neutral-300
              enabled:hover:text-[#625a7a]'
            >
              <TrashIcon
                className='inline-block align-middle h-3.5'
                title='delete icon'
              />
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default AgendaTodo
