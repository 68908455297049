import { getTodoWeightFromDirectSiblings } from '../../../providers/Todo/utils'
import { useState } from 'react'
import { Fragment } from 'react'
import AgendaInsertTodo from './AgendaInsertTodo'
import AgendaTodoForm from './AgendaTodoForm'
import AgendaTodo from './AgendaTodo'

const AgendaTodoContainer = ({ todos = [], weightIncrement, autoFocusForm, date }) => {
  const { remainingTodos, completedTodos } = todos.reduce(
    ({ remainingTodos, completedTodos }, todo) => {
      const list = todo.isComplete ? completedTodos : remainingTodos
      list.push(todo)
      return { remainingTodos, completedTodos }
    },
    { remainingTodos: [], completedTodos: [] },
  )

  const lastWeight =
    (remainingTodos.length > 0 ? remainingTodos[remainingTodos.length - 1].weight : 0) +
    weightIncrement
  const nextWeight = (i, list) => {
    const { newWeight } = getTodoWeightFromDirectSiblings(list, i + 1, weightIncrement)
    return newWeight
  }

  const todoFn = (todo, virtualIndex, list) => (
    <Fragment key={todo.id || virtualIndex}>
      <AgendaTodo todo={todo} virtualIndex={virtualIndex} />
      {virtualIndex !== list.length - 1 && (
        <AgendaInsertTodo nextWeight={nextWeight(virtualIndex, list)} date={date} />
      )}
    </Fragment>
  )

  return (
    <div>
      {remainingTodos.map(todoFn)}
      {completedTodos.map(todoFn)}
      <AgendaTodoForm nextWeight={lastWeight} autoFocus={autoFocusForm} date={date} />
    </div>
  )
}

export default AgendaTodoContainer
