import { authorizedApiFetch, apiFetch } from '.'
import { parseJSONResponse } from './utils'

function createUser(userData) {
  return apiFetch('/api/user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  })
}

function getUser(userId) {
  return authorizedApiFetch(`/api/user?userId=${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

function updateUser(userData, opts) {
  const { id } = userData
  const { onboard = false } = opts || {}
  return authorizedApiFetch(`/api/user/${id}?onboard=${onboard}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  })
}

function postCareerProgress({ userId, section, value }) {
  return authorizedApiFetch('/api/user/track-career-progress', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, section, value }),
  })
}

/**
 * Gets the user's preintern application items
 *
 * @param {String} userId
 * @param {Object} [param1]
 * @param {AbortSignal} [param1.signal]
 */
function getUserPreinternApplications(userId, { signal } = {}) {
  return authorizedApiFetch(`/api/user/preintern-application?userId=${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  }).then(parseJSONResponse)
}

function getPreinternApplicationSignedFileRequest(fileName, contentType) {
  return authorizedApiFetch(
    `/api/user/preintern-application/s3/signed-request?${new URLSearchParams({
      contentType,
      fileName,
    })}`,
  ).then(parseJSONResponse)
}

/**
 * NOTE: if you copy this function for another component that needs s3 put requests,
 * please let Thomas L'Anglais know. If it's repeated enough we'll want to refactor
 * in order to keep the code in one place
 */
function putFileToS3(content, signedRequest) {
  return fetch(signedRequest, {
    method: 'PUT',
    headers: {
      'x-amz-acl': 'public-read',
    },
    body: content,
  }).then(response => {
    if (!response.ok) {
      throw new Error('Error putting file to s3')
    }
    return response
  })
}

function postPreinternApplication(application) {
  return authorizedApiFetch('/api/user/preintern-application', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(application),
  }).then(parseJSONResponse)
}

function putPreinternApplication(application) {
  return authorizedApiFetch(`/api/user/preintern-application/${application.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(application),
  }).then(parseJSONResponse)
}

async function postTrackProgress({ userId, section, value }) {
  const response = await authorizedApiFetch(`/api/user/track-career-progress`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, section, value }),
  })
  return parseJSONResponse(response)
}

async function postCompleteGuidedTour({ userId }) {
  return await authorizedApiFetch(`/api/user/complete-guided-tour`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
  })
}

const api = {
  createUser,
  getUser,
  updateUser,
  postCareerProgress,
  getUserPreinternApplications,
  getPreinternApplicationSignedFileRequest,
  putFileToS3,
  postPreinternApplication,
  putPreinternApplication,
  postTrackProgress,
  postCompleteGuidedTour,
}

export default api
