import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { answerChallengeChannel } from '../utils/channels'

const AnswerChallenge = () => {
  const [params] = useSearchParams()
  const answer = params.get('answer')
  const username = params.get('username')
  console.log({ answer, username })
  useEffect(() => {
    const channel = answerChallengeChannel()
    channel.postMessage({ answer, username })

    return () => channel.close()
  }, [answer, username])

  return <div className='p-6'>Please close this tab and re-open the original</div>
}

export default AnswerChallenge
