import React from 'react'
// this package is for accessibility and gesture support
import * as ToastPrimitive from '@radix-ui/react-toast'
import classNames from 'classnames'

// using CSS modules since I'm very slow at writing transition/animation in tailwind
import styles from './toast.module.css'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'

// Adding more props here than necessary for CalendarConnect.jsx since we may need them in the future
// Please change styling if necessary
const Toast = ({
  description,
  variant,
  isClosable = false,
  actionTitle,
  actionFn,
  onClose,
}) => {
  const handleOpenChange = open => {
    if (open) return

    onClose()
  }

  const rootClasses = classNames(
    styles.root,
    'drop-shadow-xl bg-white rounded-md p-3.5 relative',
    {
      'bg-primary-500 text-white': variant === 'success',
      'bg-red-500 text-white': variant === 'error',
      'bg-yellow-500 text-white': variant === 'warning',
    },
  )

  const closeClasses = classNames(
    styles.close,
    'absolute w-6 h-6 p-1.5 -top-1.5 -right-1.5 bg-white rounded-full drop-shadow-2xl cursor-pointer',
    {
      'bg-primary-500 text-white': variant === 'success',
      'bg-red-500 text-white': variant === 'error',
      'bg-yellow-500 text-white': variant === 'warning',
    },
  )

  return (
    <ToastPrimitive.Root className={rootClasses} onOpenChange={handleOpenChange}>
      {!!description?.trim() && (
        <ToastPrimitive.Description className='m-0 text-sm'>
          {description.trim()}
        </ToastPrimitive.Description>
      )}

      {!!(actionTitle && actionFn) && (
        <ToastPrimitive.Action
          asChild
          altText={`${actionTitle} (Alt+U)`}
          className={styles.action}
          onClick={actionFn}
        >
          <button>{actionTitle}</button>
        </ToastPrimitive.Action>
      )}

      {isClosable && (
        <ToastPrimitive.Close aria-label='close' className={closeClasses}>
          <CloseIcon />
        </ToastPrimitive.Close>
      )}
    </ToastPrimitive.Root>
  )
}

export { Toast }
