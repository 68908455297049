import { nanoid } from 'nanoid'
import React, { useState, useRef, useCallback } from 'react'

import { EmptyBox } from './EmptyBox'
import { FilesList } from './FilesList'

import { authorizedApiFetch } from '../../utils/api'
import useAuth from '../../hooks/useAuth'
import { useToasts } from '../../context/ToastsContext'
import bytes from 'bytes'

const ALLOWED_CONTENTTYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const MAX_FILE_SIZE = bytes('2MB')

const UploadScreen = ({ onSuccess }) => {
  const { userId } = useAuth()
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef(null)
  const addToast = useToasts()
  const [isFirstUpload, setIsFirstUpload] = useState(true)

  const validFiles = files.filter(file => !file.validationError)

  const triggerFileSelect = useCallback(e => {
    e.preventDefault()
    fileInputRef.current.click()
  }, [])

  const validateFiles = newFiles => {
    const validatedFiles = []

    Array.from(newFiles).forEach(file => {
      const { name, size, type } = file
      // don't add file if it already exists
      if (files.find(f => f.content?.name === name)) {
        addToast({
          description: `File ${name} already imported`,
          variant: 'warning',
        })
        return
      }

      const parsedFile = {
        id: nanoid(),
        validationError: null,
        uploadStatus: null,
        content: file,
      }

      if (size > MAX_FILE_SIZE) {
        parsedFile.validationError = 'File size exceeds 2MB'
      }
      if (!ALLOWED_CONTENTTYPES.includes(type)) {
        parsedFile.validationError = 'File type not allowed'
      }

      validatedFiles.push(parsedFile)
    })
    return validatedFiles
  }

  const addFiles = item => {
    const newFiles = item?.files
    if (newFiles.length < 1) return
    const filesToAdd = validateFiles(newFiles)
    setFiles(files => [...files, ...filesToAdd])
  }

  const deleteFile = id => {
    setFiles(files => files.filter(file => file.id !== id))
  }

  const handleUploading = id => {
    setFiles(files => {
      return files.map(f => {
        if (f.id === id) {
          f.uploadStatus = 'uploading'
        }
        return f
      })
    })
  }

  const handleUploadError = id => {
    setFiles(files => {
      return files.map(f => {
        if (f.id === id) {
          f.uploadStatus = 'error'
        }
        return f
      })
    })
  }

  const handleUploadSuccess = id => {
    setFiles(files => {
      return files.map(f => {
        if (f.id === id) {
          f.uploadStatus = 'success'
        }
        return f
      })
    })
  }

  // Track if user has used this feature
  function setFileUploadLocalStorage() {
    const promo = localStorage.getItem('eot_promo')

    // If no promo object exists, user hasn't seen promo popup or uploaded files before
    if (!promo) {
      const promoObj = { fileUploaded: true }
      return localStorage.setItem('eot_promo', JSON.stringify(promoObj))
    }

    const promoObj = JSON.parse(promo)

    // If there's record of user uploading files, set firstUpload state to false
    if (promoObj?.fileUploaded) {
      return setIsFirstUpload(false)
    }

    // If there's no record of user uploading files, set it to true
    promoObj.fileUploaded = true
    return localStorage.setItem('eot_promo', JSON.stringify(promoObj))
  }

  const uploadFile = file => {
    handleUploading(file.id)

    let fileUrl
    return getSignedFileRequest(file.content?.type)
      .then(({ signedRequest, url }) => {
        fileUrl = url
        return putFileToS3(file.content, signedRequest)
      })
      .then(() => createBulkUploadRecord(fileUrl, userId))
      .then(() => handleUploadSuccess(file.id))
      .catch(err => {
        console.error(err)
        handleUploadError(file.id)
      })
  }

  const uploadFiles = async () => {
    const filesNeedUpload = validFiles.filter(file => file.uploadStatus !== 'success')

    setLoading(true)
    for (const file of filesNeedUpload) {
      await uploadFile(file)
    }
    setLoading(false)
    setFileUploadLocalStorage()
  }

  const allFilesUploaded =
    validFiles?.length > 0 && validFiles.every(file => file.uploadStatus === 'success')

  const handleSubmit = () => {
    if (allFilesUploaded) {
      return onSuccess(isFirstUpload)
    }
    uploadFiles()
  }

  const submitBtnRender = () => {
    if (loading) {
      return (
        <>
          Submitting...
          <span className='animate-spin rounded-full ml-2.5 border-2 h-4 w-4 border-transparent border-t-white'></span>
        </>
      )
    }

    if (allFilesUploaded) {
      return 'Done'
    }

    return 'Submit'
  }

  return (
    <div className='flex flex-col items-center gap-6'>
      <p className='font-display text-sm leading-6 p-5 bg-primary/5 text-gray-700 rounded-xl'>
        Want text reminders before your upcoming project due dates or exams across all of
        your classes? Upload screenshots, PDFs or syllabi for each class this term. Hint:
        You can save course websites as PDF and upload that file as well.
      </p>

      {files.length === 0 ? (
        <EmptyBox triggerFileSelect={triggerFileSelect} addFiles={addFiles} />
      ) : (
        <FilesList
          files={files}
          triggerFileSelect={triggerFileSelect}
          deleteFile={deleteFile}
          uploadFile={uploadFile}
        />
      )}

      <input
        type='file'
        multiple
        accept={ALLOWED_CONTENTTYPES.join(', ')}
        hidden
        ref={fileInputRef}
        onChange={e => addFiles(e.target)}
      />

      <button
        className='w-[166px] h-[40px] flex justify-center items-center bg-primary text-white rounded-xl text-sm font-medium enabled:hover:drop-shadow-xl enabled:active:drop-shadow-none disabled:bg-[#DFDFE1]'
        disabled={validFiles?.length < 1 || loading}
        onClick={handleSubmit}
      >
        {submitBtnRender()}
      </button>
    </div>
  )
}

function getSignedFileRequest(contentType) {
  return authorizedApiFetch(
    `/api/bulk-upload/s3/signed-request?${new URLSearchParams({
      contentType,
    })}`,
  ).then(response => {
    if (!response.ok) {
      throw new Error('Error getting signed request')
    }
    return response.json()
  })
}

/**
 * NOTE: if you copy this function for another component that needs s3 put requests,
 * please let Thomas L'Anglais know. If it's repeated enough we'll want to refactor
 * in order to keep the code in one place
 */
function putFileToS3(content, signedRequest) {
  return fetch(signedRequest, {
    method: 'PUT',
    headers: {
      'x-amz-acl': 'public-read',
    },
    body: content,
  }).then(response => {
    if (!response.ok) {
      throw new Error('Error putting file to s3')
    }
    return response
  })
}

function createBulkUploadRecord(url, userId) {
  return authorizedApiFetch(`/api/bulk-upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      url,
      userId,
    }),
  }).then(response => {
    if (!response.ok) {
      throw new Error('Error creating bulk upload record')
    }
    return response
  })
}

export { UploadScreen }
