import React, { useId } from 'react'
import { useDayPicker, useNavigation } from 'react-day-picker'
import CustomCaption from './CustomCaption'
import { Table } from './Table'

/**
 * Render a month.
 *
 * @param {object} props
 * @param {number} props.displayIndex
 * @param {Date} props.displayMonth
 */
export function Month(props) {
  const dayPicker = useDayPicker()
  const { dir, classNames, styles } = dayPicker
  const { displayMonths } = useNavigation()

  const captionId = useId(
    dayPicker.id ? `${dayPicker.id}-${props.displayIndex}` : undefined,
  )

  const className = [classNames.month, 'space-y-5']
  let style = styles.month

  let isStart = props.displayIndex === 0
  let isEnd = props.displayIndex === displayMonths.length - 1
  const isCenter = !isStart && !isEnd
  if (dir === 'rtl') {
    ;[isEnd, isStart] = [isStart, isEnd]
  }

  if (isStart) {
    className.push(classNames.caption_start)
    style = { ...style, ...styles.caption_start }
  }
  if (isEnd) {
    className.push(classNames.caption_end)
    style = { ...style, ...styles.caption_end }
  }
  if (isCenter) {
    className.push(classNames.caption_between)
    style = { ...style, ...styles.caption_between }
  }

  return (
    <div key={props.displayIndex} className={className.join(' ')} style={style}>
      <CustomCaption id={captionId} displayMonth={props.displayMonth} />
      <Table aria-labelledby={captionId} displayMonth={props.displayMonth} />
    </div>
  )
}
