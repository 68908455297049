export function answerChallengeChannel() {
  // mock if BroadcastChannel doesn't exist
  if (!BroadcastChannel)
    return {
      postMessage: () => {},
      onmessage: () => {},
      close: () => {},
    }

  const channel = new BroadcastChannel('answerChallenge')
  return channel
}
