import { ReactNode, forwardRef } from 'react'
import { useHighlighting } from '../../providers/HighlightingProvider'
import classNames from 'classnames'

interface HighlighterProps {
  id?: string
  className?: string
  children: ReactNode
}

export const Highlighter = forwardRef<HTMLDivElement, HighlighterProps>(
  ({ id, className, children }, ref) => {
    const { highlightedElementId, customElement, hideChildrenOnMobile } =
      useHighlighting()

    return id === highlightedElementId ? (
      <div ref={ref} className='z-[60]'>
        <div className={className}>{children}</div>
        {customElement}
      </div>
    ) : (
      <div
        ref={ref}
        className={classNames(hideChildrenOnMobile ? 'hidden xs:block' : 'block')}
      >
        {children}
      </div>
    )
  },
)
