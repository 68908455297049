import ProgressNumber from '../../../ProgressNumber'

export const SECTION_HEADER_TEXT_COLORS = {
  white: 'white',
  blue: 'blue',
}

const SectionHeader = ({
  sectionNumber,
  title,
  subtitle,
  disclaimer,
  color = SECTION_HEADER_TEXT_COLORS.white,
}) => {
  const textColor =
    color === SECTION_HEADER_TEXT_COLORS.white ? 'text-white-bg' : 'text-text'

  return (
    <div className={`relative ${textColor}`}>
      <div className='absolute right-full mr-8 top-0'>
        <ProgressNumber number={sectionNumber} />
      </div>
      <div>
        <h1 className='font-semibold text-3xl'>{title}</h1>
        <p className='text-sm font-normal'>{subtitle}</p>
        {disclaimer ? (
          <p className='text-text-700 text-sm mt-2.5 italic'>{disclaimer}</p>
        ) : null}
      </div>
    </div>
  )
}

export default SectionHeader
