import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import useAuth from '../../../hooks/useAuth'
import { useTodos } from '../../../providers/Todo'
import { useResponsiveWindow } from '../../../hooks/useResponsiveWindow'

interface MobileTodoDeleteCallToActionHookArguments {
  todo: any
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setContent: Dispatch<SetStateAction<ReactNode>>
}

export function useTodoMobileDeleteCallToAction({
  todo,
  setIsOpen,
  setContent,
}: MobileTodoDeleteCallToActionHookArguments) {
  const { user } = useAuth()
  const { isFirstColumnTodo } = useTodos()
  const { isMobile } = useResponsiveWindow()
  const [usedMobileDelete, setUsedMobileDelete] = useLocalStorage(
    'eot-used-todo-mobile-delete',
    false,
  )

  const triggeredOnce = useRef(false)

  const todoId = todo.id as string
  const guidedTourComplete = (user as any).guidedTourComplete as boolean
  const isFirstTodoInTodayColumn = isFirstColumnTodo({ todoId })

  useEffect(() => {
    if (
      triggeredOnce.current ||
      // we need to check if it has an id, since we optimistically add it
      !todo.id ||
      !isMobile ||
      !isFirstTodoInTodayColumn ||
      !guidedTourComplete
    )
      return

    if (!usedMobileDelete) {
      setContent('Press and hold to delete a todo')
      setIsOpen(true)
      setUsedMobileDelete(true)
    }
    triggeredOnce.current = true
  }, [
    todo,
    usedMobileDelete,
    setUsedMobileDelete,
    isFirstTodoInTodayColumn,
    guidedTourComplete,
    isMobile,
  ])
}
