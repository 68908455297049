import { useEffect, useState } from 'react'
import { RootTodoObject } from './types'

interface DragAndDropTodosArguments<T extends RootTodoObject> {
  filteredTodos: T
}

/**
 * We use a synced state version so that we can move todos across columns
 * without the need to call the API to force the change
 */
export function useLocalDragAndDropTodos<T extends RootTodoObject>({
  filteredTodos,
}: DragAndDropTodosArguments<T>) {
  const [localTodos, setLocalTodos] = useState(filteredTodos || {})
  useEffect(() => setLocalTodos(filteredTodos || {}), [filteredTodos])

  return {
    localTodos,
    setLocalTodos,
  }
}
