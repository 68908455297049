import deepEqual from 'deep-equal'

/**
 * Shallow compare two objects
 *
 * @param {Object} object1
 * @param {Object} object2
 * @returns {Boolean}
 */
function isShallowEqual(object1, object2) {
  const keysOf1 = Object.keys(object1)
  const keysOf2 = Object.keys(object2)
  if (keysOf1.length !== keysOf2.length) {
    return false
  }
  for (let key of keysOf1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}

/**
 * checks if object1 is a subset of properties of object2
 *
 * @param {Object} object1
 * @param {Object} object2
 * @returns {Boolean}
 */
function isSubsetOf(object1, object2) {
  const keysOf1 = Object.keys(object1)
  for (let key of keysOf1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}

export const is = object1 => ({
  deepEqual: object2 => deepEqual(object1, object2, { strict: true }),
  shallowEqual: object2 => isShallowEqual(object1, object2),
  subsetOf: object2 => isSubsetOf(object1, object2),
})
