import { parsePhoneNumber } from 'libphonenumber-js'
import { countryCode } from './getCountryCode'
import { CountryCode } from 'libphonenumber-js'

export function isPhoneNumberValid(number: string | undefined) {
  if (!number) return false

  const results = parsePhoneNumber(number, countryCode as CountryCode)
  return results?.isValid() || false
}
