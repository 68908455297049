import { useQuery } from 'react-query'
import useAuth from '../../../../hooks/useAuth'
import api from '../../../../utils/api/interview-prep-problems'

export function useDefaultInterviewPrepProblemListQuery() {
  const { userId, isAuthenticated } = useAuth()

  return useQuery(
    ['default-problems', userId],
    ({ signal }) => api.getDefaultInterviewProblems(userId, { signal }),
    {
      enabled: isAuthenticated,
      initialData: [],
    },
  )
}
