import { useMemo, useState } from 'react'
import { differenceInCalendarDays, format } from 'date-fns'
import CalendarContext from '../../context/CalendarContext'
import { Calendar as RootCalendar } from './Calendar'
import { useTodos } from '../../providers/Todo'

const isPastDate = date => {
  return differenceInCalendarDays(date, new Date()) < 0
}

const Calendar = () => {
  const [selected, setSelected] = useState()
  const { userTodosWithDueDates } = useTodos()

  const allTodos = useMemo(() => {
    const tempObj = {}
    if (userTodosWithDueDates && userTodosWithDueDates.length > 0) {
      const todosToShow = userTodosWithDueDates.filter(reminder => !reminder.isDeleted)

      todosToShow.forEach(todo => {
        const date = format(new Date(todo.dueDate), 'yyyy-MM-dd')
        if (tempObj[date]) {
          tempObj[date].push(todo)
        } else {
          tempObj[date] = [todo]
        }
      })
    }
    return tempObj
  }, [userTodosWithDueDates])

  const calendarValue = { allTodos }

  return (
    <CalendarContext.Provider value={calendarValue}>
      <RootCalendar
        mode='single'
        selected={selected}
        disabled={isPastDate}
        fromDate={new Date(new Date().setDate(1))}
        onSelect={setSelected}
        formatters={{
          formatWeekdayName: (weekday, options) => format(weekday, 'ccc', options),
        }}
      />
    </CalendarContext.Provider>
  )
}

export default Calendar
