import Toggle from '../../../Toggle'

export const ShowCompletedTodosToggle = ({
  loading,
  toggleCompletedTask,
  completedTasksEnabled,
  completedTaskToggleText,
}) => {
  return (
    <div
      className='flex items-center justify-start gap-2.5 px-2 py-4 active:bg-primary-600/10'
      onClick={() => {
        if (loading) return
        toggleCompletedTask()
      }}
    >
      <span className='grow'>Show completed todos</span>
      <Toggle
        disabled={loading}
        checked={completedTasksEnabled}
        onChange={toggleCompletedTask}
        title={completedTaskToggleText}
        srText={completedTaskToggleText}
        className='self-end'
      />
    </div>
  )
}
