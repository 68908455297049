import { useEffect, useState } from 'react'

export function useResponsiveWindow() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    let timer
    const handleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        setWindowWidth(window.innerWidth)
      }, 400)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(timer)
    }
  }, [])

  // the mobile screen we target is = to xs in the tailwind.config.js file
  const isMobile = windowWidth < 480

  return {
    windowWidth,
    isMobile,
  }
}
