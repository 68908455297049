import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  useClick,
  useInteractions,
  useTransitionStyles,
  FloatingFocusManager,
  useRole,
  useDismiss,
  useMergeRefs,
} from '@floating-ui/react'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { toDueDate } from '../../../utils/date'
import classNames from 'classnames'
import { forwardRef, useCallback } from 'react'
import { longPressExemptProps } from '../../../hooks/useLongPress'
import Calendar from './Calendar'

export const DesktopDatePicker = forwardRef(
  (
    {
      open,
      setOpen,
      selected,
      setSelected,
      optional,
      disabled,
      fromDate,
      renderButtonContent,
      buttonClassName,
      disableBlurEvents,
      ...popoverProps
    },
    ref,
  ) => {
    const { x, y, strategy, context, refs } = useFloating({
      open,
      onOpenChange: setOpen,
      placement: 'bottom',
      middleware: [offset(16), flip(), shift()],
      strategy: 'absolute',
      whileElementsMounted: autoUpdate,
    })

    const click = useClick(context, { toggle: true })
    const role = useRole(context, { role: 'dialog' })
    // debugger
    const dismiss = useDismiss(context)

    const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
      duration: 300,
      initial: {
        opacity: 0,
        transform: 'scale(0.95)',
      },
      open: {
        opacity: 100,
        transform: 'scale(1)',
      },
      close: {
        opacity: 0,
        transform: 'scale(0.95)',
      },
      common: {
        transitionTimingFunction: 'ease-in-out',
      },
    })

    const { getReferenceProps, getFloatingProps } = useInteractions([
      click,
      role,
      dismiss,
    ])

    fromDate ??= new Date()

    renderButtonContent ??= () => {
      const formattedDate = selected ? toDueDate(selected) : null
      return !formattedDate ? (
        <span className='whitespace-nowrap'>Select due date</span>
      ) : (
        <>
          <span className='font-medium text-primary-700'>{formattedDate}</span>
          {optional && (
            <div
              tabIndex={0}
              role='button'
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setSelected()
                setOpen(false)
              }}
              className='rounded-full'
            >
              <CloseIcon className='h-3 text-primary-700' />
            </div>
          )}
        </>
      )
    }
    const selectedDateContent = renderButtonContent()

    const setReference = useCallback(
      node => {
        if (!ref || typeof ref === 'function') return
        ref.current.reference = node
      },
      [ref],
    )
    const referenceRef = useMergeRefs([setReference, refs.setReference])
    const setFloating = useCallback(
      node => {
        if (!ref || typeof ref === 'function') return
        ref.current.floating = node
      },
      [ref],
    )
    const floatingRef = useMergeRefs([setFloating, refs.setFloating])

    return (
      <div {...popoverProps}>
        <button
          type='button'
          ref={referenceRef}
          {...getReferenceProps({
            onClick(event) {
              event.preventDefault()
              event.stopPropagation()
              // setOpen(prev => !prev)
              refs.reference.current?.focus()
            },
            onMouseDown(event) {
              event.preventDefault()
              event.stopPropagation()
            },
            disabled: disabled,
            className:
              buttonClassName ||
              classNames(
                'relative inline-flex gap-2 items-center',
                'text-xs rounded-full py-1.5 px-2 border border-transparent outline-none',
                'disabled:bg-[#DFDFE1] disabled:text-white',
                !selected && 'bg-text-200 hover:border-text-700 focus:border-text-700',
                (open || selected) &&
                  'bg-gradient-to-r from-primary-600/5 to-primary-600/5 !bg-white !border-primary-dark',
              ),
            ...longPressExemptProps(),
          })}
        >
          {selectedDateContent}
        </button>
        {isMounted && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={floatingRef}
              {...getFloatingProps({
                style: {
                  position: strategy,
                  top: y,
                  left: x,
                  zIndex: 40,
                  ...transitionStyles,
                },
              })}
            >
              <div className='p-4 bg-white drop-shadow-lg shadow-xl min-w-[21rem]'>
                <Calendar
                  selected={selected}
                  setSelected={selectedDate => {
                    // debugger
                    setSelected(selectedDate)
                    setOpen(false)
                  }}
                />
              </div>
            </div>
          </FloatingFocusManager>
        )}
      </div>
    )
  },
)
