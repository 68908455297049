import { DISCORD_LINK } from '../../../../../constants'
import { ReactComponent as BulbIcon } from '../assets/bulb.svg'

const SupportWarning = () => {
  return (
    <div className=' flex items-center gap-[18px] bg-panel-bg/[.2] w-[840px] h-[70px] rounded-[10px] p-[19px]'>
      <BulbIcon width='32px' height='32px' />
      <p className='text-primary-black text-sm'>
        If you’re having trouble, join{' '}
        <a
          className='text-primary-dark'
          href={DISCORD_LINK}
          target='_blank'
          rel='noopener noreferrer'
        >
          Edlyft’s discord
        </a>{' '}
        to connect with a community of other people building their skills
      </p>
    </div>
  )
}

export default SupportWarning
