import { USER_PREFERENCES } from '../../../constants'
import { useToggleBooleanUserPreference } from '../../../hooks/toggleBooleanUserPreference'
import useAuth from '../../../hooks/useAuth'
import { ReactComponent as TextRemindersImage } from '../assets/text-reminders.svg'
import { StepTemplate } from '../StepTemplate'
import { PhoneForm } from './PhoneForm/PhoneForm'
import { isPhoneNumberValid } from '../../../utils/phoneNumber'

export const TextReminders = ({ isOnboarding = false, onSuccessButtonClick }) => {
  const { toggleBooleanUserPreference, loading } = useToggleBooleanUserPreference()
  const { user } = useAuth()

  const { preferences: { textWarningsEnabled } = {}, phoneNumber } = user
  const success = textWarningsEnabled && isPhoneNumberValid(phoneNumber)

  const toggleTextReminders = event => {
    event && event.preventDefault()

    toggleBooleanUserPreference(USER_PREFERENCES.TEXT_WARNINGS_ENABLED)
  }

  return (
    <StepTemplate
      image={<TextRemindersImage width='100%' />}
      title='Get text reminders for upcoming deadlines'
      subtitle='We’ll send you texts to remind you of upcoming deadlines.'
      buttonText='Opt in now'
      buttonTextSuccess='Opted in'
      loading={loading}
      success={success}
      onClick={toggleTextReminders}
      highlightedElementId={'text-reminders-button'}
    >
      {!isPhoneNumberValid(phoneNumber) || !isOnboarding ? (
        <PhoneForm
          isOnboarding={isOnboarding}
          buttonText='Opt in now'
          buttonTextSuccess='Opted in'
          buttonTextDone='Done'
          loading={loading}
          success={success}
          onClick={onSuccessButtonClick}
        />
      ) : null}
    </StepTemplate>
  )
}
