import { useContext, useState } from 'react'

const { HighlightingContext } = require('../context/HighlightingContext')

const HighlightingProvider = ({ children }) => {
  const [highlightedElementId, setHighlightedElementId] = useState('')
  const [customElement, setCustomElement] = useState(null)
  const [hideChildrenOnMobile, setHideChildrenOnMobile] = useState(false)

  const highlightElement = (elementId, customElement, hideChildrenOnMobile) => {
    setHideChildrenOnMobile(hideChildrenOnMobile)
    setHighlightedElementId(elementId)
    setCustomElement(customElement)
  }

  const resetHighlighting = () => {
    setHideChildrenOnMobile(false)
    setHighlightedElementId('')
    setCustomElement(null)
  }

  const value = {
    highlightedElementId,
    customElement,
    highlightElement,
    resetHighlighting,
    setCustomElement,
    hideChildrenOnMobile,
  }

  return (
    <HighlightingContext.Provider value={value}>{children}</HighlightingContext.Provider>
  )
}

const useHighlighting = () => {
  const context = useContext(HighlightingContext)

  if (context === undefined) {
    throw new Error('useTodos must be used within a TodoProvider')
  }

  return context
}

export { HighlightingProvider, useHighlighting }
