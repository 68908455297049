import { useDayPicker, useNavigation } from 'react-day-picker'
import { format, getMonth } from 'date-fns'
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg'
import { ReactComponent as RightIcon } from '../../assets/icons/right.svg'
import classNames from 'classnames'

/**
 *
 * @param {import('react-day-picker').CaptionProps} param0
 */
const CustomCaption = ({ displayMonth, id }) => {
  const { goToMonth, nextMonth, previousMonth, currentMonth } = useNavigation()
  const {
    fromDate = new Date(),
    labels: { labelNext, labelPrevious },
    locale,
  } = useDayPicker()

  const fromMonthIndex = getMonth(fromDate)
  const currentMonthIndex = getMonth(currentMonth)

  const monthDifference = Math.abs(currentMonthIndex - fromMonthIndex)

  return (
    <div className='flex justify-center items-center gap-4 text-2xl font-semibold tracking-tight'>
      <button
        className='flex text-primary-700 disabled:text-text-500 enabled:hover:text-primary-300'
        disabled={!previousMonth}
        aria-label={
          monthDifference >= 2
            ? 'Go to current month'
            : labelPrevious(previousMonth, { locale })
        }
        onClick={() => {
          if (monthDifference >= 2) return goToMonth(fromDate)
          goToMonth(previousMonth)
        }}
      >
        {monthDifference >= 2 && <LeftIcon className='p-1 h-6' />}
        <LeftIcon className={classNames('p-1 h-6', monthDifference >= 2 && '-ml-4')} />
      </button>
      <div role='presentation' id={id} aria-live='polite'>
        {format(displayMonth, 'MMMM yyyy')}
      </div>
      <button
        className='flex text-primary-700 disabled:text-text-500 enabled:hover:text-primary-300'
        disabled={!nextMonth}
        aria-label={labelNext(nextMonth, { locale })}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <RightIcon className='p-1 h-6' />
      </button>
    </div>
  )
}

export default CustomCaption
