import { useRef } from 'react'
import { useDayPicker, useDayRender } from 'react-day-picker'
import classNames from 'classnames'
import CustomDayContent from './CustomDayContent'
/**
 *
 * @param {import('react-day-picker').DayProps} param0
 */
const CustomDay = ({ date, displayMonth }) => {
  const ref = useRef()
  const reminderListContainerRef = useRef()
  const dayRender = useDayRender(date, displayMonth, ref)
  const { disabled, outside, hidden } = dayRender.activeModifiers

  if (outside || hidden) {
    return <></>
  }

  const props = dayRender.buttonProps

  return (
    <button
      {...props}
      type='button'
      style={{}}
      className={classNames(
        'w-full h-full flex items-center justify-center',
        disabled && '!cursor-default',
      )}
      ref={ref}
    >
      <CustomDayContent
        ref={reminderListContainerRef}
        date={date}
        displayMonth={displayMonth}
        activeModifiers={dayRender.activeModifiers}
      />
    </button>
  )
}

export default CustomDay
