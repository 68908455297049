import React from 'react'

import { ReactComponent as GoogleLogo } from '../../assets/icons/google.svg'
import { useToasts } from '../../context/ToastsContext'
import useAsyncLoading from '../../hooks/useAsyncLoading'
import useAuth from '../../hooks/useAuth'
import { calendarSync } from '../../utils/api/calendar-sync'

const CalendarSync = () => {
  const { loading, asyncLoading } = useAsyncLoading()
  const addToast = useToasts()
  const { user } = useAuth()

  const userCalendarSynced = user?.preferences?.isCalendarSynced

  const syncCalendar = asyncLoading(async () => {
    try {
      await calendarSync()
    } catch (err) {
      console.error(err)

      addToast({
        description: err?.message || 'An unknown error occurred, please contact us!',
        variant: 'error',
      })
    }
  })

  return (
    <div className='flex flex-col items-center text-center gap-4 mb-12'>
      <GoogleLogo className='w-[72px] h-[72px]' />

      <p className='font-display font-medium text-2xl'>
        Sync your upcoming deadlines and exam dates to your google calendar
      </p>

      <small className='font-display font-normal text-sm text-[#9592A1]'>
        Keep track of projects, essays, and exam dates across all your classes in one
        place and in google calendar
      </small>

      <button
        className='font-medium text-sm text-white bg-primary px-10 py-2.5 rounded-xl enabled:hover:drop-shadow-xl enabled:active:drop-shadow-none disabled:bg-[#DFDFE1]'
        onClick={syncCalendar}
        disabled={loading || userCalendarSynced}
      >
        {userCalendarSynced ? 'Calendar Synced' : 'Sync Now'}
      </button>
    </div>
  )
}

export { CalendarSync }
