import { ReactComponent as OrangeDotsIcon } from '../assets/orange-dots.svg'
import SectionHeader, { SECTION_HEADER_TEXT_COLORS } from '../SectionHeader'
import { LockScreen } from './LockScreen'

/**
 *
 * @param {boolean} [props.isLocked = true]
 */
const Applications = ({ isLocked = true }) => {
  return (
    <section className='relative bg-primary-200/10 px-56 pt-[90px] pb-[70px] font-display not-italic leading-[120%]'>
      <div className='flex flex-col'>
        <SectionHeader
          sectionNumber={4}
          color={SECTION_HEADER_TEXT_COLORS.blue}
          title='Apply to companies hiring interns like you'
        />
        <iframe
          tabIndex={isLocked ? -1 : 0}
          className='mt-10 mx-20 airtable-embed'
          src='https://airtable.com/embed/shrxn42vBfUy0xDKa?backgroundColor=tealLight&layout=card&viewControls=on'
          height='533'
          style={{ background: 'transparent', border: '1px solid #ccc' }}
        />
      </div>
      {isLocked && <LockScreen />}
      <OrangeDotsIcon className='absolute top-[-38px] left-[67px]' />
    </section>
  )
}

export default Applications
