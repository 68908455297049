import { Step } from './Step'
import { useStepperForm } from './StepperForm'

export const Steps = () => {
  const { steps, ...stepper } = useStepperForm()

  return (
    <ol className='self-center flex items-center justify-center w-full mb-4 sm:mb-5 xs:min-w-[328px] max-w-[492px]'>
      {steps.map((_, index) => (
        <Step
          key={index}
          stepIndex={index}
          currentStep={stepper.currentStep}
          maxStep={steps.length}
        />
      ))}
    </ol>
  )
}
