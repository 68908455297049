import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Editor, Node, Transforms } from 'slate'
import useAuth from '../../../hooks/useAuth'
import {
  useCreateTodoMutation,
  useTodos,
  useUpdateTodoMutation,
} from '../../../providers/Todo'
import ContentEditableBox, {
  RtfFormat,
  useContentEditableBoxEditor,
  serializeNodes,
} from '../../inputs/ContentEditableBox'
import { getCustomTimeHeader, to0001 } from '../../../utils/time'
import { WEIGHT_INCREMENT } from '../../../constants'
import { is } from '../../../utils/object'
import { ReactEditor } from 'slate-react'

const initialValue = [RtfFormat.paragraph([RtfFormat.text()])]

export const CalendarTodoForm = ({ onClose, date, todo, locked, setLocked }) => {
  const { userId } = useAuth()
  const { filteredTodos } = useTodos()
  const {
    isLoading: isLoadingPost,
    error: errorPost,
    mutateAsync: post,
    reset: resetPost,
  } = useCreateTodoMutation()
  const {
    isLoading: isLoadingPut,
    error: errorPut,
    mutateAsync: put,
    reset: resetPut,
  } = useUpdateTodoMutation()
  const editor = useContentEditableBoxEditor()

  /* Focus on the text input when it loads */
  useEffect(() => {
    Transforms.select(editor, {
      anchor: Editor.end(editor, []),
      focus: Editor.end(editor, []),
    })
    ReactEditor.focus(editor)
  }, [editor])

  const isLoading = todo?.id ? isLoadingPut : isLoadingPost
  const error = todo?.id ? errorPut : errorPost
  const reset = todo?.id ? resetPut : resetPost

  const [editorValue, setEditorValue] = useState(
    todo
      ? typeof todo.title === 'string'
        ? [RtfFormat.paragraph([RtfFormat.text(todo.title)])]
        : todo.title
      : initialValue,
  )

  const containerRef = useRef()

  const deleteTodo = async () => {
    const updatedTodo = { ...todo, isDeleted: true }
    try {
      const newTodo = await put(updatedTodo)
      reset()
      return newTodo
    } catch (error) {
      console.error(error)
    }
  }

  const editTodo = async () => {
    try {
      setLocked(true)

      const newTitle = editorValue
      if (serializeNodes(newTitle).trim() === '') {
        const deletedTodo = await deleteTodo()
        setLocked(false)
        return onClose(deletedTodo)
      }

      if (is(todo.title).deepEqual(newTitle)) {
        setLocked(false)
        // nothing changed, so close the editing form
        return onClose(todo)
      }

      const updatedTodo = { ...todo, title: newTitle, dueDate: to0001(date) }
      const newTodo = await put(updatedTodo)
      reset()
      onClose(newTodo)
    } catch (error) {
      console.error(error)
    } finally {
      setLocked(false)
    }
  }

  const createTodo = async () => {
    try {
      setLocked(true)
      const dueDate = to0001(date)
      const todoDate = dueDate
      const header = getCustomTimeHeader(dueDate)
      const todoWeightList =
        header === 'today'
          ? filteredTodos.todosToday
          : header === 'tomorrow'
          ? filteredTodos.todosTomorrow
          : filteredTodos.todosLater
      const lastWeight =
        todoWeightList.length > 0 ? todoWeightList[todoWeightList.length - 1].weight : 0
      const weight = lastWeight + WEIGHT_INCREMENT
      const title = editorValue

      const todo = await post({
        title,
        userId,
        todoDate,
        dueDate,
        weight,
      })
      reset()
      onClose(todo)
    } catch (error) {
      console.error(error)
    } finally {
      setLocked(false)
    }
  }

  const onEditorKeyUp = e => {
    if ((e.code !== 'Enter' && e.code !== 'NumpadEnter') || e.shiftKey) return

    if (!todo && !serializeNodes(editorValue).trim()) return onClose()

    todo?.id ? editTodo() : createTodo()
  }
  const onEditorBlur = e => {
    const containerEl = containerRef.current
    if (containerEl.contains(e.target) && containerEl.contains(e.relatedTarget)) return

    if (!todo && !serializeNodes(editorValue).trim()) return onClose()

    todo?.id ? editTodo() : createTodo()
  }

  return (
    <div
      className={classNames('relative rounded bg-white', isLoading && 'pr-6')}
      ref={containerRef}
    >
      <ContentEditableBox
        placeholder='Enter a new todo'
        className='inline-block w-full p-1 text-xs outline-none bg-white rounded resize-none align-text-top'
        onKeyUp={onEditorKeyUp}
        onBlur={onEditorBlur}
        editor={editor}
        value={editorValue}
        setValue={setEditorValue}
        disabled={isLoading || locked}
      />
      {isLoading && (
        <div className='inline-block align-middle animate-spin -mr-5 w-5 h-5 border-2 border-transparent bg-white border-t-current rounded-full' />
      )}
      {error && (
        <p className='text-red-500'>Failed to create the todo. Please try again</p>
      )}
    </div>
  )
}
