import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg'
import edlyft from '../../assets/logos/edlyft.png'
import edlyft2x from '../../assets/logos/edlyft-2x.png'
import edlyft3x from '../../assets/logos/edlyft-3x.png'
import linkedin from '../../assets/logos/linkedin.png'
import linkedin2x from '../../assets/logos/linkedin-2x.png'
import linkedin3x from '../../assets/logos/linkedin-3x.png'
import dropbox from '../../assets/logos/dropbox.png'
import dropbox2x from '../../assets/logos/dropbox-2x.png'
import dropbox3x from '../../assets/logos/dropbox-3x.png'
import tinder from '../../assets/logos/tinder.png'
import tinder2x from '../../assets/logos/tinder-2x.png'
import tinder3x from '../../assets/logos/tinder-3x.png'

const featureList = [
  'Sync project due dates and exams to your Google calendar',
  'Consolidate major deadlines and exams from all your classes in one place',
  'Access an engineering mentor from LinkedIn, Dropbox, or Tinder',
  'Opt-in to notifications of new tech internship applications as they open',
]

const Intro = () => {
  return (
    <>
      <img
        srcSet={`${edlyft2x} 2x, ${edlyft3x} 3x`}
        src={edlyft}
        alt='Edlyft logo'
        className='w-[70px] h-[41px] absolute top-10 left-20'
      />

      <h1 className='text-[44px] font-display font-extrabold mb-9 leading-[55px]'>
        <span className='block text-[#013590]'>Edlyft On Track</span>
        <span className='block text-[#0D58D9]'>
          Stay on top of all things school & career
        </span>
      </h1>

      <ul className='flex flex-col gap-5 mb-16'>
        {featureList.map((copy, i) => (
          <li key={i} className='flex items-center gap-2'>
            <CheckIcon className='w-6 h-6 p-0.5 bg-[#FFD24F] rounded-full text-[#013590]' />
            <span className='font-medium text-black font-display text-sm'>{copy}</span>
          </li>
        ))}
      </ul>

      <ul className='flex flex-wrap gap-x-12 gap-y-6 items-center'>
        <li>
          <img
            srcSet={`${linkedin2x} 2x, ${linkedin3x} 3x`}
            src={linkedin}
            alt='LinkedIn logo'
            className='w-[136px] h-[35px]'
          />
        </li>

        <li>
          <img
            srcSet={`${dropbox2x} 2x, ${dropbox3x} 3x`}
            src={dropbox}
            alt='Dropbox logo'
            className='w-[169px] h-[39px]'
          />
        </li>

        <li>
          <img
            srcSet={`${tinder2x} 2x, ${tinder3x} 3x`}
            src={tinder}
            alt='Tinder logo'
            className='w-[120px] h-[34px]'
          />
        </li>
      </ul>
    </>
  )
}

export { Intro }
