import classNames from 'classnames'
import { useRef, useState, useEffect } from 'react'
import { useTodos, useUpdateTodoMutation } from '../../providers/Todo'
import DueDatePill from './DueDatePill'

import './index.css'

import { ReactComponent as TrashIcon } from '../../assets/icons/trash-empty.svg'
import { UpdateTodoForm } from './UpdateTodoForm'
import { useTodoCallToAction } from './hooks'
import { useResponsiveWindow } from '../../hooks/useResponsiveWindow'
import { longPressExemptProps, useLongPress } from '../../hooks/useLongPress'
import { CloseableWrapper } from './CloseableWrapper'
import { useManageTodoStartDate } from '../../hooks/useManageTodoStartDate'
import { TodoProgressBar } from './TodoProgressBar'
import { MobileDeleteTodoModal } from './MobileDeleteTodoModal'
import { TodoText } from './TodoText'
import { CallToAction } from '../CallToAction'

const Todo = ({
  preview,
  editing,
  setEditing,
  locked,
  setLocked,
  isEmphasized,
  todo,
  active,
}) => {
  const [error, setError] = useState()
  const [localIsComplete, setLocalIsComplete] = useState(todo.isComplete)
  const [isCheckingOff, setIsCheckingOff] = useState(false)
  const [percentage, setPercentage] = useState(todo.progressPercentage)
  const percentageTimeoutRef = useRef()
  const checkOffBox = useRef()

  const { getNewTodoWeight, isFirstColumnTodo } = useTodos()
  const { getTodoStartInDaysOrLess } = useManageTodoStartDate(todo)
  const hook = useTodoCallToAction(todo)
  const { mutateAsync, reset, isLoading: loading } = useUpdateTodoMutation()
  const doesTodoStartInDaysOrLess = getTodoStartInDaysOrLess()
  const isFirstTodoInTodayColumn = isFirstColumnTodo({ todoId: todo.id })

  const createUpdateFunction = updateObj => todo => async () => {
    // a value inside the update object was different
    const updatedTodo = { ...todo, ...updateObj }
    try {
      await mutateAsync(updatedTodo)
      reset()
      setError()
    } catch (error) {
      console.error(error)
      setError({ message: 'Failed to complete the todo. Please try again', error })
    }
  }

  const editTodoTitle = event => {
    if (locked || editing) return

    event.preventDefault()
    event.stopPropagation()

    return setEditing(true)
  }

  const checkOffTodo = async (event, isComplete) => {
    if (event && event.target !== checkOffBox.current) return
    debugger
    setIsCheckingOff(false)

    const weight = getNewTodoWeight(todo)

    // create the update todo function
    const commitUpdate = createUpdateFunction({
      isComplete: isComplete ?? localIsComplete,
      weight,
    })
    const commitUpdateWithTodo = commitUpdate(todo)

    await commitUpdateWithTodo()
  }

  const startTodoCheckOff = async event => {
    event && event.preventDefault()
    setEditing(false)

    const isComplete = event.target.checked || false
    // set the local state to what it was checked to
    setLocalIsComplete(isComplete)
    isComplete && setIsCheckingOff(true)

    if (!isComplete) {
      checkOffTodo(null, isComplete)
    }
  }

  const deleteTodo = async () => {
    const updatedTodo = { ...todo, isDeleted: true }
    try {
      await mutateAsync(updatedTodo)
      reset()
      setError()
    } catch (error) {
      console.error(error)
      setError({ message: 'Failed to delete. Please try again', error })
    }
  }

  const updatePercentage = async progressPercentage => {
    setPercentage(progressPercentage)

    clearTimeout(percentageTimeoutRef.current)

    percentageTimeoutRef.current = setTimeout(async () => {
      if (progressPercentage === 100) {
        const isComplete = true
        setLocalIsComplete(isComplete)

        const weight = getNewTodoWeight(todo)

        const commitUpdate = createUpdateFunction({
          isComplete,
          weight,
          progressPercentage,
        })
        const commitUpdateWithTodo = commitUpdate(todo)

        await commitUpdateWithTodo()
      } else {
        const commitUpdate = createUpdateFunction({ progressPercentage })
        const commitUpdateWithTodo = commitUpdate(todo)

        await commitUpdateWithTodo()
      }
    }, 1000)
  }

  const updateDueDate = async dueDate => {
    const commitUpdate = createUpdateFunction({ dueDate })
    const commitUpdateWithTodo = commitUpdate(todo)

    await commitUpdateWithTodo()
  }

  const [animateDelete, setAnimateDelete] = useState(false)
  const [todoRenderable, setTodoRenderable] = useState(true)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const { isMobile } = useResponsiveWindow()
  const mobileClickProps = useLongPress(
    () => setIsDeleteVisible(!isDeleteVisible),
    () => {},
  )
  useEffect(() => {
    if (isMobile) return
    setDeleteModalOpen(false)
    setIsDeleteVisible(false)
  }, [isMobile])

  const todoProps = !editing && !preview && isMobile ? mobileClickProps : {}

  const todoClass = classNames(
    'todo group py-3 px-5 rounded-xl',
    'hover:bg-white focus-within:bg-white',
    'relative hover:z-30 focus-within:z-30',
    'hover:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)] focus-within:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)]',
    'max-xs:transition-colors max-xs:active:bg-neutral-200 max-xs:active:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)]',
    preview && 'drop-shadow-[-2px_3px_4px_0_rgba(66,144,243,0.08)] bg-white',
    active && 'bg-white',
    (editing || active) && 'drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)]',
    editing && '!z-50',
    hook.isOpen && 'drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)] bg-white',
  )
  const mobileOverrideLongClickProps = longPressExemptProps()

  if (!todoRenderable) return null

  return (
    <>
      <CallToAction {...hook} placement='bottom'>
        <CloseableWrapper
          closed={isMobile && animateDelete}
          onFinish={() => {
            setAnimateDelete(false)
            setTodoRenderable(false)
            deleteTodo()
          }}
        >
          <div
            onClick={editTodoTitle}
            onPointerUp={editTodoTitle}
            className={todoClass}
            onAnimationEnd={checkOffTodo}
            {...todoProps}
          >
            <div className='flex gap-2 items-center'>
              {editing ? (
                <UpdateTodoForm
                  todo={todo}
                  setEditing={setEditing}
                  preview={preview}
                  editing
                  editedWithStartDate={hook.editedWithStartDate}
                >
                  {doesTodoStartInDaysOrLess ? (
                    <TodoProgressBar
                      percentage={percentage}
                      setPercentage={updatePercentage}
                      showTooltip={isFirstTodoInTodayColumn}
                    />
                  ) : null}
                </UpdateTodoForm>
              ) : (
                <div className='flex flex-col overflow-hidden break-all todo-box mr-auto'>
                  <div
                    className={classNames(
                      isEmphasized ? 'text-base font-medium' : 'text-sm font-normal',
                      'flex items-start ml-6',
                    )}
                  >
                    <input
                      {...mobileOverrideLongClickProps}
                      onChange={startTodoCheckOff}
                      disabled={editing || loading}
                      checked={localIsComplete}
                      type='checkbox'
                      className={classNames(
                        isEmphasized && 'todo-input',
                        '-ml-6 mr-1 w-[18px] h-[18px] align-middle',
                      )}
                    />
                    <TodoText
                      ref={checkOffBox}
                      todo={todo}
                      {...mobileOverrideLongClickProps}
                      className={classNames(
                        todo.isComplete && '!line-through !text-[#C1BCCC]',
                        'align-middle break-words',
                        isCheckingOff && 'todo-text !text-[#C1BCCC]',
                      )}
                    />
                  </div>
                  {error && <p className='text-red-500'>{error.message}</p>}
                </div>
              )}

              {!preview && !editing && (
                <button
                  onClick={deleteTodo}
                  disabled={loading}
                  className='self-baseline hidden xs:group-hover:enabled:block xs:group-focus-within:enabled:block xs:group-focus-visible:enabled:block
                  todo-button pb-0.5 px-1.5 rounded-md text-[#6B667B]
                  enabled:hover:bg-neutral-300
                  enabled:hover:text-[#625a7a]'
                >
                  <TrashIcon
                    className='inline-block align-middle h-3.5'
                    title='delete icon'
                  />
                </button>
              )}
              {!preview && !editing && isDeleteVisible && (
                <button
                  {...mobileOverrideLongClickProps}
                  onClick={() => setDeleteModalOpen(true)}
                  className='self-baseline pb-0.5 px-1.5 rounded-md text-red-500
                  enabled:active:bg-neutral-300'
                >
                  <TrashIcon
                    className='inline-block align-middle h-3.5'
                    title='delete icon'
                  />
                </button>
              )}
            </div>
            <div className='flex items-center justify-between'>
              {!editing && doesTodoStartInDaysOrLess ? (
                <TodoProgressBar
                  percentage={percentage}
                  setPercentage={updatePercentage}
                  showTooltip={isFirstTodoInTodayColumn}
                />
              ) : null}
              <DueDatePill
                preview={preview}
                editing={editing}
                setLocked={setLocked}
                todo={todo}
                updateDueDate={updateDueDate}
              />
            </div>
          </div>
        </CloseableWrapper>
      </CallToAction>
      {isMobile && (
        <MobileDeleteTodoModal
          todo={todo}
          isDeleteModalOpen={isDeleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          setIsDeleteVisible={setIsDeleteVisible}
          setAnimateDelete={setAnimateDelete}
        />
      )}
    </>
  )
}

export default Todo
