import { Modal } from '../../components/common/Modal'
import { SyncPromptAfterTodo } from '../../components/CalendarSync'

const GoogleCalendarSyncModal = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <SyncPromptAfterTodo onClose={onClose} />
    </Modal>
  )
}

export { GoogleCalendarSyncModal }
