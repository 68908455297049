import React from 'react'

import { ReactComponent as Flag } from '../../assets/images/flag.svg'

const SuccessScreen = ({ onClose }) => {
  return (
    <div className='flex flex-col items-center text-center'>
      <Flag className='w-[72px] h-[72px] mb-4' />

      <p className='font-display font-medium text-2xl mb-4'>Thanks for uploading!</p>

      <small className='font-display font-normal text-sm text-[#9592A1] mb-24'>
        Check back soon, we'll notify you once we've imported all of the important dates.
      </small>

      <button
        className='px-10 py-2.5 bg-primary text-white rounded-xl text-sm font-medium enabled:hover:drop-shadow-xl enabled:active:drop-shadow-none'
        onClick={onClose}
      >
        OK
      </button>
    </div>
  )
}

export { SuccessScreen }
