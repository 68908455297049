import { StartDateSelect } from '../StartDateSelect'
import { useSelectProps } from '../../common/Select'
import ContentEditableBox from '../../inputs/ContentEditableBox'
import { DatePicker } from '../../inputs/DatePicker'

import useTodoForm from '../../../hooks/useTodoForm'
import { useUpdateFunctions } from './hooks/useUpdateFunctions'
import { useUpdateTextEditor } from './hooks/useUpdateTextEditor'
import { useManageTodoStartDate } from '../../../hooks/useManageTodoStartDate'

export const UpdateTodoForm = ({
  todo,
  setEditing,
  preview,
  editing,
  editedWithStartDate,
  children,
}) => {
  const { dueDate, setDueDate, containerRef, onSubmit, onBlur, disableBlurEvents } =
    useTodoForm({
      todo,
    })

  const { value, setValue, editor } = useUpdateTextEditor({ todo, editing })

  const { getStartingIndex, options } = useManageTodoStartDate(todo)
  const selectProps = useSelectProps(getStartingIndex(), options)

  const { loading, error, editTodo } = useUpdateFunctions({
    todo,
    value,
    setEditing,
    selectProps,
    editedWithStartDate,
  })

  const handleSubmit = onSubmit(editTodo)
  const handleBlur = onBlur(editTodo)

  const onTitleKeyUp = async event => {
    if (event.code === 'Enter' && !event.shiftKey) {
      await handleSubmit(event)
    }
  }

  return (
    <form
      className='w-full group/updateTodo'
      ref={containerRef}
      onSubmit={handleSubmit}
      onBlur={handleBlur}
      disabled={loading || preview}
    >
      <div className='inline-block w-full overflow-hidden break-all'>
        <div className='pl-6 w-full'>
          <input
            readOnly
            disabled
            checked={todo.isComplete}
            type='checkbox'
            className='-ml-6 mr-1 w-[18px] h-[18px] align-middle stroke-[#C1BCCC]'
          />
          <ContentEditableBox
            name='title'
            required
            aria-required
            className='inline-block w-full outline-none bg-transparent resize-none align-text-top'
            placeholder='Enter to-do here'
            autoFocus
            editor={editor}
            onKeyUp={onTitleKeyUp}
            value={value}
            setValue={setValue}
          />
        </div>
      </div>
      <div className='flex flex-col items-start gap-5'>
        <div className='flex flex-col items-start gap-3.5'>
          <div className='flex items-center gap-1 font-display ml-0'>
            <p className='text-text-700 text-xs font-normal'>Due date:</p>
            <DatePicker
              className='w-fit ml-auto block mt-2 text-[#6B667B]'
              selected={dueDate}
              setSelected={setDueDate}
              optional
              disableBlurEvents={disableBlurEvents}
            />
          </div>
          <StartDateSelect dueDate={dueDate} {...selectProps} />
          {error && <p className='text-red-500'>{error.message}</p>}
        </div>
        {children}
      </div>
    </form>
  )
}
