import * as Dialog from '@radix-ui/react-dialog'
import classNames from 'classnames'
import { forwardRef, useRef } from 'react'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { toDueDate } from '../../../utils/date'
import { longPressExemptProps } from '../../../hooks/useLongPress'
import Calendar from './Calendar'
import { createPortal } from 'react-dom'

export const MobileDatePicker = forwardRef(
  (
    {
      open,
      setOpen,
      selected,
      setSelected,
      optional,
      disabled,
      fromDate,
      renderButtonContent,
      buttonClassName,
      ...popoverProps
    },
    ref,
  ) => {
    const triggerRef = useRef()

    fromDate ??= new Date()

    renderButtonContent ??= () => {
      const formattedDate = selected ? toDueDate(selected) : null
      return !formattedDate ? (
        <span className='whitespace-nowrap'>Select due date</span>
      ) : (
        <>
          <span className='font-medium text-primary-700'>{formattedDate}</span>
          {optional && (
            <div
              tabIndex={0}
              role='button'
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setSelected()
                setOpen(false)
              }}
              onPointerUp={e => {
                e.preventDefault()
                e.stopPropagation()
                setSelected()
                setOpen(false)
              }}
              className='rounded-full'
            >
              <CloseIcon className='h-3 text-primary-700' />
            </div>
          )}
        </>
      )
    }
    const selectedDateContent = renderButtonContent()

    return (
      <Dialog.Root
        modal={false}
        open={open}
        defaultOpen={open}
        onOpenChange={open => {
          console.log(ref)
          triggerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
          setOpen(open)
        }}
      >
        <div {...popoverProps}>
          <Dialog.Trigger ref={triggerRef} asChild>
            <button
              {...longPressExemptProps()}
              disabled={disabled}
              ref={node => {
                if (!ref || typeof ref === 'function') return
                ref.current.reference = node
              }}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
              }}
              onMouseDown={event => {
                event.preventDefault()
                event.stopPropagation()
              }}
              onPointerDown={event => {
                event.preventDefault()
                event.stopPropagation()
                setOpen(prev => !prev)
              }}
              className={
                buttonClassName ||
                classNames(
                  'relative inline-flex gap-2 items-center',
                  'text-xs rounded-full py-1.5 px-2 border border-transparent outline-none',
                  'disabled:bg-[#DFDFE1] disabled:text-white',
                  !selected && 'bg-text-200 hover:border-text-700 focus:border-text-700',
                  (open || selected) &&
                    'bg-gradient-to-r from-primary-600/5 to-primary-600/5 !bg-white !border-primary-dark',
                )
              }
            >
              {selectedDateContent}
            </button>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Content
              {...longPressExemptProps()}
              ref={node => {
                if (!ref || typeof ref === 'function') return
                ref.current.floating = node
              }}
            >
              <div className='z-50 p-4 animate-slide-up bg-white fixed bottom-0 left-0 right-0 max-h-[70%] overflow-scroll rounded-t-2xl'>
                <Dialog.Title className='text-center mb-4'>Due date</Dialog.Title>
                <Calendar
                  selected={selected}
                  setSelected={selectedDate => {
                    setSelected(selectedDate)
                    setOpen(false)
                  }}
                />
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </div>
      </Dialog.Root>

      // <div {...popoverProps}>
      //   <button
      //     {...longPressExemptProps()}
      //     disabled={disabled}
      //     ref={node => {
      //       if (!ref || typeof ref === 'function') return
      //       ref.current.reference = node
      //       triggerRef.current = node
      //     }}
      //     onClick={event => {
      //       console.log(event)
      //       // event.preventDefault()
      //       // event.stopPropagation()
      //       // console.log(ref)
      //       setOpen(prev => !prev)
      //       // triggerRef.current?.focus()
      //     }}
      //     onMouseDown={event => {
      //       event.preventDefault()
      //       event.stopPropagation()
      //     }}
      //     onPointerDown={event => {
      //       event.preventDefault()
      //       event.stopPropagation()
      //     }}
      //     className={
      //       buttonClassName ||
      //       classNames(
      //         'relative inline-flex gap-2 items-center',
      //         'text-xs rounded-full py-1.5 px-2 border border-transparent outline-none',
      //         'disabled:bg-[#DFDFE1] disabled:text-white',
      //         !selected && 'bg-text-200 hover:border-text-700 focus:border-text-700',
      //         (open || selected) &&
      //           'bg-gradient-to-r from-primary-600/5 to-primary-600/5 !bg-white !border-primary-dark',
      //       )
      //     }
      //   >
      //     {selectedDateContent}
      //   </button>
      //   {open &&
      //     createPortal(
      //       <div
      //         {...longPressExemptProps()}
      //         ref={node => {
      //           if (!ref || typeof ref === 'function') return
      //           ref.current.floating = node
      //         }}
      //       >
      //         <div className='z-50 p-4 animate-slide-up bg-white fixed bottom-0 left-0 right-0 max-h-[70%] overflow-scroll rounded-t-2xl'>
      //           {/* <Dialog.Title className='text-center mb-4'>Due date</Dialog.Title> */}
      //           Due Date
      //           <Calendar
      //             selected={selected}
      //             setSelected={selectedDate => {
      //               setSelected(selectedDate)
      //               setOpen(false)
      //             }}
      //           />
      //         </div>
      //       </div>,
      //       document.body,
      //     )}
      // </div>
    )
  },
)
