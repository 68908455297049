import { RefObject } from 'react'

import { useEventListener } from 'usehooks-ts'

type Handler = (event: MouseEvent) => void

function useOnClickOutside<R, T extends HTMLElement = HTMLElement>(
  ref: RefObject<R>,
  getElement: (ref: RefObject<R>) => T,
  handler: Handler,
  mouseEvent: 'mousedown' | 'mouseup' | 'pointerdown' | 'pointerup' = 'mousedown',
): void {
  useEventListener(mouseEvent, event => {
    console.log(event)

    const el = getElement(ref)

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target as Node)) {
      return
    }

    handler(event)
  })
}

export default useOnClickOutside
