import { ChevronDownIcon } from '@heroicons/react/20/solid'

import useAuth from '../../../hooks/useAuth'
import { Menu, MenuItem } from '../../DropdownMenu'
import { ReactComponent as MessageIcon } from '../../../assets/icons/message.svg'
import { ReactComponent as PlaylistIcon } from '../../../assets/icons/playlist.svg'
import { ReactComponent as SignOutIcon } from '../../../assets/icons/sign-out.svg'
import Toggle from '../../Toggle'

export const DesktopUserMenu = ({
  isOpen,
  setIsOpen,
  toggleCompletedTask,
  toggleSmsNotifications,
  smsEnabled,
  completedTasksEnabled,
  smsToggleText,
  completedTaskToggleText,
  signOut,
  loading,
}) => {
  const { user } = useAuth()

  return (
    <Menu
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className='text-xs inline-flex items-center rounded-md px-4 py-2
  bg-primary-600 bg-opacity-5 hover:bg-opacity-20
  focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-opacity-75'
      classNames={{
        menu: 'p-4 whitespace-nowrap space-y-3 bg-white text-xs text-text origin-top-right rounded-md shadow-[0px_4px_36px_3px_rgba(44,37,67,0.16)] focus:outline-none z-50',
      }}
      contentEl={
        <>
          {user.email}
          <ChevronDownIcon
            className='inline-block ml-2 -mr-1 h-5 w-5'
            aria-hidden='true'
          />
        </>
      }
    >
      {user.registrationRecordId && (
        <MenuItem
          disabled={loading}
          label='Receive text notifications'
          className='w-full flex items-center gap-2.5'
          onClick={async () => {
            if (loading) return
            await toggleSmsNotifications()
          }}
        >
          <MessageIcon
            className='h-3.5 text-neutral-500'
            alt='receive sms notifications icon'
          />
          <span className='grow text-left'>Receive text notifications</span>
          <span
            className='self-end'
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <Toggle
              disabled={loading}
              checked={smsEnabled}
              onChange={toggleSmsNotifications}
              title={smsToggleText}
              srText={smsToggleText}
            />
          </span>
        </MenuItem>
      )}
      <MenuItem
        disabled={loading}
        label='Show completed todos'
        className='w-full flex items-center justify-start gap-2.5'
        onClick={async () => {
          if (loading) return
          await toggleCompletedTask()
        }}
      >
        <PlaylistIcon className='h-3.5 text-neutral-500' alt='completed tasks icon' />
        <span className='grow text-left'>Show completed todos</span>
        <span
          className='self-end'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Toggle
            disabled={loading}
            checked={completedTasksEnabled}
            onChange={toggleCompletedTask}
            title={completedTaskToggleText}
            srText={completedTaskToggleText}
          />
        </span>
      </MenuItem>
      <div aria-hidden aria-disabled className='border-t border-neutral-300 my-3' />
      <MenuItem
        label='sign out'
        className='disabled:cursor-not-allowed flex items-center gap-2.5'
        onClick={signOut}
      >
        <SignOutIcon className='h-3.5 text-neutral-500' alt='sign out icon' />
        Sign out
      </MenuItem>
    </Menu>
  )
}
