import React, { useState } from 'react'

import { SuccessScreen } from './SuccessScreen'
import { UploadScreen } from './UploadScreen'

import { Modal } from '../common/Modal'
import { SyncPromptAfterTodo } from '../CalendarSync'
import useAuth from '../../hooks/useAuth'

const FileUploader = ({ show, close }) => {
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false)
  const [showSyncPrompt, setShowSyncPrompt] = useState(false)
  const { user } = useAuth()

  const closeFileUploader = () => {
    close()
    setFileUploadSuccess(false)
    setShowSyncPrompt(false)
  }

  const onFileUploadSuccess = isFirstUpload => {
    if (isFirstUpload) {
      setShowSyncPrompt(!user?.preferences?.isCalendarSynced)
    }
    setFileUploadSuccess(true)
  }

  let modalContent = null

  if (fileUploadSuccess) {
    if (showSyncPrompt) {
      modalContent = <SyncPromptAfterTodo onClose={closeFileUploader} />
    } else {
      modalContent = <SuccessScreen onClose={closeFileUploader} />
    }
  } else {
    modalContent = <UploadScreen onSuccess={onFileUploadSuccess} />
  }

  return (
    <Modal
      isOpen={show}
      onClose={closeFileUploader}
      // TODO: please update title for future terms
      titleText={!fileUploadSuccess && 'Import Spring 2023 Course Due Dates'}
    >
      {modalContent}
    </Modal>
  )
}

export { FileUploader }
