import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar'

const Layout = () => {
  return (
    <div
      className='flex flex-col h-full relative'
      style={{
        minHeight: 'inherit',
        height: 'inherit',
      }}
    >
      <Navbar />
      <div className='grow bg-white-bg'>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
