/**
 * This custom floating ui hook is used to keep the tooltip
 * open after the modal button was clicked and the syncing was handle
 * correctly.
 *
 * This ensures after 5 seconds the tooltip will close
 *
 * @param {import('@floating-ui/react').FloatingContext} context
 * @returns {import('@floating-ui/react').ElementProps}
 */
export function useClickDelayedClosing(
  context,
  { referenceProps, timeoutId, setTimeoutId, enabled = true, time = 5000 } = {},
) {
  const { onClick, ...props } = referenceProps

  return {
    reference: {
      onClick: async e => {
        // If the feature isn't enabled, then only run the click handler
        if (!enabled && onClick) return await onClick(e)

        context.onOpenChange(true)
        // set temporarily in order to keep the tooltip open before submitting the change
        setTimeoutId(true)

        if (onClick) await onClick(e)
        if (timeoutId) clearTimeout(timeoutId)

        if (e.defaultPrevented) {
          // don't run if the default behavior was turned off (aka, the event was handled)
          setTimeoutId()
          return context.onOpenChange(false)
        }

        const newTimeoutId = setTimeout(() => {
          setTimeoutId()
          context.onOpenChange(false)
        }, time)
        setTimeoutId(newTimeoutId)
      },
      ...props,
    },
  }
}
