import { useToasts } from '../../context/ToastsContext'
import useAsyncLoading from '../../hooks/useAsyncLoading'
import { calendarSync } from '../../utils/api/calendar-sync'
import { ReactComponent as GoogleLogo } from '../../assets/icons/google.svg'

const SyncPromptAfterTodo = ({ onClose }) => {
  const { loading, asyncLoading } = useAsyncLoading()
  const addToast = useToasts()

  const syncCalendar = asyncLoading(async () => {
    try {
      await calendarSync()
    } catch (err) {
      console.error(err)

      addToast({
        description: err?.message || 'An unknown error occurred, please contact us!',
        variant: 'error',
      })
    }
  })

  return (
    <div className='flex flex-col items-center text-center gap-4 mb-12'>
      <GoogleLogo className='w-[72px] h-[72px]' />

      <p className='font-display font-medium text-2xl'>
        Congrats on your first reminder! Want to sync your reminders to your google
        calendar?
      </p>

      <small className='font-display font-normal text-sm text-[#9592A1]'>
        Automatically sync your class project due dates, exams and more to your calendar.
      </small>

      <button
        className='font-medium text-sm text-white bg-primary px-10 py-2.5 rounded-xl enabled:hover:drop-shadow-xl enabled:active:drop-shadow-none disabled:bg-[#DFDFE1]'
        onClick={() => {
          syncCalendar()
          onClose()
        }}
        disabled={loading}
      >
        Sync now
      </button>
    </div>
  )
}

export { SyncPromptAfterTodo }
