/**
 * Do not use this as a public object.
 *
 * You should define the valid operation types
 * by creating a subclass of this.
 *
 * @private
 */
export class __MetricOperation {
  constructor({ metricName, name, data }) {
    this.metricName = metricName
    this.name = name
    this.data = data
  }
}

export class AddSignInLogMetricOperation extends __MetricOperation {
  constructor() {
    super({
      metricName: 'Sign in log',
      name: 'string::join',
      data: { value: new Date().toString(), separator: '\n' },
    })
  }
}

export class AddEditTodoCountMetricOperation extends __MetricOperation {
  constructor(data) {
    super({
      metricName: 'Add/edit todo count',
      name: 'add',
      data,
    })
  }
}

export class AddCompleteTodoCountMetricOperation extends __MetricOperation {
  constructor(data) {
    super({
      metricName: 'Complete todo count',
      name: 'add',
      data,
    })
  }
}

export class AddEditReminderCount extends __MetricOperation {
  constructor(data) {
    super({
      metricName: 'Add/edit reminder count',
      name: 'add',
      data,
    })
  }
}
