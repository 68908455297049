import { useState } from 'react'
import useAuth from '../../../../hooks/useAuth'
import ExpandableItem from '../../../ExpandableItem'
import Line from '../../../Line'
import ProgressTracker, { PROGRESS_TRACKER_TEXT_COLORS } from '../../../ProgressTracker'
import SectionHeader, { SECTION_HEADER_TEXT_COLORS } from '../SectionHeader'
import ProblemAssessment from './ProblemAssessment'
import SupportWarning from './SupportWarning'

import { ReactComponent as BottomLeftBlueIcon } from './assets/bottom-left-blue.svg'
import { ReactComponent as BottomLeftGreenIcon } from './assets/bottom-left-green.svg'
import { ReactComponent as BottomLeftLineIcon } from './assets/bottom-left-line.svg'
import { useDefaultInterviewPrepProblemListQuery } from '../hooks/useDefaultInterviewPrepProblemListQuery'

const progressTrackerChoices = {
  name: 'knowledge',
  items: [
    { id: 'later', label: 'I’ll do these later', value: false },
    { id: 'known', label: 'I know how to do these', value: true },
  ],
}

const PracticeSkills = () => {
  const {
    userId,
    user: { careersStepTwoComplete },
    trackCareerProgress,
  } = useAuth()
  const { data: defaultProblems } = useDefaultInterviewPrepProblemListQuery()

  const [expandedItem, setExpandedItem] = useState()
  const [progress, setProgress] = useState(careersStepTwoComplete)
  const [isSuccess, setIsSuccess] = useState(null)

  const trackProgress = async progressResult => {
    if (progress !== progressResult) {
      setProgress(progressResult)

      try {
        await trackCareerProgress({ userId, section: 2, value: progressResult })
        setIsSuccess(true)
      } catch (error) {
        console.error(error)
        setIsSuccess(false)
      }
    }
  }

  const toggleExpand = itemId => {
    itemId === expandedItem ? setExpandedItem(null) : setExpandedItem(itemId)
  }

  return (
    <section
      id='section-two'
      className='relative bg-transparent px-56 pt-[90px] pb-[70px] font-display not-italic leading-[120%]'
    >
      <BottomLeftBlueIcon className='absolute left-0 bottom-0' />
      <BottomLeftGreenIcon className='absolute left-0 bottom-0' />
      <BottomLeftLineIcon className='absolute left-0 bottom-0' />
      <SectionHeader
        sectionNumber={2}
        color={SECTION_HEADER_TEXT_COLORS.blue}
        title='Practice your skills'
        subtitle={
          <span>
            Increase your chances of matching with an engineering mentor by practicing
            these Leetcode questions.
            <br />
            Students who consistently practice are the best intern candidates.
          </span>
        }
        disclaimer={
          <span>
            We won't be able to see your Leetcode here, so feel free to practice without
            worry of being observed.
            <br />
            We want to see that you've practiced, attempted and reflected.
          </span>
        }
      />
      <div className='mt-2.5 isolate'>
        <SupportWarning />

        <div className='bg-primary-300/[.08] w-[840px] mt-[30px] mb-5'>
          {defaultProblems.map((problem, index) => (
            <div key={problem.id}>
              <ExpandableItem
                itemNumber={index + 1}
                title={problem.name}
                problemLink={problem.link}
                expand={toggleExpand}
                isExpanded={expandedItem === index + 1}
              >
                <ProblemAssessment problem={problem} />
              </ExpandableItem>
              <Line />
            </div>
          ))}
        </div>

        <div className='flex flex-col items-start gap-2.5'>
          <ProgressTracker
            color={PROGRESS_TRACKER_TEXT_COLORS.black}
            choices={progressTrackerChoices}
            progress={careersStepTwoComplete}
            trackProgress={trackProgress}
          />
          <p className='text-sm text-text-green font-medium'>
            {isSuccess && progress === true
              ? 'Great work! You’re on your way to an internship.'
              : ''}
          </p>
        </div>
      </div>
    </section>
  )
}

export default PracticeSkills
