import todayHeader from '../assets/today.svg'
import tomorrowHeader from '../assets/tomorrow.svg'
import laterHeader from '../assets/later.svg'

export const TodayHeader = () => {
  return (
    <div
      className='bg-primary-dark/40 text-primary-700 text-2xl bg-no-repeat rounded-[inherit] py-2.5 px-5'
      style={{
        backgroundImage: `url(${todayHeader})`,
        backgroundSize: 'auto 100%',
      }}
    >
      Today
    </div>
  )
}

export const TomorrowHeader = () => {
  return (
    <div
      className='bg-amber-300/40 text-primary-700 text-2xl bg-no-repeat bg-right rounded-[inherit] py-2.5 px-5'
      style={{
        backgroundImage: `url(${tomorrowHeader})`,
        backgroundSize: 'auto 100%',
      }}
    >
      Tomorrow
    </div>
  )
}

export const LaterHeader = () => {
  return (
    <div
      className='bg-orange-400/40 text-primary-700 text-2xl bg-no-repeat bg-right rounded-[inherit] py-2.5 px-5'
      style={{
        backgroundImage: `url(${laterHeader})`,
        backgroundSize: 'auto 100%',
      }}
    >
      Later
    </div>
  )
}
