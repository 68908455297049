import { useId } from 'react'
import { useFormContext } from 'react-hook-form'
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg'
import { ReactComponent as RightIcon } from '../../assets/icons/right.svg'
import { useStepperForm } from '../../components/StepperForm/StepperForm'
import { KEY_CAREER_TAB, KEY_TODOS_TAB } from '../../constants'
import { Header } from './Header'
import { Steps } from '../../components/StepperForm'

const Label = ({ name, value, label }) => {
  const { register } = useFormContext()
  const id = useId()

  return (
    <div className='max-xs:w-full'>
      <input
        id={id}
        hidden
        type='checkbox'
        className='peer'
        value={value}
        {...register(name)}
      />
      <label
        className='inline-block border border-[#C0BEC7] rounded-full p-3 hover:bg-text-300 hover:bg-opacity-30
        peer-checked:!border-primary-600 peer-checked:!bg-primary-300 peer-checked:!bg-opacity-10'
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

const usageValues = [
  {
    value: KEY_CAREER_TAB,
    label: (
      <>
        <span className='max-xs:hidden'>
          Apply for an Engineering Mentor (Edlyft Pre-Internship Program)
        </span>
        <span className='xs:hidden'>Apply for the Edlyft Pre-Internship Program</span>
      </>
    ),
  },
  {
    value: KEY_CAREER_TAB,
    label: 'Internship prep',
  },
  {
    value: KEY_TODOS_TAB,
    label: 'Keep track of major due dates',
  },
  {
    value: KEY_TODOS_TAB,
    label: 'Organize daily todos',
  },
]

export const PlannedUsageContent = () => {
  const { register } = useFormContext()
  const stepper = useStepperForm()

  return (
    <>
      <div className='xs:hidden flex justify-between gap-2.5 text-base'>
        <button
          type='button'
          onClick={stepper.goToPrevStep}
          className='flex items-center gap-2.5 -ml-3 p-3 text-center text-text-600 hover:text-text-700 font-light leading-[120%] rounded-md'
        >
          <LeftIcon className='h-2.5' />
          Back
        </button>
        <div className='grow' />
        <button
          type='button'
          onClick={stepper.goToNextStep}
          className='flex items-center gap-2.5 -mr-3 p-3 text-center text-text-600 hover:text-text-700 font-light leading-[120%] rounded-md'
        >
          Skip
          <RightIcon className='h-2.5' />
        </button>
      </div>
      <div className='max-xs:hidden'>
        <Header />
      </div>
      <div className='max-xs:mx-8'>
        <Steps />
      </div>
      <div className='grid grid-rows-1 grid-cols-2 gap-6'>
        <div className='col-span-full'>
          <h2 className='font-semibold text-2xl mb-1'>
            What are you planning to use Edlyft for?{' '}
            <span className='text-[#C0BEC7]'>(optional)</span>
          </h2>
          <p className='text-[#333333]'>
            This will help us personalize your experience with Edlyft on Track
          </p>
          <p className='text-text-70 mb-2.5'>Select all that apply</p>
          <div className='flex flex-wrap gap-4 xs:gap-2.5 break-words'>
            {usageValues.map(({ value, label }, index) => (
              <Label key={index} name='usage' value={value} label={label} />
            ))}
          </div>
        </div>
        <div className='col-span-full'>
          <label className='flex gap-2.5'>
            <input
              className='flex-shrink-0 h-6 w-6 xs:w-5 xs:h-5'
              type='checkbox'
              defaultChecked
              {...register('preferences.sendCareerOps')}
            />
            <span className='text-sm'>
              Check if you’d like us to automatically notify you of new vetted tech
              internship opportunities.
            </span>
          </label>
        </div>
        {/* desktop buttons */}
        <div className='hidden xs:flex col-span-full gap-2.5'>
          <button
            type='button'
            onClick={stepper.goToPrevStep}
            className='self-start flex items-center gap-2.5 p-3 text-center w-10 md:w-20 lg:w-40 text-primary-600 focus-visible:bg-primary-200 font-medium leading-[120%] rounded-md'
          >
            <LeftIcon className='w-2.5' />
            Previous
          </button>
          <div className='grow' />
          <button
            type='button'
            onClick={stepper.goToNextStep}
            className='p-3 text-center w-10 md:w-20 lg:w-40 text-text-500 hover:text-text-700 font-medium leading-[120%] rounded-md'
          >
            Skip
          </button>
          <button
            type='submit'
            className='p-3 text-center w-10 md:w-20 lg:w-40 bg-primary-300 disabled:text-black disabled:bg-neutral-400 focus-visible:bg-primary-200 hover:bg-primary-200 font-medium leading-[120%] text-primary-700 rounded-md'
          >
            Continue
          </button>
        </div>
      </div>
      {/* mobile sticky button */}
      <div className='hidden max-xs:block mt-auto sticky bottom-0 left-0 pt-4 pb-12 bg-white-bg w-full'>
        <button
          type='submit'
          className='max-xs:w-full p-3 text-center bg-primary-300 disabled:text-black disabled:bg-neutral-400 focus-visible:bg-primary-200 hover:bg-primary-200 font-medium leading-[120%] text-primary-700 rounded-md'
        >
          Continue
        </button>
      </div>
    </>
  )
}
