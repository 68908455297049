import classNames from 'classnames'
import { format } from 'date-fns'
import { forwardRef } from 'react'
/**
 *
 * @param {import('react-day-picker').DayContentProps} param0
 */
const CustomDayContent = ({ activeModifiers, date }, ref) => {
  const { selected, disabled } = activeModifiers

  return (
    <span
      ref={ref}
      className={classNames(
        'inline-flex items-center justify-center leading-4 rounded-full',
        disabled && 'text-text-700',
        !disabled &&
          !selected &&
          'p-2 hactive:font-medium hactive:border hactive:border-primary-600 hactive:after:block hactive:after:h-0 hactive:after:w-full hactive:after:pb-[100%] hactive:bg-primary-600/5',
        selected &&
          '!text-white font-medium p-2 after:block after:h-0 after:w-full after:pb-[100%] bg-primary-dark',
      )}
    >
      <div
        className={classNames(
          'pointer-events-none hactive:overflow-visible hactive:h-0 hactive:-mt-4',
          selected && 'overflow-visible h-0 -mt-4',
        )}
      >
        {format(date, 'd')}
      </div>
    </span>
  )
}

export default forwardRef(CustomDayContent)
