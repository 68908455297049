import * as Dialog from '@radix-ui/react-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'

/**
 * UI component to display a modal dialog
 * @param {Object} props
 * @param {string} props.className - the class name of the component
 * @param {boolean} props.isOpen - whether the dialog is open
 * @param {() => void} props.onClose - callback to be called when the dialog is closed
 * @param {string} [props.titleText] - the title of the dialog
 * @param {boolean} [props.visuallyHiddenTitle = false]
 * @param {import('react').ReactNode} [props.titleEl] - the title element if it exists
 * @param {boolean} [props.unmount = false] - whether to unmount the modal content after changing to the closed state
 * @param {import('react').ReactNode} props.children - child elements to be rendered inside the dialog
 * @param {boolean} [props.renderClose = true]
 */
const Modal = props => {
  const {
    className,
    isOpen,
    onClose,
    titleText,
    visuallyHiddenTitle,
    titleEl,
    children,
    renderClose = true,
  } = props
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className='fixed inset-0 bg-black/30 z-50 animate-opacity-easein grid place-items-end xs:place-items-center overflow-y-auto'>
          <Dialog.Content>
            <div className='animate-contentShow-easein'>
              <div
                className={
                  className ||
                  'bg-white pt-6 px-9 pb-8 min-h-[346px] w-[652px] max-sm:w-screen rounded-[20px]'
                }
              >
                {!visuallyHiddenTitle ? (
                  <Dialog.Title asChild>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr min-content',
                        gridTemplateAreas: '"title close"',
                        alignItems: 'center',
                        marginBottom: '2rem',
                      }}
                    >
                      {titleText && (
                        <span
                          className={`text-2xl font-bold font-display`}
                          style={{ gridArea: 'title' }}
                        >
                          {titleText}
                        </span>
                      )}

                      {titleEl && titleEl}

                      {renderClose && (
                        <Dialog.Close asChild>
                          <button
                            aria-label='close'
                            className='w-8 h-8 rounded-full p-1 text-text-500 hover:text-text-700'
                            style={{ gridArea: 'close' }}
                            onClick={onClose}
                          >
                            <CloseIcon />
                          </button>
                        </Dialog.Close>
                      )}
                    </div>
                  </Dialog.Title>
                ) : (
                  <VisuallyHidden.Root asChild>
                    <Dialog.Title>{titleText}</Dialog.Title>
                  </VisuallyHidden.Root>
                )}
                {children}
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export { Modal }
