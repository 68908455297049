import { useDayPicker, useNavigation } from 'react-day-picker'
import { format } from 'date-fns'
import { ReactComponent as LeftIcon } from '../../../../assets/icons/left.svg'
import { ReactComponent as RightIcon } from '../../../../assets/icons/right.svg'

/**
 *
 * @param {import('react-day-picker').CaptionProps} param0
 */
const CustomCaption = ({ displayMonth, id }) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  const {
    labels: { labelNext, labelPrevious },
    locale,
  } = useDayPicker()

  return (
    <div className='flex justify-center items-center gap-4 text-2xl font-semibold tracking-tight'>
      <button
        type='button'
        className='flex text-primary-700 disabled:text-text-500 enabled:hover:text-primary-300'
        disabled={!previousMonth}
        aria-label={labelPrevious(previousMonth, { locale })}
        onClick={() => {
          goToMonth(previousMonth)
        }}
      >
        <LeftIcon className='p-1 h-6' />
      </button>
      <div role='presentation' id={id} aria-live='polite'>
        {format(displayMonth, 'MMMM yyyy')}
      </div>
      <button
        type='button'
        className='flex text-primary-700 disabled:text-text-500 enabled:hover:text-primary-300'
        disabled={!nextMonth}
        aria-label={labelNext(nextMonth, { locale })}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <RightIcon className='p-1 h-6' />
      </button>
    </div>
  )
}

export default CustomCaption
