import { useMemo } from 'react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { withReact } from 'slate-react'
import { withCustomEditor } from '../slateApi'

export function useContentEditableBoxEditor() {
  const editor = useMemo(
    () => withCustomEditor(withHistory(withReact(createEditor()))),
    [],
  )

  return editor
}
