import { useEffect } from 'react'
import { useHighlighting } from '../../../providers/HighlightingProvider'
import { ActionButton } from '../../ActionButton'

export const StepTemplate = ({
  image,
  title,
  subtitle,
  buttonText,
  buttonTextSuccess,
  loading,
  success,
  onClick,
  highlightedElementId,
  children,
}) => {
  const { highlightElement, resetHighlighting } = useHighlighting()

  useEffect(() => {
    highlightElement(highlightedElementId)

    return () => {
      resetHighlighting()
    }
  }, [highlightElement, highlightedElementId, resetHighlighting])

  return (
    <div className='flex flex-col items-center gap-[30px]'>
      {image}
      <div className='flex flex-col items-center gap-[12px] text-center'>
        <p className='text-primary-700 text-[26px] font-bold'>{title}</p>
        <p className='text-text-700 text-sm font-normal'>{subtitle}</p>
        {children ? (
          children
        ) : (
          <ActionButton
            text={buttonText}
            successText={buttonTextSuccess}
            disabled={loading}
            success={success}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  )
}
