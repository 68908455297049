import classNames from 'classnames'
import React, {
  Dispatch,
  HTMLProps,
  PropsWithChildren,
  SetStateAction,
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  arrow,
  FloatingArrow,
  useTransitionStyles,
  useTransitionStatus,
  Placement,
} from '@floating-ui/react'

type SetStateFunction<T> = Dispatch<SetStateAction<T>>

const ARROW_HEIGHT = 12
const TOOLTIP_GAP = 9

type TooltipHookArguments = Partial<{
  initialOpen: boolean
  placement: Placement
  isOpen: boolean
  setIsOpen: SetStateFunction<boolean>
}>

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  isOpen: controlledOpen,
  setIsOpen: setControlledOpen,
}: TooltipHookArguments) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const arrowRef = useRef<SVGSVGElement>(null)
  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(TOOLTIP_GAP + ARROW_HEIGHT),
      flip(),
      shift(),
      arrow({ element: arrowRef }),
    ],
  })
  const { isMounted, styles } = useTransitionStyles(data.context, {
    initial: {
      opacity: 0,
      transform: 'scale(0.8)',
    },
  })
  const { status } = useTransitionStatus(data.context)

  const context = data.context

  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      isMounted,
      animationStyles: styles,
      status,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data, isMounted, styles, status],
  )
}

const TooltipContext = createContext<ReturnType<typeof useTooltip> | null>(null)

export const useTooltipContext = () => {
  const context = useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export type TooltipProps = PropsWithChildren<TooltipHookArguments>

export function Tooltip({ children, ...options }: TooltipProps) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export const TooltipTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext()
  const childrenRef = (children as any).ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      }),
    )
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

export const TooltipContent = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  function TooltipContent(props, propRef) {
    const context = useTooltipContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])

    if (!context.isMounted) return null

    const floatingProps = context.getFloatingProps(props)
    floatingProps.className = classNames(
      'eot-tooltip-floating',
      floatingProps.className as string | undefined,
    )

    return (
      <FloatingPortal>
        <div
          ref={ref}
          style={{
            position: context.strategy,
            top: context.y ?? 0,
            left: context.x ?? 0,
            ...props.style,
            ...context.animationStyles,
          }}
          {...floatingProps}
          data-status={context.status}
          data-placement={context.placement}
        >
          <FloatingArrow
            width={24}
            height={ARROW_HEIGHT}
            ref={context.arrowRef}
            // @ts-ignore floating-ui internal typing issue
            context={context}
          />
          {props.children}
        </div>
      </FloatingPortal>
    )
  },
)
