import { RootTodoObject } from './types'
import { useDndkitTodoInteractions } from './useDndkitTodoInteractions'
import { useLocalDragAndDropTodos } from './useLocalDragAndDropTodos'
import { useMoveTodoMutation } from '../../../providers/Todo'
import { TodoInterface } from '../../Todo/types'
import { useDndkitEventHandlers } from './useDndkitEventHandlers'
import { WEIGHT_INCREMENT } from '../../../constants'
import { useToasts } from '../../../context/ToastsContext'

interface DndTodosArguments {
  filteredTodos: RootTodoObject
  weightIncrement?: number
}

export function useDndTodos({
  filteredTodos,
  weightIncrement = WEIGHT_INCREMENT,
}: DndTodosArguments) {
  const { mutateAsync, reset, isLoading } = useMoveTodoMutation()
  const addToast = useToasts()

  const moveTodo = async (
    todoToUpdate: TodoInterface,
    toPositionIndex: number,
    weightIncrement: number,
    formId: string,
    todos: TodoInterface[],
  ) => {
    try {
      await mutateAsync({
        todoToUpdate,
        toPositionIndex,
        weightIncrement,
        formId,
        todoIdList: todos.map(t => t.id),
      })
      reset()
    } catch (error) {
      console.error(error)

      const toastProps = {
        isClosable: true,
        variant: 'error',
      }
      if (error instanceof Error) {
        // @ts-ignore
        addToast({
          title: 'Failed to move the todo here. Please try again',
          description: error.message,
          ...toastProps,
        })
        throw error
      }

      // @ts-ignore
      addToast({
        title: 'Failed to move the todo here. Please try again',
        description: error,
        ...toastProps,
      })
      throw error
    }
  }

  const { localTodos, setLocalTodos } = useLocalDragAndDropTodos({
    filteredTodos,
  })

  const {
    contextProps,
    noCollisions,
    setActiveId,
    isDragging,
    recentlyMovedToNewContainer,
  } = useDndkitTodoInteractions({ localTodos })

  const events = useDndkitEventHandlers({
    filteredTodos,
    localTodos,
    setLocalTodos,
    setActiveId,
    moveTodo,
    noCollisions,
    recentlyMovedToNewContainer,
    weightIncrement,
  })

  return {
    contextProps: {
      ...contextProps,
      ...events,
    },
    localTodos,
    isLoading,
    isDragging,
    weightIncrement,
  }
}
