import { ReactComponent as StepOneImage } from '../assets/step-one.svg'

export const StepOne = () => {
  return (
    <div className='flex flex-col items-center gap-[30px]'>
      <StepOneImage width='100%' />
      <p className='text-primary-700 text-2xl font-bold'>Allow us to show you around!</p>
    </div>
  )
}
