import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import { useState } from 'react'
import Todo from './Todo'

const DraggableTodo = ({ virtualIndex, isLoading, isEmphasized, todo }) => {
  const [editing, setEditing] = useState(false)
  const [locked, setLocked] = useState(false)
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: todo.id,
      disabled: isLoading || editing || locked,
      data: {
        virtualIndex,
      },
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <fieldset
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={classNames(
        'min-w-0',
        editing && 'cursor-auto',
        isDragging && 'opacity-50 z-20',
      )}
    >
      <Todo
        isEmphasized={isEmphasized}
        todo={todo}
        editing={editing}
        setEditing={setEditing}
        locked={locked}
        setLocked={setLocked}
        active={isDragging}
      />
    </fieldset>
  )
}

export default DraggableTodo
