import { to2359 } from './time'

export const DUE_DATE = {
  OVERDUE: 'OVERDUE',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  TWO_DAYS: 'TWO_DAYS',
}

export const DUE_DATE_LABELS = {
  [DUE_DATE.OVERDUE]: 'Overdue',
  [DUE_DATE.TODAY]: 'Due today',
  [DUE_DATE.TOMORROW]: 'Due tomorrow',
  [DUE_DATE.TWO_DAYS]: 'Due in two days',
}

const getYesterdayDate = today => {
  const yesterday = new Date(today)

  yesterday.setDate(yesterday.getDate() - 1)

  return yesterday
}

const getDueDate = dueDate => {
  const today = to2359()
  const yesterday = to2359(getYesterdayDate(today))
  const tomorrow = to2359(today.getTime() + 1)
  const twoDays = to2359(tomorrow.getTime() + 1)

  if (dueDate <= yesterday) {
    return DUE_DATE.OVERDUE
  }

  if (dueDate <= today) {
    return DUE_DATE.TODAY
  }

  if (dueDate <= tomorrow) {
    return DUE_DATE.TOMORROW
  }

  if (dueDate <= twoDays) {
    return DUE_DATE.TWO_DAYS
  }
}

/**
 * Creates a formatted date string in the format of <Jan-Dec> <1-31>.
 *
 * This function is to be used when formatting date-like objects for the
 * purpose of displaying a due date.
 *
 * @param {Date | string | number | undefined} date
 * @param {boolean | undefined} includeDaysDifference
 * @returns {string | object}
 */
export const toDueDate = (date = new Date(), includeDaysDifference = false) => {
  if (!(date instanceof Date)) date = new Date(date)

  const dateString = date.toLocaleDateString(undefined, {
    month: 'long',
    day: 'numeric',
  })

  if (includeDaysDifference) {
    const dueDate = getDueDate(date)

    return { dueDate, dateString }
  }

  return dateString
}
