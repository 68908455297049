import { useContext } from 'react'
import { format } from 'date-fns'
import CalendarContext from '../context/CalendarContext'

export default function useCalendarTodos(date) {
  const formattedDateKey = format(new Date(date), 'yyyy-MM-dd')
  const context = useContext(CalendarContext)
  const todosForDate = context.allTodos[formattedDateKey] || []

  return {
    ...context,
    todosForDate,
  }
}
