import { authorizedApiFetch } from '.'
import { parseJSONResponse } from './utils'

async function post(problem) {
  const response = await authorizedApiFetch('/api/interview-prep/problem', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(problem),
  })
  return parseJSONResponse(response)
}

async function put(problem = {}) {
  const response = await authorizedApiFetch('/api/interview-prep/problem', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(problem),
  })
  return parseJSONResponse(response)
}

/**
 * Gets the user's interview prep problems
 *
 * @param {String} userId
 * @param {Object} [param1]
 * @param {AbortSignal} [param1.signal]
 */
async function get(userId, { signal } = {}) {
  const response = await authorizedApiFetch(
    `/api/interview-prep/problem?${new URLSearchParams({
      userId,
    })}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    },
  )
  return parseJSONResponse(response)
}

/**
 * Gets the user's default interview prep problems
 *
 * @param {String} userId
 * @param {Object} [param1]
 * @param {AbortSignal} [param1.signal]
 */
async function getDefaultInterviewProblems(userId, { signal } = {}) {
  const response = await authorizedApiFetch(
    `/api/interview-prep/default-problem?${new URLSearchParams({
      userId,
    })}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    signal,
  )

  return parseJSONResponse(response)
}

const api = {
  post,
  put,
  get,
  getDefaultInterviewProblems,
}

export default api
