import {
  addDays,
  differenceInCalendarDays,
  endOfISOWeek,
  endOfWeek,
  getISOWeek,
  getWeek,
  Locale,
  startOfISOWeek,
  startOfWeek,
} from 'date-fns'

/** Represents a week in the month.*/
/**
 * @typedef {{
 *   weekNumber: number,
 *   dates: Date[],
 * }} MonthWeek
 */

/** Return the weeks between two dates.
 * @param {Date} fromDate
 * @param {Date} toDate
 * @param {{
 *   ISOWeek?: boolean,
 *   locale?: Locale,
 *   weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
 *   firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7,
 * }} [options]
 * @returns {MonthWeek[]}
 */
export function daysToMonthWeeks(fromDate, toDate, options) {
  const toWeek = options?.ISOWeek ? endOfISOWeek(toDate) : endOfWeek(toDate, options)
  const fromWeek = options?.ISOWeek
    ? startOfISOWeek(fromDate)
    : startOfWeek(fromDate, options)

  const nOfDays = differenceInCalendarDays(toWeek, fromWeek)
  const days = []

  for (let i = 0; i <= nOfDays; i++) {
    days.push(addDays(fromWeek, i))
  }

  const weeksInMonth = days.reduce((result, date) => {
    const weekNumber = options?.ISOWeek ? getISOWeek(date) : getWeek(date, options)

    const existingWeek = result.find(value => value.weekNumber === weekNumber)
    if (existingWeek) {
      existingWeek.dates.push(date)
      return result
    }
    result.push({
      weekNumber,
      dates: [date],
    })
    return result
  }, [])

  return weeksInMonth
}
