import { ChevronDownIcon } from '@heroicons/react/20/solid'
import ItemNumber from './ItemNumber'

const ExpandableItem = ({
  itemNumber,
  title,
  problemLink,
  expand,
  isExpanded,
  children,
}) => {
  return (
    <div className={`${isExpanded ? 'pb-[55px] pt-[10px]' : ''}`}>
      <div
        className='flex items-start justify-between font-display not-italic leading-[120%] p-5 cursor-pointer'
        onClick={() => expand(itemNumber)}
      >
        <div className='flex gap-3'>
          <div className='flex justify-center items-center bg-panel-yellow rounded-md w-[38px] h-[30px]'>
            <ItemNumber number={itemNumber} />
          </div>
          <div className='flex flex-col items-start'>
            <span className='text-lg text-primary-black font-semibold'>{title}</span>
            {isExpanded ? (
              <a
                className='text-sm text-primary-dark font-medium'
                href={problemLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                Find the problem here
              </a>
            ) : null}
          </div>
        </div>
        <ChevronDownIcon className='text-primary-700 w-5' />
      </div>
      {isExpanded ? <div className='ml-[70px]'>{children}</div> : null}
    </div>
  )
}

export default ExpandableItem
