import { ReactNode, useEffect, useState } from 'react'

export function useCallToAction() {
  const [isOpen, setIsOpen] = useState(false)
  const [ctaContent, setContent] = useState<ReactNode>(null)

  useEffect(() => {
    let timeoutId: number | undefined
    if (isOpen) {
      timeoutId = window.setTimeout(() => {
        setIsOpen(false)
      }, 3000)
    }

    return () => window.clearTimeout(timeoutId)
  }, [isOpen])

  return {
    isOpen,
    setIsOpen,
    ctaContent,
    setContent,
  }
}

export type CallToActionResult = ReturnType<typeof useCallToAction>
