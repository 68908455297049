import { useRef, useState } from 'react'
import { to0001 } from '../utils/time'

function useTodoForm({ todo, beforeBlurSubmit, beforeSubmit, afterSubmit } = {}) {
  const [dueDate, setDueDate] = useState(
    todo?.dueDate ? new Date(todo?.dueDate) : undefined,
  )
  /**
   * @type {import('react').MutableRefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLElement>}
   */
  const titleRef = useRef()
  const containerRef = useRef()
  const skipNextBlurEventRef = useRef(false)
  const disableBlurEventsRef = useRef(false)

  const disableBlurEvents = disabled => {
    disableBlurEventsRef.current = disabled
  }

  const onSubmit =
    callback =>
    /**
     * @type {React.FormEventHandler<HTMLFormElement>}
     */
    async ev => {
      ev.preventDefault()
      if (beforeSubmit && !beforeSubmit(ev)) return
      skipNextBlurEventRef.current = true
      await _handleSubmit(callback)
    }
  const onBlur =
    callback =>
    /**
     * @type {React.FocusEventHandler}
     */
    async ev => {
      // escape hatch to not handle blur events
      if (disableBlurEventsRef.current) return

      const { target, relatedTarget } = ev
      if (skipNextBlurEventRef.current) {
        skipNextBlurEventRef.current = false
        return
      }
      const insideForm =
        containerRef.current.contains(target) &&
        containerRef.current.contains(relatedTarget)
      if (insideForm) {
        return 'skipped'
      }
      if (beforeBlurSubmit && !beforeBlurSubmit(ev)) return
      await _handleSubmit(callback)
    }
  const _handleSubmit = async callback => {
    const titleElm = titleRef.current
    const title = titleElm?.value

    try {
      const date = dueDate ? to0001(dueDate).getTime() : undefined
      await callback({
        title,
        date,
      })
      if (titleElm) titleElm.value = ''
      afterSubmit && afterSubmit()
    } finally {
    }
  }

  return {
    dueDate,
    setDueDate,
    titleRef,
    containerRef,
    onSubmit,
    onBlur,
    disableBlurEvents,
  }
}

export default useTodoForm
