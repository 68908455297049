import { useEffect } from 'react'
import { useHighlighting } from '../../../providers/HighlightingProvider'

export const MobileAddDueDate = () => {
  const { highlightElement, resetHighlighting } = useHighlighting()

  useEffect(() => {
    highlightElement('later-column', null, true)

    return () => {
      resetHighlighting()
    }
  }, [])

  return (
    <p className='font-display font-normal text-base text-text-700 text-center'>
      Adding a due date to your todo will ensure you get a text reminder and it’s visible
      in your Google calendar
    </p>
  )
}
