import classNames from 'classnames'
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'
import { useId } from 'react'
import { bracketsAreBalanced } from '../../../../utils/functions'
import { countryCode } from '../../../../utils/getCountryCode'

export const PhoneInput = ({ register, setValue, watch, errors, isSubmitting }) => {
  const phoneNumberId = `phoneNumber${useId()}`
  const phoneNumber = watch('phoneNumber', '')

  /**
   * @type {import('react-hook-form').RegisterOptions}
   */
  const phoneNumberOpts = {
    setValueAs: value => {
      const typer = new AsYouType(countryCode)
      const output = typer.input(value)

      if (value.length !== output.length && !bracketsAreBalanced(value))
        return typer.getChars()

      return output
    },
    validate: async number => {
      if (!number) return false
      const result = parsePhoneNumber(number, countryCode)
      setValue('preferences.textWarningsEnabled', result?.isValid() || false)
      return result?.isValid()
    },
  }

  return (
    <div className='w-full'>
      <label
        className='hidden xs:block text-text-700 text-sm font-normal'
        htmlFor={phoneNumberId}
      >
        Enter your mobile phone number:
      </label>
      <input
        id={phoneNumberId}
        value={phoneNumber}
        placeholder='(000) 000-0000'
        aria-invalid={!!errors.phoneNumber}
        disabled={isSubmitting}
        className={classNames(
          'block w-full rounded-md outline outline-1 outline-transparent text-base text-text-500 text-center font-normal focus-visible:outline-primary-200 p-3.5',
          errors.phoneNumber && '!outline-red-500',
        )}
        {...register('phoneNumber', phoneNumberOpts)}
      />
    </div>
  )
}
