import { Auth } from 'aws-amplify'

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL ?? ''

/**
 * The api prefixed fetch call
 * @param {RequestInfo | URL} url
 * @param {RequestInit | undefined} opts
 * @returns {Promise<Response>}
 */
export const apiFetch = async (url, opts) => {
  const apiUrl = API_SERVER_URL + url
  const response = await fetch(apiUrl, opts)
  return response
}

/**
 * The authorized api prefixed fetch call
 * @param {RequestInfo | URL} url
 * @param {RequestInit | undefined} opts
 * @returns {Promise<Response>}
 */
export const authorizedApiFetch = async (url, opts) => {
  if (!opts) opts = { headers: {} }
  if (!opts.headers) opts.headers = {}

  const session = await Auth.currentSession()
  const token = await session.getAccessToken().getJwtToken()
  const authorization = `Bearer ${token}`
  opts.headers.authorization = authorization

  return await apiFetch(url, opts)
}

export default apiFetch
