import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import deepEqual from 'deep-equal'
import { Editor, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { DAY_IN_MS, WEIGHT_INCREMENT } from '../../../constants'
import useAuth from '../../../hooks/useAuth'
import { useCreateTodoMutation, useTodos } from '../../../providers/Todo'
import useTodoForm from '../../../hooks/useTodoForm'
import { getCustomTimeHeader, to0001 } from '../../../utils/time'
import ContentEditableBox, {
  useContentEditableBoxEditor,
  RtfFormat,
} from '../../inputs/ContentEditableBox'

const initialValue = [RtfFormat.paragraph([RtfFormat.text()])]

const AgendaTodoForm = ({
  formId,
  nextWeight,
  autoFocus,
  showBorder = false,
  isInsert = false,
  isOpen = true,
  closeForm,
  date,
}) => {
  const { userId } = useAuth()
  const { filteredTodos } = useTodos()
  const [error, setError] = useState()
  const editor = useContentEditableBoxEditor()
  const { isLoading, mutate, reset } = useCreateTodoMutation({
    mutationKey: ['createTodo', formId],
    onSuccess: () => {
      if (titleRef.current) titleRef.current.value = ''
      setError()
      setDueDate(date)
      reset()
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      })
    },
    onError: error => {
      setError({ message: 'Failed to create the todo. Please try again', error })
    },
  })
  const [value, setValue] = useState(initialValue)
  const beforeSubmit = event => {
    if ((event.code !== 'Enter' && event.code !== 'NumpadEnter') || event.shiftKey) return
    if (deepEqual(value, initialValue)) {
      if (!isInsert) return
      closeForm()
      return
    }
    return true
  }
  const beforeBlurSubmit = () => {
    if (deepEqual(value, initialValue)) {
      if (!isInsert) return
      closeForm()
      return
    }
    return true
  }
  const afterSubmit = () => {
    if (isInsert) {
      closeForm()
    }
  }
  const { setDueDate, containerRef, titleRef, onSubmit, onBlur } = useTodoForm({
    afterSubmit,
    beforeBlurSubmit,
    beforeSubmit,
  })

  useEffect(() => {
    if (isInsert && isOpen) {
      ReactEditor.focus(editor)
    }
  }, [isInsert, isOpen, editor])

  async function postTodo() {
    const header = getCustomTimeHeader(to0001(date))
    const todoDate =
      header === 'today'
        ? to0001()
        : header === 'tomorrow'
        ? new Date(to0001().getTime() + DAY_IN_MS)
        : to0001(date)
    const todoWeightList =
      header === 'today'
        ? filteredTodos.todosToday
        : header === 'tomorrow'
        ? filteredTodos.todosTomorrow
        : filteredTodos.todosLater
    const lastWeight =
      todoWeightList.length > 0 ? todoWeightList[todoWeightList.length - 1].weight : 0
    const weight = nextWeight ? nextWeight : lastWeight + WEIGHT_INCREMENT
    const title = value
    mutate({
      title,
      userId,
      todoDate,
      dueDate: to0001(date),
      weight,
    })
  }

  const borderClass = showBorder ? 'border-primary' : 'border-transparent'

  const formClass = classNames(
    'group/todo-form add-task-form px-5 py-1.5 rounded-xl text-[#9592A1] hover:text-[#6B667B]',
    '',
    'hover:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)] focus-within:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)]',
    isInsert && borderClass,
  )

  return (
    <form
      className={formClass}
      onSubmit={onSubmit(postTodo)}
      disabled={isLoading}
      onBlur={onBlur(postTodo)}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='pl-6' ref={containerRef}>
        <input
          className='-ml-8 mr-1.5 w-[18px] h-[18px] align-middle'
          type='checkbox'
          disabled
          readOnly
        />
        <ContentEditableBox
          placeholder='Enter new todo'
          className='inline-block w-full outline-none bg-transparent resize-none align-text-top caret-[#0D58D9] text-black'
          onKeyUp={onSubmit(postTodo)}
          editor={editor}
          value={value}
          setValue={setValue}
          autoFocus={autoFocus}
        />
        {error && <p className='whitespace-normal text-red-500'>{error.message}</p>}
      </div>
    </form>
  )
}

export default AgendaTodoForm
