import { WEIGHT_INCREMENT } from '../../../constants'
import AgendaTodoContainer from './AgendaTodoContainer'

const Day = props => {
  // Get the current date
  const currentDate = new Date()

  // Determine if the current date matches the date of this agenda
  const isCurrentDate = currentDate.toDateString() === props.date.toDateString()
  // Determine if current date is tommorrow
  const isTommorow = currentDate.getDate() === props.date.getDate() - 1

  return (
    <div className='bg-transparent pt-3'>
      <h2
        className={[
          isCurrentDate
            ? 'font-medium text-primary pt-3'
            : props.todos.length > 0
            ? 'text-black'
            : 'text-[#6B667B] pt-3',
          'pt-3 font-medium text-lg',
        ]}
      >
        {props.date.toLocaleString('default', { month: 'long', day: 'numeric' })}
        {isCurrentDate ? (
          <span>
            <span className='align-[4px] font-bold text-xl'> . </span>Today
          </span>
        ) : (
          <></>
        )}
        {isTommorow ? (
          <span>
            <span className='align-[4px] font-bold text-xl'> . </span> Tomorrow
          </span>
        ) : (
          <></>
        )}
      </h2>
      <hr />
      <div className='mt-1'>
        <AgendaTodoContainer
          formId='agenda'
          todos={props.todos}
          weightIncrement={WEIGHT_INCREMENT}
          date={props.date}
        />
      </div>
    </div>
  )
}
export default Day
