import { useTodoStartDateCallToAction } from './useTodoStartDateCallToAction'
import { useTodoMobileDeleteCallToAction } from './useTodoMobileDeleteCallToAction'
import { useCallToAction } from '../../../hooks/useCallToAction'

export function useTodoCallToAction(todo: any) {
  const { isOpen, setIsOpen, ctaContent, setContent } = useCallToAction()

  const todoStartDateCTA = useTodoStartDateCallToAction({
    todo,
    setIsOpen,
    setContent,
  })
  useTodoMobileDeleteCallToAction({
    todo,
    setIsOpen,
    setContent,
  })

  return {
    isOpen,
    setIsOpen,
    ctaContent,
    ...todoStartDateCTA,
  }
}
