import CourseCard from '../../../CourseCard'
import ProgressTracker, { PROGRESS_TRACKER_TEXT_COLORS } from '../../../ProgressTracker'

import HarvardLogo from './assets/harvard-logo.svg'
import StanfordLogo from './assets/stanford-logo.svg'

import { ReactComponent as BottomRightBlueIcon } from './assets/bottom-right-blue.svg'
import { ReactComponent as BottomRightPurpleIcon } from './assets/bottom-right-purple.svg'
import { ReactComponent as BottomRightLineIcon } from './assets/bottom-right-line.svg'
import { ReactComponent as OrangeDotsIcon } from '../assets/orange-dots.svg'
import SectionHeader, { SECTION_HEADER_TEXT_COLORS } from '../SectionHeader'
import { useState } from 'react'
import useAuth from '../../../../hooks/useAuth'

const coursesCards = [
  {
    title: 'CS50: Introduction to Computer Science',
    description:
      'An introduction to the intellectual enterprises of computer science and the art of programming',
    courseLink:
      'https://pll.harvard.edu/course/cs50-introduction-computer-science?delta=0',
    university: 'Harvard',
    logo: HarvardLogo,
  },
  {
    title: '106a: Programming Methodology',
    description:
      'Introduction to the engineering of computer applications emphasizing modern software engineering principles',
    courseLink: 'https://see.stanford.edu/course/cs106a',
    university: 'Stanford',
    logo: StanfordLogo,
  },
]

const progressTrackerChoices = {
  name: 'done',
  items: [
    { id: 'working', label: 'I’m working on this now', value: false },
    { id: 'done', label: 'I’m done!', value: true },
  ],
}

const BrushUp = () => {
  const {
    userId,
    user: { careersStepOneComplete },
    trackCareerProgress,
  } = useAuth()

  const [progress, setProgress] = useState(careersStepOneComplete)
  const [isSuccess, setIsSuccess] = useState(null)

  const trackProgress = async progressResult => {
    if (progress !== progressResult) {
      setProgress(progressResult)

      try {
        await trackCareerProgress({ userId, section: 1, value: progressResult })
        setIsSuccess(true)
      } catch (error) {
        setIsSuccess(false)
      }
    }
  }

  return (
    <section className='relative bg-primary-700 px-56 pt-[90px] pb-[70px] font-display not-italic leading-[120%]'>
      <BottomRightBlueIcon className='absolute bottom-0 right-0' />
      <BottomRightPurpleIcon className='absolute bottom-0 right-0' />
      <BottomRightLineIcon className='absolute bottom-0 right-0' />
      <OrangeDotsIcon className='absolute top-[-38px] left-[67px]' />

      <SectionHeader
        sectionNumber={1}
        color={SECTION_HEADER_TEXT_COLORS.white}
        title='Brush up data structures'
        subtitle='Here’s some courses we recommend'
      />

      <div className='flex justify-center items-center gap-10 mt-[30px] mb-[50px]'>
        {coursesCards.map(
          ({ title, description, courseLink, university, logo }, index) => (
            <CourseCard
              key={index}
              title={title}
              description={description}
              courseLink={courseLink}
              logo={logo}
              university={university}
            />
          ),
        )}
      </div>
      <div className='relative flex justify-center items-center'>
        <div className='flex flex-col items-start gap-2.5'>
          <ProgressTracker
            color={PROGRESS_TRACKER_TEXT_COLORS.white}
            choices={progressTrackerChoices}
            progress={progress}
            trackProgress={trackProgress}
          />
          <p className='text-sm text-text-green font-medium'>
            {isSuccess && progress === true
              ? 'Great work! You’re on your way to an internship.'
              : ''}
          </p>
        </div>
      </div>
    </section>
  )
}

export default BrushUp
