import LinkifyIt from 'linkify-it'
import { Editor, Element, Path, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import tlds from 'tlds'
import { RtfFormat } from './RtfFormat'

const linkify = LinkifyIt()
linkify.tlds(tlds)

export const ANCHOR = 'anchor'

/**
 * If text contains something similar to link `true` will be returned
 * @param {string} text
 * @returns {boolean}
 */
export const isLink = text => linkify.test(text)

/**
 * returns the url for a given link.
 *
 * i.e. google.com -> https://google.com
 *
 * @param {string} link
 * @returns {string}
 */
export const toUrl = link => {
  const matches = linkify.match(link)
  if (!matches) return
  const match = matches[0]
  return match.url
}

/**
 *
 * @param {ReactEditor} editor
 * @returns {boolean}
 */
export const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n => Element.isElementType(n, ANCHOR),
  })
  return !!link
}

/**
 * We additionally want to return isEdit flag to upper function
 * @param {ReactEditor} editor
 * @returns {boolean}
 */
const isEditLink = editor => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
    return true
  }
  return false
}

/**
 * Remove `link` inline from the current caret position
 * @param {ReactEditor} editor
 */
export const unwrapLink = editor => {
  const [link] = Editor.nodes(editor, {
    match: n => Element.isElementType(n, ANCHOR),
  })
  // we need to select all link text for case when selection is collapsed. In
  // that case we re-create new link for the same text
  Transforms.select(editor, link[1])
  Transforms.unwrapNodes(editor, {
    match: n => Element.isElementType(n, ANCHOR),
  })
}

/**
 * Wrap underlying text into `link` inline
 * @param {ReactEditor} editor
 * @param {string} href - href
 */
export const wrapLink = (editor, href) => {
  const isEdit = isEditLink(editor)

  const { selection } = editor
  const isExpanded = selection && Range.isExpanded(selection)
  const link = {
    type: ANCHOR,
    href,
    children: isExpanded || isEdit ? [] : [{ text: href }],
  }

  // if this is a link editing, we shouldn't rename it even if it is not selected
  if (isExpanded || isEdit) {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  } else {
    Transforms.insertNodes(editor, link)
  }
}

/**
 * @param {ReactEditor} editor
 * @param {string} url - href
 */
export const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

/**
 * We are trying to detect links while user typing
 * @param {KeyboardEvent} event
 * @param {ReactEditor} editor
 */
export const onKeyDown = (event, editor) => {
  // It will be better if we will try to detect links only after "word" will be
  // finished. We will check links possibilities at current word near the caret
  if (event.key !== 'Enter' && event.key !== ' ' && event.key !== ',') {
    return
  }

  const { selection } = editor
  if (selection && Range.isCollapsed(selection)) {
    const [start, end] = Range.edges(selection)
    const [word, wordAnchorOffset] = getWordUnderCaret(
      Editor.string(editor, start.path),
      selection,
    )
    const links = linkify.match(word)

    if (!links) {
      return
    }
    links.forEach(link => {
      Transforms.select(editor, {
        anchor: {
          path: start.path,
          offset: wordAnchorOffset + link.index,
        },
        focus: {
          path: start.path,
          offset: wordAnchorOffset + link.lastIndex,
        },
      })
      wrapLink(editor, link.url)
    })
    const parentPath = Path.parent(editor.selection.focus.path)
    parentPath[parentPath.length - 1] = parentPath[parentPath.length - 1] + 1
    const point = {
      path: parentPath,
      offset: 0,
    }
    Transforms.select(editor, {
      anchor: point,
      focus: point,
    })
  }
}

/**
 * In this function we try to detect link-like text peaces and convert them to
 * links. We assume that this pasted text is a plain text and because of that
 * we can transform it to slate-compatible fragment before inserting
 * @param {DataTransfer} data
 * @param {function} insertData
 */
export const insertPastedLinks = (data, insertData) => {
  const text = data.getData('text/plain')
  const links = linkify.match(text)

  if (!links) {
    insertData(data)
    return
  }

  let prevLineStart = 0
  const pasteLength = text.length

  const children = []

  links.forEach(link => {
    const prevText = text.slice(prevLineStart, link.index)
    if (prevText.length) {
      children.push(RtfFormat.text(prevText))
    }
    children.push(toLinkFragment(link))
    prevLineStart = link.lastIndex
  })

  const nextText = text.slice(prevLineStart, pasteLength)
  if (nextText.length) {
    children.push(RtfFormat.text(nextText))
  }

  if (!children.length) {
    insertData(data)
    return
  }

  // generate new DataTransfer instance w/ slate-compatible fragment
  const dataTrans = new DataTransfer()
  const fragment = JSON.stringify([{ children }])
  const encoded = window.btoa(encodeURIComponent(fragment))
  dataTrans.setData('application/x-slate-fragment', encoded)
  dataTrans.setData('text/plain', text)

  insertData(dataTrans)
}

const toLinkFragment = link => RtfFormat.anchor(link.url, [RtfFormat.text(link.text)])

/**
 * Find the underlying word under selection
 * @param {string} text
 * @param {Range} selection
 * @returns {[string, number]}
 */
const getWordUnderCaret = (text, selection) => {
  const start = Range.isForward(selection) ? selection.anchor : selection.focus
  const wordBeginningOffset = traverseBehind(start.offset, text)
  return [text.slice(wordBeginningOffset, start.offset), wordBeginningOffset]
}

/**
 * Find the word beginning
 * @param {number} index - current search position
 * @param {string} text
 * @return {number} word beginning position
 */
const traverseBehind = (index, text) => {
  if (index > 0 && !isWhitespace(text[index - 1])) {
    return traverseBehind(index - 1, text)
  }
  return index
}

/**
 * Whitespace checker
 * @param {string} value
 * @return {boolean}
 */
const isWhitespace = value => {
  return /[ \f\n\r\t\v\u00A0\u2028\u2029]/.test(value)
}
