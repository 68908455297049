import React from 'react'

import { FileItem } from './FileItem'

const FilesList = ({ files, triggerFileSelect, deleteFile, uploadFile }) => {
  return (
    <section className='flex flex-col gap-2 w-full'>
      <p className='font-display font-normal text-xs text-[#999999]'>
        Upload document(s) containing due dates
      </p>

      <ul className='flex flex-col gap-2'>
        {files.map(file => (
          <FileItem
            key={file.id}
            file={file}
            onDelete={() => deleteFile(file.id)}
            onReUpload={() => uploadFile(file)}
          />
        ))}
      </ul>

      <div className='h-[52px] flex items-center justify-between px-4 rounded-lg border border-[#DFDFE1] border-dashed'>
        <button
          className='font-medium text-primary text-xs p-2 -ml-2 font-display rounded hover:bg-primary/5'
          onClick={triggerFileSelect}
        >
          Click here to select documents
        </button>

        <span className='uppercase text-[#9592A1] font-medium text-[10px] font-display'>
          .pdf .jpg .jpeg .docx .xls
        </span>
      </div>
    </section>
  )
}

export { FilesList }
