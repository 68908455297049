import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { useId } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { difficulties } from '../Problems'
import { ReactComponent as DownIcon } from '../../../../../assets/icons/down.svg'
import { useToasts } from '../../../../../context/ToastsContext'
import { useWriteInterviewPrepProblemMutation } from '../../hooks/useWriteInterviewPrepProblemMutation'
import useAuth from '../../../../../hooks/useAuth'
import { CARREER_PROBLEMS_DIFFICULTIES_COLORS } from '../utils'

/**
 *
 * @param {object} param0
 * @param {{
 *    id: string
 *    name: string,
 *    link: string,
 *    difficulty?: string,
 *    notes?: string,
 *    createdAt: Date | string | number,
 *    updatedAt: Date | string | number,
 * }} param0.problem
 * @returns
 */
export const ProblemModal = ({ problem, onSubmitted = () => {} }) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting: isFormSubmitting },
  } = useForm({
    defaultValues: problem
      ? problem
      : {
          name: '',
          link: '',
          difficulty: null,
          notes: '',
          createdAt: new Date(),
        },
  })
  const { userId, trackCareerProgress } = useAuth()
  const addToast = useToasts()
  const { mutateAsync, isLoading } = useWriteInterviewPrepProblemMutation()

  const nameId = useId()
  const linkId = useId()
  const difficultyId = useId()
  const notesId = useId()

  const createdAt = new Date(watch('createdAt', new Date()))
  const isSubmitting = isFormSubmitting || isLoading

  const onSubmit = async problemData => {
    try {
      const newProblem = await mutateAsync({ ...problemData, userId })
      onSubmitted(newProblem)
    } catch (error) {
      console.error(error)
      addToast({
        variant: 'error',
        description: error.message || 'Unknown error occurred',
      })
    }
  }

  return (
    <form className='space-y-8' onSubmit={handleSubmit(onSubmit)}>
      <div className='space-y-5'>
        <div className='space-y-1'>
          <div>Date</div>
          <div className='inline-block bg-text-200 p-4 rounded-md text-neutral-500'>
            {createdAt.toLocaleDateString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </div>
        </div>
        <div className='space-y-1'>
          <label htmlFor={nameId}>Problem name</label>
          <input
            disabled={isSubmitting}
            className={classNames(
              'block w-full rounded-md outline outline-1',
              'focus-visible:outline-primary-200 p-3.5',
              errors.name ? '!outline-red-500' : 'outline-transparent',
            )}
            id={nameId}
            {...register('name', { required: true })}
          />
          {errors.name && <p className='text-red-500 mb-3'>This field is required.</p>}
        </div>
        <div className='space-y-1'>
          <label htmlFor={linkId}>Link to problem</label>
          <input
            type='url'
            disabled={isSubmitting}
            className={classNames(
              'block w-full rounded-md outline outline-1 text-text-link',
              'focus-visible:outline-primary-200 p-3.5',
              errors.link ? '!outline-red-500' : 'outline-transparent',
            )}
            id={linkId}
            {...register('link', { required: true })}
          />
          {errors.link && <p className='text-red-500 mb-3'>This field is required.</p>}
        </div>
        <div className='space-y-1'>
          <label htmlFor={difficultyId}>How hard was this problem</label>
          <Controller
            name='difficulty'
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={({
              field: { onChange, value, onBlur, name, ref },
              fieldState: { error },
            }) => {
              const difficultyValue = value && value.toUpperCase()

              return (
                <Listbox value={value} onChange={onChange} disabled={isSubmitting}>
                  <div className='relative'>
                    <Listbox.Button
                      name={name}
                      onBlur={onBlur}
                      ref={ref}
                      className={classNames(
                        'flex justify-between items-center min-w-[240px] bg-white',
                        'text-left rounded-md outline outline-1',
                        'focus-visible:outline-primary-200 p-3.5',
                        error ? '!outline-red-500' : 'outline-transparent',
                      )}
                    >
                      {(difficultyValue && (
                        <div
                          className={classNames(
                            'inline-block py-2 px-6 rounded-full text-black bg-opacity-10 border',
                            CARREER_PROBLEMS_DIFFICULTIES_COLORS[difficultyValue],
                          )}
                        >
                          {
                            difficulties.find(diff => difficultyValue === diff.value)
                              ?.label
                          }
                        </div>
                      )) || <span />}
                      <DownIcon className='w-4' />
                    </Listbox.Button>
                    <Listbox.Options
                      className='absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white
                    py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                    >
                      {difficulties.map(({ value: difficultyValue, label }, i) => (
                        <Listbox.Option
                          key={i}
                          value={difficultyValue}
                          className={({ selected }) =>
                            classNames(
                              'relative cursor-default select-none px-3.5 py-2 group/option',
                              selected && 'text-white',
                            )
                          }
                        >
                          <div
                            className={classNames(
                              'self-start inline-block py-2 px-6 rounded-full text-black border bg-opacity-10 group-hover/option:bg-opacity-25',
                              CARREER_PROBLEMS_DIFFICULTIES_COLORS[difficultyValue],
                              value === difficultyValue && '!bg-opacity-40',
                            )}
                          >
                            {label}
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                    {error && (
                      <p className='text-red-500 mb-3'>Oops, please select an option</p>
                    )}
                  </div>
                </Listbox>
              )
            }}
          />
        </div>
        <div className='space-y-1'>
          <label htmlFor={notesId}>Notes (for your future self!)</label>
          <textarea
            disabled={isSubmitting}
            className={classNames(
              'block w-full rounded-md outline outline-1 overflow-scroll',
              'focus-visible:outline-primary-200 p-3.5',
              errors.notes ? '!outline-red-500' : 'outline-transparent',
            )}
            id={notesId}
            {...register('notes', { required: true })}
          />
          {errors.notes && <p className='text-red-500 mb-3'>This field is required.</p>}
        </div>
      </div>

      <div className='flex justify-end items-center gap-3'>
        <button
          type='submit'
          className='flex gap-2 items-center justify-center p-3 text-center md:w-20 lg:w-40 bg-primary-300 disabled:text-white disabled:bg-neutral-400 focus-visible:bg-primary-200 hover:bg-primary-200 font-medium leading-[120%] text-primary-700 rounded-md'
        >
          Submit{isSubmitting && 'ting...'}
          {isSubmitting && (
            <span className='animate-spin rounded-full mx-auto border-2 h-6 w-6 border-transparent border-t-white' />
          )}
        </button>
      </div>
    </form>
  )
}
