import { StepTemplate } from '../StepTemplate'

import { ReactComponent as SyllabusUploadImage } from '../assets/syllabus-upload.svg'
import { useState } from 'react'
import { FileUploader } from '../../FileUploader'

export const SyllabusUpload = () => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  const openSyllabusUpload = event => {
    event.preventDefault()

    setUploadModalOpen(true)
  }

  const closeSyllabusUpload = () => {
    setUploadModalOpen(false)
  }

  return uploadModalOpen ? (
    <FileUploader show={uploadModalOpen} close={closeSyllabusUpload} />
  ) : (
    <StepTemplate
      image={<SyllabusUploadImage width='100%' />}
      title='Upload your course syllabus to add important deadlines right to your todo list'
      subtitle='Add a PDF, image or document to import upcoming important dates to the later tab'
      buttonText='Try it out'
      buttonTextSuccess='Try it out'
      loading={false}
      success={false}
      onClick={openSyllabusUpload}
      highlightedElementId='syllabus-upload-button'
    />
  )
}
