import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ErrorBoundary } from '@highlight-run/react'
import { H as HighlightTracking } from 'highlight.run'

const isLocalhost = !!(window.location.hostname === 'localhost')
if (!isLocalhost) HighlightTracking.init(51)

Amplify.configure(awsExports)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
