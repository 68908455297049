import ApiResponseError from '../errors/ApiResponseError'

/**
 * returns the response object assuming its in json.
 * boilerplate code to throw server response errors if they are in json
 *
 * @param {Response} response
 * @returns {unknown}
 */
export async function parseJSONResponse(response) {
  if (
    !response.ok &&
    (response.headers.get('Content-Type') || '').includes('application/json')
  ) {
    const body = await response.json()
    throw new ApiResponseError({ response, body })
  }
  if (!response.ok)
    throw new ApiResponseError({ response, body: { message: 'Unknown response error' } })
  return await response.json()
}
