import { useRef, useState } from 'react'
import { useResponsiveWindow } from '../../../hooks/useResponsiveWindow'
import { MobileUserMenu } from './MobileUserMenu'
import useAuth from '../../../hooks/useAuth'
import { DesktopUserMenu } from './DesktopUserMenu'
import { useToggleBooleanUserPreference } from '../../../hooks/toggleBooleanUserPreference'

export const UserMenu = () => {
  const auth = useAuth()
  const { isMobile } = useResponsiveWindow()
  const [isOpen, setIsOpen] = useState(false)

  const signOut = () => auth.signOut()
  const { loading, toggleBooleanUserPreference } = useToggleBooleanUserPreference()

  const toggleCompletedTask = () => toggleBooleanUserPreference('showCompletedTasks')
  const toggleSmsNotifications = () => toggleBooleanUserPreference('textWarningsEnabled')

  const smsEnabled = auth.user?.preferences?.textWarningsEnabled
  const completedTasksEnabled = auth.user?.preferences?.showCompletedTasks

  const smsToggleText = `${smsEnabled ? 'Disables' : 'Enables'} sms notifications`
  const completedTaskToggleText = `${
    completedTasksEnabled ? 'Hides' : 'Shows'
  } completed tasks`

  const props = {
    isOpen,
    setIsOpen,
    toggleCompletedTask,
    toggleSmsNotifications,
    smsEnabled,
    completedTasksEnabled,
    smsToggleText,
    completedTaskToggleText,
    signOut,
    loading,
  }

  if (isMobile) return <MobileUserMenu {...props} />

  return <DesktopUserMenu {...props} />
}
