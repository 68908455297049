import { Modal } from '../common/Modal'
import { renderRtfFormat, serializeNodes } from '../inputs/ContentEditableBox'
import { TodoText } from './TodoText'

export const MobileDeleteTodoModal = ({
  todo,
  isDeleteModalOpen,
  setDeleteModalOpen,
  setIsDeleteVisible,
  setAnimateDelete,
}) => {
  return (
    <Modal
      renderClose={false}
      visuallyHiddenTitle
      titleText={`Delete "${
        typeof todo.title === 'string' ? todo.title : serializeNodes(todo.title)
      }"?`}
      isOpen={isDeleteModalOpen}
      onClose={() => {
        setDeleteModalOpen(false)
        setIsDeleteVisible(false)
      }}
      className='mx-4 px-9 py-5 bg-white rounded-[20px] font-display space-y-2 text-center'
    >
      <h1 className='font-medium tracking-tight'>
        Delete “
        <TodoText todo={todo} />
        ”?
      </h1>
      <div className='text-sm tracking-tight text-neutral-700'>
        This to-do will be permanently deleted from your todos.
      </div>
      <div className='!mt-4 space-y-4'>
        <button
          onClick={() => {
            setDeleteModalOpen(false)
            setAnimateDelete(true)
          }}
          className='block w-full p-2 rounded-md font-medium bg-red-400 text-white'
        >
          Delete
        </button>
        <button
          onClick={() => {
            setDeleteModalOpen(false)
            setIsDeleteVisible(false)
          }}
          className='block w-full p-2 rounded-md font-medium bg-neutral-100'
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}
