import { createContext, useContext } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import useStep from '../../hooks/useStep'

/**
 * @typedef {{
 *   currentStep: number
 *   goToNextStep: () => void
 *   goToPrevStep: () => void
 *   canGoToNextStep: () => boolean
 *   canGoToPrevStep: () => boolean
 *   setStep: (step: number) => void
 *   reset: () => void
 *   formData: Record<string, unknown>
 *   steps: object[]
 * }} ContextValue
 *
 * @type {import('react').Context<ContextValue>}
 */
const StepperFormContext = createContext()

export function useStepperForm() {
  const context = useContext(StepperFormContext)

  if (!context) throw new Error(`StepperFormContext cannot be null`)

  return context
}

/**
 * @typedef {{
 *   Header: import('react').Component
 *   Content: import('react').Component
 * }} Step
 *
 * @param {object} param0
 * @param {Step[]} [param0.steps]
 * @param {(data: Record<string, unknown>) => Promise<void>} [param0.onSubmit]
 */
const StepperForm = ({ steps = [], onSubmit = async _ => {} }) => {
  const stepper = useStep(steps.length)
  const formMethods = useForm()

  const onStepSubmit = async data => {
    // go to next step if possible
    if (stepper.canGoToNextStep) return stepper.goToNextStep()

    // if we are on the last step, submit
    return await onSubmit(data)
  }

  const Step = steps.length && steps[stepper.currentStep - 1]

  return (
    <FormProvider {...formMethods}>
      <StepperFormContext.Provider value={{ ...stepper, steps }}>
        <form
          onSubmit={formMethods.handleSubmit(onStepSubmit)}
          className='grow flex flex-col justify-evenly gap-y-8'
        >
          {Step && <Step />}
        </form>
      </StepperFormContext.Provider>
    </FormProvider>
  )
}

export default StepperForm
