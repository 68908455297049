import { CAREER_PROBLEMS_DIFFICULTIES } from '../../../../../constants'

export const toTitleCase = word =>
  `${word[0].toUpperCase()}${word.substring(1, word.length).toLowerCase()}`

export const getDifficultyLevels = () =>
  Object.entries(CAREER_PROBLEMS_DIFFICULTIES).map(([id, value]) => ({
    id,
    value,
    label: toTitleCase(id),
  }))
