import { PropsWithChildren, ReactNode } from 'react'
import { Tooltip, TooltipContent, TooltipProps, TooltipTrigger } from './common/Tooltip'

type CallToActionProps = PropsWithChildren<TooltipProps & { ctaContent?: ReactNode }>

export const CallToAction = ({ children, ctaContent, ...props }: CallToActionProps) => {
  return (
    <Tooltip {...props}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent className='z-50 max-w-xxs text-center py-5 px-6 bg-primary-700 fill-primary-700 rounded-xl text-white'>
        {ctaContent}
      </TooltipContent>
    </Tooltip>
  )
}
