import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import classNames from 'classnames'
import { Fragment, MouseEventHandler, useMemo } from 'react'

import DraggableTodo from '../DraggableTodo'
import TodoForm from '../TodoForm'
import { InsertTodo } from '../InsertTodo'
import { Highlighter } from '../../Highlighter'
import {
  getLastWeightInTodoList,
  getTodoWeightFromDirectSiblings,
} from '../../../providers/Todo/utils'
import { TodoInterface } from '../types'
import { TodayHeader, TomorrowHeader, LaterHeader } from './Headers'

interface DroppableTodoContainerProps {
  todos: TodoInterface[]
  containerId: string
  formId: 'today' | 'tomorrow' | 'later'
  isEmphasized: boolean
  onColumnSelect: (id: 'today' | 'tomorrow' | 'later') => void
  weightIncrement: number
  isDragging: boolean
  isLoading: boolean
  autoFocusForm?: boolean
  id?: string
}

const headers = {
  today: TodayHeader,
  tomorrow: TomorrowHeader,
  later: LaterHeader,
}

const DroppableTodoContainer = ({
  todos = [],
  containerId,
  formId,
  isEmphasized,
  onColumnSelect,
  weightIncrement,
  autoFocusForm,
  isDragging,
  isLoading,
  id,
}: DroppableTodoContainerProps) => {
  const lastWeight = getLastWeightInTodoList(todos) + weightIncrement
  const getNextWeight = (i: number) => {
    const { newWeight } = getTodoWeightFromDirectSiblings(todos, i, weightIncrement)
    return newWeight
  }

  const { setNodeRef } = useDroppable({
    id: containerId,
    disabled: isLoading,
  })
  // MUST BE MEMO'd if we want the dnd item transitions working
  const items = useMemo(() => todos.map(todo => todo.id), [todos])

  const onHeaderClick: MouseEventHandler = event => {
    event.preventDefault()

    onColumnSelect(formId)
  }

  const Header = headers[formId]

  return (
    <SortableContext
      id={containerId}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <Highlighter
        id={id}
        className='drop-column cursor-pointer bg-white-bg-light rounded-[10px] h-[100%] p-5'
      >
        <div ref={setNodeRef} className='drop-column cursor-pointer'>
          <div
            className={classNames(
              'mb-2 bg-white-bg-light rounded-lg',
              isEmphasized ? 'font-bold' : 'font-semibold',
            )}
            onClick={onHeaderClick}
          >
            {<Header />}
          </div>
          {todos.map((todo, virtualIndex) => (
            <Fragment key={todo.id || virtualIndex}>
              <DraggableTodo
                todo={todo}
                virtualIndex={virtualIndex}
                isLoading={isLoading}
                isEmphasized={isEmphasized}
              />

              {!isDragging && virtualIndex !== todos.length - 1 && (
                <InsertTodo formId={formId} nextWeight={getNextWeight(virtualIndex)} />
              )}
            </Fragment>
          ))}
          <div className={classNames('transition-all rounded-lg')} />
          <TodoForm
            formId={formId}
            nextWeight={lastWeight}
            autoFocus={autoFocusForm}
            closeForm={() => {}}
          />
        </div>
      </Highlighter>
    </SortableContext>
  )
}

export default DroppableTodoContainer
