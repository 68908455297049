const RadioInput = ({ id, name, label, checked, onClick }) => {
  return (
    <label
      className='flex items-center gap-2.5 font-normal text-sm accent-primary-dark'
      htmlFor={id}
      onClick={onClick}
    >
      <input
        className='w-5 h-5 track-progress-input'
        type='radio'
        id={id}
        name={name}
        defaultChecked={checked}
      />
      {label}
    </label>
  )
}

export default RadioInput
