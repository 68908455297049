import { useEffect, useRef, useState } from 'react'
import { getTodoDateValue } from '../../../providers/Todo/utils'
import { toDueDate } from '../../../utils/date'
import { Pill } from './Pill'
import { DatePicker } from '../../inputs/DatePicker'
import { longPressExemptProps } from '../../../hooks/useLongPress'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

const DueDatePill = ({ preview, editing, setLocked, todo, updateDueDate }) => {
  const { dueDate, computedInTodoColumnId } = todo
  const todoDateValue = getTodoDateValue(todo)

  const [pillEdit, setPillEdit] = useState(false)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (preview) return
    setLocked(open)
  }, [preview, open])
  const picker = useRef({})

  const handler = () => {
    if (!pillEdit || !open) return

    setOpen(false)
    setLocked(false)
    setPillEdit(false)
  }
  useOnClickOutside(picker, ref => ref?.current?.floating, handler, 'mousedown')

  if (!editing && pillEdit) {
    return (
      <DatePicker
        className='w-fit ml-auto block mt-2 text-[#6B667B]'
        selected={todo.dueDate && new Date(todo.dueDate)}
        setSelected={async selected => {
          await updateDueDate(selected)
          setPillEdit(false)
          setLocked(false)
        }}
        open={open}
        setOpen={o => {
          setOpen(o)
          setPillEdit(o)
        }}
        optional
        ref={picker}
      />
    )
  }

  if (!editing && !dueDate && computedInTodoColumnId === 'later' && todoDateValue) {
    const { dueDate, dateString } = toDueDate(todoDateValue, true)

    return (
      <Pill
        dueDate={dueDate}
        dateString={dateString}
        {...longPressExemptProps()}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          setPillEdit(true)
          setOpen(true)
          setLocked(true)
        }}
        onPointerDown={event => {
          event.preventDefault()
          event.stopPropagation()
          setPillEdit(true)
          setOpen(true)
          setLocked(true)
        }}
        onMouseDown={event => {
          event.preventDefault()
          event.stopPropagation()
        }}
      />
    )
  }

  if (!editing && todo.dueDate) {
    const { dueDate, dateString } = toDueDate(todo.dueDate, true)

    return (
      <Pill
        dueDate={dueDate}
        dateString={dateString}
        {...longPressExemptProps()}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          setPillEdit(true)
          setOpen(true)
          setLocked(true)
        }}
        onPointerDown={event => {
          event.preventDefault()
          event.stopPropagation()
          setPillEdit(true)
          setOpen(true)
          setLocked(true)
        }}
        onMouseDown={event => {
          event.preventDefault()
          event.stopPropagation()
        }}
      />
    )
  }

  return null
}

export default DueDatePill
