import { useRef } from 'react'
import { useDayPicker, useDayRender } from 'react-day-picker'
import classNames from 'classnames'
import CustomDayContent from './CustomDayContent'
/**
 *
 * @param {import('react-day-picker').DayProps} param0
 */
const CustomDay = ({ date, displayMonth }) => {
  const ref = useRef()
  const reminderListContainerRef = useRef()
  const { onSelect } = useDayPicker()
  const dayRender = useDayRender(date, displayMonth, ref)
  const { selected, disabled, outside, hidden } = dayRender.activeModifiers

  if (outside || hidden) {
    return <></>
  }

  const props = dayRender.divProps

  return (
    <div
      {...props}
      onClick={e => {
        if (disabled || reminderListContainerRef.current.contains(e.target)) return
        onSelect(date)
      }}
      className={classNames(
        'relative group todo h-full py-2.5 px-2',
        !disabled && 'hover:bg-primary-200/40 focus:bg-primary-200/40',
        disabled && '!cursor-default',
        selected && '!bg-primary-200/40 z-20',
      )}
      role='gridcell'
      ref={ref}
    >
      <CustomDayContent
        ref={reminderListContainerRef}
        date={date}
        displayMonth={displayMonth}
        activeModifiers={dayRender.activeModifiers}
      />
    </div>
  )
}

export default CustomDay
