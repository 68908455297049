import { forwardRef } from 'react'
import { renderRtfFormat } from '../inputs/ContentEditableBox'

export const TodoText = forwardRef(({ todo, ...props }, ref) => {
  return (
    <span {...props} ref={ref}>
      {typeof todo.title === 'string'
        ? todo.title
        : todo?.title?.map((n, i) => renderRtfFormat(n, i))}
    </span>
  )
})
