import { useCallback } from 'react'
import { Editor, Range, Text } from 'slate'
import { Slate, Editable, useSlate } from 'slate-react'
import HoveringToolbar from './HoveringToolbar'
import { Element, Leaf, onKeyDown as slateOnKeyDown } from './slateApi'

const ContentEditableBox = ({
  onKeyUp,
  autoFocus,
  placeholder,
  editor,
  value,
  setValue,
  ...rest
}) => {
  return (
    <Slate editor={editor} value={value} onChange={setValue}>
      <EditableWithDecorate
        {...rest}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onKeyDown={event => {
          slateOnKeyDown(event, editor)
          if (
            (event.code === 'Enter' || event.code === 'NumpadEnter') &&
            !event.shiftKey
          ) {
            event.preventDefault()
          }
        }}
      />
      <HoveringToolbar />
    </Slate>
  )
}

/**
 *
 * @param {import('slate-react/dist/components/editable').EditableProps} props
 * @returns
 */
function EditableWithDecorate(props) {
  const editor = useSlate()

  const decorate = useCallback(
    ([node, path]) => {
      if (
        Text.isText(node) &&
        editor.selection != null &&
        Editor.string(editor, editor.selection) !== ''
      ) {
        const intersection = Range.intersection(
          editor.selection,
          Editor.range(editor, path),
        )

        if (intersection == null) {
          return []
        }

        const range = {
          selectionMarked: true,
          ...intersection,
        }

        return [range]
      }
      return []
    },
    [editor],
  )

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  return (
    <Editable
      {...props}
      decorate={decorate}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      style={{
        overflow: 'hidden',
        wordBreak: 'break-all',
      }}
    />
  )
}

export default ContentEditableBox
