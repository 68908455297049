import React from 'react'
import { useDayPicker } from 'react-day-picker'
import { HeadRow } from './HeadRow'

/** Render the table head. */
export function Head() {
  const { classNames, styles } = useDayPicker()
  return (
    <>
      <HeadRow />
    </>
  )
}
