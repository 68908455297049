import { useCallback, useState } from 'react'

/**
 * A utility hook that provides loading state,
 * and a function decorator to enable loading state updates
 * when the wrapped function is called.
 *
 * Please try to use this hook whenever state loading applies
 */
export default function useAsyncLoading() {
  const [loading, setLoading] = useState(false)

  const asyncLoading = useCallback(
    cb =>
      async (...args) => {
        let result
        try {
          setLoading(true)
          result = await cb(...args)
        } finally {
          setLoading(false)
        }
        return result
      },
    [],
  )

  return {
    loading,
    setLoading,
    asyncLoading,
  }
}
