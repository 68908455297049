export const Header = () => {
  return (
    <div className='text-center'>
      <h1 className='font-semibold text-4xl text-primary-700 mb-2'>
        Your guide to a career in tech
      </h1>
      <p className='text-text-70'>
        Setup to access mentors from Edlyft partner companies, technical interview prep,
        and support managing classes.
      </p>
    </div>
  )
}
