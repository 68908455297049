import { useResponsiveWindow } from '../../../hooks/useResponsiveWindow'
import { MobileDatePicker } from './MobileDatePicker'
import { DesktopDatePicker } from './DesktopDatePicker'
import { forwardRef, useState } from 'react'

export const DatePicker = forwardRef(
  (
    { disableBlurEvents, open: controlledOpen, setOpen: controlledSetOpen, ...props },
    ref,
  ) => {
    const { isMobile } = useResponsiveWindow()
    const [privateOpen, privateSetOpen] = useState(false)

    const open = typeof controlledOpen === 'boolean' ? controlledOpen : privateOpen
    const setOpen =
      typeof controlledSetOpen === 'function' ? controlledSetOpen : privateSetOpen

    const customSetOpen = openVarOrFn => {
      disableBlurEvents &&
        disableBlurEvents(
          typeof openVarOrFn === 'function' ? openVarOrFn(open) : openVarOrFn,
        )
      setOpen(openVarOrFn)
    }

    if (isMobile)
      return <MobileDatePicker {...props} open={open} setOpen={customSetOpen} ref={ref} />

    return <DesktopDatePicker {...props} open={open} setOpen={customSetOpen} ref={ref} />
  },
)
