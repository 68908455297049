import React from 'react'

import { ReactComponent as Attachment } from '../../assets/icons/attachment.svg'
import { ReactComponent as Check } from '../../assets/icons/check-circle.svg'
import { ReactComponent as Close } from '../../assets/icons/close.svg'

const STATUS_TEXT = {
  uploading: 'Uploading...',
  success: 'Uploaded',
  error: 'Failed',
}

const FileItem = ({ file, onDelete, onReUpload }) => {
  const { content, validationError, uploadStatus } = file

  const iconRender = () => {
    if (uploadStatus === 'success') {
      return <Check className='w-3.5 h-3.5 text-[#00C48C]' />
    }

    if (!uploadStatus || uploadStatus === 'error') {
      return (
        <button className='p-1 -mr-1 hover:bg-gray-200 rounded' onClick={onDelete}>
          <Close className='w-3.5 h-3.5 text-[#999999]' />
        </button>
      )
    }

    return <Attachment className='w-3.5 h-3.5 text-[#999999]' />
  }

  return (
    <li className='h-[52px] flex items-center justify-between px-4 rounded-lg bg-[#F9F9F9] border border-[#DFDFE1]'>
      <span className='font-display text-xs font-medium text-[#6B667B]'>
        {content.name}
      </span>

      <div className='flex items-center'>
        {(validationError || uploadStatus) && (
          <span className='font-display text-[10px] font-medium text-[#9592A1] mr-4 uppercase'>
            {validationError || STATUS_TEXT[uploadStatus]}
          </span>
        )}

        {uploadStatus === 'error' && (
          <button
            className='font-display text-[10px] font-medium text-primary p-1 mr-2 -ml-2 uppercase hover:bg-primary/5 rounded'
            onClick={onReUpload}
          >
            Try Again
          </button>
        )}

        {iconRender()}
      </div>
    </li>
  )
}

export { FileItem }
