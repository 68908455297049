import { useState, useEffect } from 'react'
import classNames from 'classnames'
import deepEqual from 'deep-equal'
import { Editor, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import useAuth from '../../hooks/useAuth'
import { useCreateTodoMutation, useTodos } from '../../providers/Todo'
import useTodoForm from '../../hooks/useTodoForm'
import { to0001 } from '../../utils/time'
import { DatePicker } from '../inputs/DatePicker'
import ContentEditableBox, {
  useContentEditableBoxEditor,
  RtfFormat,
} from '../inputs/ContentEditableBox'
import { DAY_IN_MS } from '../../constants'

const initialValue = [RtfFormat.paragraph([RtfFormat.text()])]

const TodoForm = ({
  formId,
  nextWeight,
  autoFocus,
  showBorder = false,
  isInsert = false,
  isOpen = true,
  closeForm,
}) => {
  const { userId } = useAuth()
  const { getLastWeight } = useTodos()
  const [error, setError] = useState()
  const editor = useContentEditableBoxEditor()
  const { isLoading, mutate, reset } = useCreateTodoMutation({
    mutationKey: ['createTodo', formId],
    onSuccess: () => {
      if (titleRef.current) titleRef.current.value = ''
      setError()
      setDueDate()
      reset()
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      })
    },
    onError: error => {
      setError({ message: 'Failed to create the todo. Please try again', error })
    },
  })
  const [value, setValue] = useState(initialValue)
  const beforeSubmit = event => {
    if ((event.code !== 'Enter' && event.code !== 'NumpadEnter') || event.shiftKey) return
    if (deepEqual(value, initialValue)) {
      if (!isInsert) return
      closeForm()
      return
    }
    return true
  }
  const beforeBlurSubmit = () => {
    if (deepEqual(value, initialValue)) {
      if (!isInsert) return
      closeForm()
      return
    }
    return true
  }
  const afterSubmit = () => {
    if (isInsert) {
      closeForm()
    }
  }
  const { dueDate, setDueDate, containerRef, titleRef, onSubmit, onBlur } = useTodoForm({
    afterSubmit,
    beforeBlurSubmit,
    beforeSubmit,
  })

  useEffect(() => {
    if (isInsert && isOpen) {
      ReactEditor.focus(editor)
    }
  }, [isInsert, isOpen, editor])

  const reminderInputSettings = {
    selected: dueDate,
    setSelected: setDueDate,
    optional: true,
  }

  async function postTodo({ date }) {
    const todoDate =
      formId === 'today'
        ? to0001().getTime()
        : formId === 'tomorrow'
        ? new Date(to0001().getTime() + DAY_IN_MS).getTime()
        : date
    const { lastIncrementedWeight } = getLastWeight(todoDate)
    const weight = nextWeight ? nextWeight : lastIncrementedWeight
    const title = value
    mutate({
      title,
      userId,
      todoDate,
      dueDate: date,
      weight,
    })
  }

  const borderClass = showBorder ? 'border-primary' : 'border-transparent'

  const formClass = classNames(
    'group/todo-form add-task-form px-5 py-3 rounded-xl text-[#6B667B] relative focus:z-50 focus-within:z-50',
    'hover:bg-white focus-within:bg-white',
    'hover:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)] focus-within:drop-shadow-[-2px_3px_4px_rgba(66,144,243,0.08)]',
    isInsert && borderClass,
  )

  return (
    <form
      className={formClass}
      onSubmit={onSubmit(postTodo)}
      disabled={isLoading}
      onBlur={onBlur(postTodo)}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='pl-6' ref={containerRef}>
        <input
          className='-ml-6 mr-1 w-[18px] h-[18px] align-middle'
          type='checkbox'
          disabled
          readOnly
        />
        <ContentEditableBox
          placeholder='Enter new to-do here'
          className='inline-block w-full outline-none bg-transparent resize-none align-text-top'
          onKeyUp={onSubmit(postTodo)}
          editor={editor}
          value={value}
          setValue={setValue}
          autoFocus={autoFocus}
        />
        {formId === 'later' && (
          <DatePicker
            className='w-fit ml-auto mt-2 hidden group-focus-within/todo-form:block group-focus/todo-form:block'
            {...reminderInputSettings}
          />
        )}
        {error && <p className='whitespace-normal text-red-500'>{error.message}</p>}
      </div>
    </form>
  )
}

export default TodoForm
