import classNames from 'classnames'
import React from 'react'
import { useDayPicker } from 'react-day-picker'
import { getWeekdays } from './utils/getWeekDays'

/**
 * Render the HeadRow component - i.e. the table head row with the weekday names.
 */
export function HeadRow() {
  const {
    locale,
    weekStartsOn,
    ISOWeek,
    formatters: { formatWeekdayName },
    labels: { labelWeekday },
  } = useDayPicker()

  const weekdays = getWeekdays(locale, weekStartsOn, ISOWeek)

  return (
    <>
      {weekdays.map((weekday, i) => (
        <div
          key={i}
          className='text-center text-text-700 py-2.5 px-2'
          aria-label={labelWeekday(weekday, { locale })}
        >
          {formatWeekdayName(weekday, { locale })}
        </div>
      ))}
    </>
  )
}
