import { authorizedApiFetch } from '.'
import ApiResponseError from '../errors/ApiResponseError'

/**
 * Creates a request to process the user metric operations.
 * This function will never throw an exception because we the user
 * will not care for error handling that involves their metrics
 *
 * @param {string} userId user id
 * @param {import('../metrics').__MetricOperation[]} operations the list of metric operations
 */
export const safePostMetrics = async (userId, operations) => {
  try {
    const response = await authorizedApiFetch('/api/metrics', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        operations,
      }),
    })
    const body = await response.json()
    if (!response.ok) throw new ApiResponseError({ response, body })
    console.log('Posted metrics', operations)
    if (body.unprocessedOperations.length > 0)
      console.warn('Unprocessed metrics', body.unprocessedOperations)
  } catch (err) {
    console.error(err)
  }
}
