import classNames from 'classnames'

export const Footer = ({ currentStep, goNext, goPrevious }) => {
  return (
    <div
      className={classNames(
        currentStep >= 1 && 'hidden xs:flex',
        'flex items-center justify-center xs:justify-between font-medium',
      )}
    >
      {currentStep > 1 ? (
        <button
          type='button'
          onClick={goPrevious}
          className='bg-gray-300/30 hover:bg-gray-300/60 active:bg-gray-300/90 text-text-700 w-[130px] h-[40px] rounded-md'
        >
          Previous
        </button>
      ) : (
        <div className='hidden opacity-0 xs:flex' />
      )}
      <button
        type='button'
        onClick={goNext}
        className='bg-primary-300/40 hover:bg-primary-300/60 active:bg-primary-300/80 text-primary-700 w-[330px] h-11 xs:w-[130px] xs:h-[40px] rounded-md'
      >
        Next
      </button>
    </div>
  )
}
