export const KEY_TODOS_TAB = 'todo'
export const KEY_CALENDAR_TAB = 'calendar'
export const KEY_CAREER_TAB = 'career'

export const TODOS_TAB = 'To-dos'
export const CALENDAR_TAB = 'Calendar'
export const CAREER_TAB = 'Career'
export const AGENDA_TAB = 'Agenda'

// Calendar Veiws
export const MONTH_VIEW = 'month'
export const AGENDA_VIEW = 'agenda'
export const REGULAR_VIEW = 'regular'

export const WEIGHT_INCREMENT = 1000

export const CAREER_PROBLEMS_DIFFICULTIES = {
  easy: 'EASY',
  medium: 'MEDIUM',
  hard: 'HARD',
}

export const INPUT_CHANGE_SAVE_REQUEST_TIMEOUT = 3000 // 3 seconds

export const USER_PREFERENCES = {
  IS_CALENDAR_SYNCED: 'isCalendarSynced',
  TEXT_WARNINGS_ENABLED: 'textWarningsEnabled',
}

export const DAY_IN_MS = 86_400_000

export const DISCORD_LINK = 'https://discord.com/invite/7D7tmvXKZS'
