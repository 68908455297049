import classNames from 'classnames'
import { format } from 'date-fns'
import { forwardRef, useState } from 'react'
import { useDayPicker } from 'react-day-picker'

import useCalendarTodos from '../../hooks/useCalendarTodos'
import { CalendarTodoForm } from '../Todo/CalendarView/CalendarTodoForm'
import { CalendarTodo } from '../Todo/CalendarView/CalendarTodo'

/**
 *
 * @param {import('react-day-picker').DayContentProps} param0
 */
const CustomDayContent = ({ activeModifiers, date }, ref) => {
  const { selected, today } = activeModifiers

  const { todosForDate } = useCalendarTodos(date)

  const { onSelect } = useDayPicker()
  const [locked, setLocked] = useState(false)

  return (
    <div className='space-y-1 h-full'>
      <div className='text-primary'>
        <div
          className={classNames(
            'text-text-700 text-right leading-4',
            today && '!text-white font-medium -mt-1.5',
          )}
        >
          <span
            className={classNames(
              'inline-flex items-center justify-center',
              today &&
                'rounded-full p-2 after:block after:h-0 after:w-full after:pb-[100%] bg-primary-dark',
            )}
          >
            <div className={classNames(today && 'overflow-visible h-0 -mt-4')}>
              {format(date, 'd')}
            </div>
          </span>
        </div>
      </div>
      <div className='space-y-1' ref={ref}>
        {todosForDate.map((todo, key) => (
          <CalendarTodo todo={todo} key={key} date={date} />
        ))}
      </div>
      {selected && (
        <CalendarTodoForm
          date={date}
          onClose={() => onSelect()}
          locked={locked}
          setLocked={setLocked}
        />
      )}
    </div>
  )
}

export default forwardRef(CustomDayContent)
