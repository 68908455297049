import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react'
import { useEffect, useRef, useState } from 'react'

import { ReactComponent as DownIcon } from '../../../assets/icons/down.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import classNames from 'classnames'

export const Select = ({
  isOpen,
  setIsOpen,
  selectedIndex,
  setSelectedIndex,
  options,
  placeholder = 'Select...',
  className,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const { x, y, strategy, refs, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            width: `${rects.reference.width}px`,
          })
        },
        padding: 10,
      }),
    ],
  })

  const listLabelRef = useRef(options)
  useEffect(() => {
    listLabelRef.current = options.map(({ label }) => label)
  }, [options])

  const listRef = useRef([])
  const isTypingRef = useRef(false)

  const click = useClick(context, { event: 'click' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
  })
  const typeahead = useTypeahead(context, {
    listRef: listLabelRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange: isTyping => {
      isTypingRef.current = isTyping
    },
    ignoreKeys: ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    dismiss,
    role,
    listNav,
    typeahead,
  ])

  const handleSelect = index => {
    setSelectedIndex(index)
    setIsOpen(false)
    refs.reference.current.focus()
  }

  const selectedItemLabel =
    selectedIndex !== null ? options[selectedIndex].label : undefined

  const finalClassName = classNames('!relative', className)

  return (
    <>
      <div
        tabIndex={0}
        ref={refs.setReference}
        aria-autocomplete='none'
        {...getReferenceProps({ ...props, className: finalClassName })}
      >
        {selectedItemLabel || placeholder}
        <DownIcon className='inline' />
        {selectedIndex !== null && (
          <button
            onClick={e => {
              setSelectedIndex(null)
              e.preventDefault()
              e.stopPropagation()
              refs.reference.current.focus()
            }}
            className='inline-block absolute -top-3 right-0 p-1 rounded-full bg-primary-300 text-primary-700'
          >
            <CloseIcon className='w-2' title='close button' />
          </button>
        )}
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false} initialFocus={-1}>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
            className='overflow-y-auto py-1 rounded-lg outline-0
              bg-white drop-shadow-[-2px_4px_5px_rgba(1,53,144,0.09)]
              text-black'
          >
            {options.map(({ value, label }, i) => (
              <div
                id={`${value}-${i}`}
                key={value}
                ref={node => {
                  listRef.current[i] = node
                }}
                role='option'
                tabIndex={i === activeIndex ? 0 : -1}
                aria-selected={i === selectedIndex && i === activeIndex}
                {...getItemProps({
                  // Handle pointer select.
                  onClick() {
                    handleSelect(i)
                  },
                  // Handle keyboard select.
                  onKeyDown(event) {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                      handleSelect(i)
                    }

                    if (event.key === ' ' && !isTypingRef.current) {
                      event.preventDefault()
                    }
                  },
                  onKeyUp(event) {
                    if (event.key === ' ' && !isTypingRef.current) {
                      handleSelect(i)
                    }
                  },
                })}
                className={classNames(
                  'px-2.5 py-1.5 cursor-default',
                  i === activeIndex && '!bg-primary-dark/20',
                )}
              >
                {label}
              </div>
            ))}
          </div>
        </FloatingFocusManager>
      )}
    </>
  )
}
