import classNames from 'classnames'
import { DUE_DATE, DUE_DATE_LABELS } from '../../../utils/date'

export const Pill = ({ dueDate, dateString, ...rest }) => {
  return (
    <div
      {...rest}
      className={classNames(
        'w-fit ml-auto text-[10px] leading-3 font-medium tracking-tight rounded-full py-1 px-3',
        dueDate === DUE_DATE.OVERDUE && 'text-white bg-primary-red',
        dueDate === DUE_DATE.TODAY && 'text-primary-200 bg-primary-700',
        dueDate !== DUE_DATE.OVERDUE &&
          dueDate !== DUE_DATE.TODAY &&
          'text-primary-700 bg-primary-200',
      )}
    >
      {DUE_DATE_LABELS[dueDate] || dateString}
    </div>
  )
}
