import classNames from 'classnames'
import { Select } from '../common/Select'

export const StartDateSelect = ({ dueDate, ...selectProps }) => {
  if (!dueDate) return null

  const { isOpen, selectedIndex } = selectProps

  return (
    <div
      className={classNames(
        'z-10 text-xs text-text-700 hidden group-hover/updateTodo:block group-focus-visible/updateTodo:block group-focus-within/updateTodo:block',
        isOpen && '!block',
      )}
    >
      Start
      <Select
        {...selectProps}
        placeholder='Select start date'
        className={classNames(
          'cursor-pointer min-w-[100px] inline-flex justify-center gap-1 px-2 py-1 rounded-full mx-1 bg-text-200 border hover:border-text-300',
          isOpen &&
            'bg-gradient-to-r from-primary-600/10 to-primary-600/10 !bg-white !border-primary-dark',
          selectedIndex !== null &&
            'bg-primary-300/30 text-primary-700 font-medium !border-transparent',
        )}
      />
      before due date
    </div>
  )
}
