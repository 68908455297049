import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

export const MobileMenuTabs = ({ isSelected, setIsSelected, setIsOpen, tabs }) => {
  const navigate = useNavigate()

  return Object.keys(tabs).map((tab, i) => {
    const { label, pathUrl } = tabs[tab]
    return (
      <div
        key={i}
        className={classNames(
          'text-base cursor-pointer px-2 py-4 active:bg-primary-600/10',
          isSelected === tab && '!font-bold',
        )}
        onClick={() => {
          setIsSelected(tab)
          if (pathUrl) navigate(pathUrl)
          setIsOpen(false)
        }}
      >
        {label}
      </div>
    )
  })
}
