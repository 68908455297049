import React, { createContext, useCallback, useState, useContext } from 'react'
import { Provider as ToastPrimitiveProvider } from '@radix-ui/react-toast'
import { nanoid } from 'nanoid'
import { MemoizedToastViewport, Toast } from '../components/common/Toast'

const ToastsContext = createContext(null)

const ToastsProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const addToast = useCallback(toast => {
    setToasts(toasts => [...toasts, { id: nanoid(), ...toast }])
  }, [])

  const removeToast = useCallback(id => {
    setToasts(toasts => toasts.filter(toast => toast.id !== id))
  }, [])

  return (
    <ToastsContext.Provider value={addToast}>
      <ToastPrimitiveProvider>
        {children}

        {toasts.map(toast => {
          const { id, ...rest } = toast
          const toastProps = { ...rest, onClose: () => removeToast(id) }
          return <Toast key={id} {...toastProps} />
        })}

        <MemoizedToastViewport />
      </ToastPrimitiveProvider>
    </ToastsContext.Provider>
  )
}

const useToasts = () => {
  return useContext(ToastsContext)
}

export { ToastsProvider, useToasts }
