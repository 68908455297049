import classNames from 'classnames'
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'
import { useId } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DatePicker } from '../../components/inputs/DatePicker'
import { useStepperForm } from '../../components/StepperForm/StepperForm'
import { bracketsAreBalanced } from '../../utils/functions'

import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg'
import { ReactComponent as DownIcon } from '../../assets/icons/down.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { Header } from './Header'
import { Steps } from '../../components/StepperForm'
import { countryCode } from '../../utils/getCountryCode'

/**
 * @type {import('react-hook-form').RegisterOptions}
 */
const stringOpts = {
  setValueAs: value => String(value).trim(),
}

export const GoalsContent = () => {
  const {
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useFormContext()
  const stepper = useStepperForm()

  const reminderTitleId = `reminderTitle${useId()}`
  const dueDateId = `dueDate${useId()}`
  const phoneNumberId = `phoneNumber${useId()}`

  const phoneNumber = watch('phoneNumber', '')
  const reminderTitle = watch('todo.title', undefined)
  const reminderDate = watch('todo.dueDate', undefined)

  const requireReminderData = !!reminderTitle || !!reminderDate

  /**
   * @type {import('react-hook-form').RegisterOptions}
   */
  const phoneNumberOpts = {
    setValueAs: value => {
      const typer = new AsYouType(countryCode)
      const output = typer.input(value)

      if (value.length !== output.length && !bracketsAreBalanced(value))
        return typer.getChars()

      return output
    },
    validate: async number => {
      if (!number) return true
      const result = parsePhoneNumber(number, countryCode)
      return result?.isValid()
    },
  }

  return (
    <>
      <div className='xs:hidden flex justify-between gap-2.5 text-base'>
        <button
          type='button'
          onClick={stepper.goToPrevStep}
          className='flex items-center gap-2.5 -mx-3 p-3 text-center text-text-600 hover:text-text-700 font-light leading-[120%] rounded-md'
        >
          <LeftIcon className='h-2.5' />
          Back
        </button>
      </div>
      <div className='max-xs:hidden'>
        <Header />
      </div>
      <div className='max-xs:mx-8'>
        <Steps />
      </div>
      <div className='grid grid-rows-1 grid-cols-2 gap-6'>
        <div className='col-span-full'>
          <h2 className='font-semibold text-2xl mb-1'>
            What’s one major event this term that you need to plan for and can’t forget?{' '}
            <span className='text-[#C0BEC7]'>(optional)</span>
          </h2>
          <p className='text-[#333333]'>See what Edlyft can do!</p>
        </div>
        <div className='col-span-full'>
          <label htmlFor={reminderTitleId}>Event name</label>
          <input
            id={reminderTitleId}
            disabled={isSubmitting}
            placeholder='CS50 midterm'
            className={classNames(
              'block w-full rounded-md outline outline-1 outline-transparent focus-visible:outline-primary-200 p-3.5',
              errors.reminder?.title && '!outline-red-500',
            )}
            {...register('todo.title', {
              ...stringOpts,
              required: requireReminderData,
            })}
          />
        </div>
        <div className='col-span-full'>
          <label htmlFor={dueDateId}>What day is it on?</label>
          <Controller
            name='todo.dueDate'
            control={control}
            rules={{ required: requireReminderData }}
            render={({
              field: { onChange, value },
              fieldState: { error },
              formState: { isSubmitting },
            }) => {
              const renderButtonContent = () => {
                const formattedDate = (value || new Date()).toLocaleDateString(
                  undefined,
                  {
                    month: 'long',
                    year: 'numeric',
                    day: 'numeric',
                  },
                )
                return (
                  <div className='inline-flex gap-2.5 items-center'>
                    {formattedDate}
                    {value && (
                      <button
                        disabled={isSubmitting}
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          onChange()
                        }}
                      >
                        <CloseIcon className='w-5' />
                      </button>
                    )}
                    {!value && <DownIcon className='w-5' />}
                  </div>
                )
              }

              return (
                <DatePicker
                  selected={value}
                  setSelected={onChange}
                  disabled={isSubmitting}
                  buttonClassName={classNames(
                    'block min-w-[10rem] rounded-md outline outline-1 outline-transparent focus-visible:outline-primary-200 p-3.5 bg-white text-text-500',
                    error && '!outline-red-500',
                  )}
                  renderButtonContent={renderButtonContent}
                />
              )
            }}
          />
        </div>
        <div className='col-span-full space-y-2.5'>
          {!!reminderTitle && !!reminderDate && (
            <p className='font-medium'>
              Want a reminder text for{' '}
              <span className='text-primary-600'>{reminderTitle}</span> right before{' '}
              <span className='text-primary-600'>
                {reminderDate.toLocaleDateString(undefined, {
                  month: 'long',
                  year: 'numeric',
                  day: 'numeric',
                })}
              </span>
              ?
            </p>
          )}
          <div>
            <label htmlFor={phoneNumberId}>Mobile phone number</label>
            <input
              id={phoneNumberId}
              value={phoneNumber}
              placeholder='+1 (000) 000-0000'
              aria-invalid={!!errors.phoneNumber}
              disabled={isSubmitting}
              className={classNames(
                'block w-full rounded-md outline outline-1 outline-transparent focus-visible:outline-primary-200 p-3.5',
                errors.phoneNumber && '!outline-red-500',
              )}
              {...register('phoneNumber', phoneNumberOpts)}
            />
          </div>
          <p className='text-xs text-text-500'>
            We'll send you friendly reminders about your upcoming deadlines. By providing
            you cell number, you agree and acknowledge that Edlyft may send text messages
            to your cell. You can turn them off at anytime.
          </p>
        </div>
        {/* desktop button */}
        <div className='hidden xs:flex col-span-full gap-2.5'>
          <button
            type='button'
            disabled={isSubmitting}
            onClick={stepper.goToPrevStep}
            className='self-start flex items-center gap-2.5 p-3 text-center w-10 md:w-20 lg:w-40 text-primary-600 focus-visible:bg-primary-200 font-medium leading-[120%] rounded-md'
          >
            <LeftIcon className='w-2.5' />
            Previous
          </button>
          <div className='grow' />
          <button
            type='submit'
            disabled={isSubmitting}
            className='p-3 text-center min-w-10 md:min-w-20 lg:min-w-40 bg-primary-300 disabled:text-black disabled:bg-neutral-400 focus-visible:bg-primary-200 hover:bg-primary-200 font-medium leading-[120%] text-primary-700 rounded-md'
          >
            Launch Your Tech Career
          </button>
        </div>
      </div>
      {/* mobile sticky button */}
      <div className='hidden max-xs:block mt-auto sticky bottom-0 left-0 pt-4 pb-12 bg-white-bg w-full'>
        <button
          type='submit'
          className='max-xs:w-full p-3 text-center bg-primary-300 disabled:text-black disabled:bg-neutral-400 focus-visible:bg-primary-200 hover:bg-primary-200 font-medium leading-[120%] text-primary-700 rounded-md'
        >
          Launch Your Tech Career
        </button>
      </div>
    </>
  )
}
