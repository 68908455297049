import { Auth } from 'aws-amplify'

function intToHex(nr) {
  return nr.toString(16).padStart(2, '0')
}

function getRandomString(bytes) {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join('')
}

export async function register({ email }) {
  const params = {
    username: email,
    password: getRandomString(30),
    attributes: {
      name: email,
    },
  }
  const results = await Auth.signUp(params)
  return results
}

/**
 *
 * Login will always result in a cognito user being returned.
 * HOWEVER they are not authenticated yet. Before the user's JWTs
 * can be accessed, they need to answer the {@link answerCustomChallenge custom challenge}
 *
 * @returns {import("amazon-cognito-identity-js").CognitoUser}
 */
export async function login(email) {
  const user = await Auth.signIn(email)
  return user
}

export async function signOut() {
  return await Auth.signOut()
}

/**
 * The client must input the magic code that was sent to their
 * email address in order to be authenticated and make authorized api requests
 *
 * @returns {import("amazon-cognito-identity-js").CognitoUser}
 */
export async function answerCustomChallenge(user, answer) {
  // Send the answer to the User Pool
  // This will throw an error if it’s the 3rd wrong answer
  const newUser = await Auth.sendCustomChallengeAnswer(user, answer)

  // It we get here, the answer was sent successfully,
  // but it might have been wrong (1st or 2nd time)
  // So we should test if the user is authenticated now
  try {
    // This will throw an error if the user is not yet authenticated:
    await Auth.currentSession()
    return newUser
  } catch {
    throw Error('Incorrect code, please try again')
  }
}
