import { Editor, Element as SlateElement, Node, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import {
  insertLink,
  insertPastedLinks,
  isLink,
  isLinkActive,
  ANCHOR,
  onKeyDown,
  unwrapLink,
  wrapLink,
} from './utils'

/**
 * @template {ReactEditor} E
 *
 * @param {E} editor
 * @returns {E}
 */

const withLinkify = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element =>
    SlateElement.isElementType(element, ANCHOR) || isInline(element)

  editor.insertText = text => {
    if (text && isLink(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    // this is for pasting links, but we ignore it if snippet contains html.
    // that's the work for the other plugin that must deserialize `html` by other
    // way and both plugins must work in conjunction to cover all pasting cases
    if (!data.types.includes('text/html') && text && isLink(text)) {
      insertPastedLinks(data, insertData)
    } else {
      insertData(data)
    }
  }

  return editor
}

/**
 * Applies the custom rules for hyperlinking
 * @template {ReactEditor} E
 *
 * @param {E} editor
 * @returns {E}
 */
export function withCustomEditor(editor) {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    // If the element is a paragraph, ensure its children are valid.
    if (SlateElement.isElement(node) && node.type === 'paragraph') {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (SlateElement.isElement(child) && !editor.isInline(child)) {
          Transforms.unwrapNodes(editor, { at: childPath })
          return
        }
      }
    }
    if (SlateElement.isElement(node) && SlateElement.isElementType(node, ANCHOR)) {
      if (Editor.string(editor, path) === '') {
        Transforms.removeNodes(editor, { at: path })
        return
      }
      for (const [childNode, childPath] of Node.descendants(node)) {
        if (
          SlateElement.isElement(childNode) &&
          SlateElement.isElementType(childNode, ANCHOR)
        ) {
          const at = [...path, ...childPath]
          // the parent and child are both anchors
          if (node.href === childNode.href) {
            // we can unwrap the node to ansure anchor validity
            // Warning: validateDOMNesting(...): <a> cannot appear as a descendant of <a>
            Transforms.unwrapNodes(editor, { at })
            return
          }
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return withLinkify(editor)
}

export { insertLink, isLinkActive, onKeyDown, unwrapLink }

export const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case ANCHOR:
      return (
        <a
          {...attributes}
          href={element.href}
          target='_blank'
          rel='noreferrer'
          className='text-primary-600 hover:underline'
          onClick={e => e.stopPropagation()}
        >
          {children}
        </a>
      )

    default:
      return <span {...attributes}>{children}</span>
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.selectionMarked) {
    children = <span className='bg-primary-700 bg-opacity-10'>{children}</span>
  }

  return <span {...attributes}>{children}</span>
}

export function renderRtfFormat(slateState, key = 0) {
  if (slateState.text !== undefined)
    return (
      <Leaf key={key} leaf={slateState}>
        {slateState.text}
      </Leaf>
    )

  const renderedChildren =
    slateState.children &&
    slateState.children.map((n, index) => renderRtfFormat(n, index))

  return (
    <Element key={key} element={slateState}>
      {renderedChildren}
    </Element>
  )
}

export const serializeNodes = nodes => {
  return nodes.map(n => Node.string(n)).join('\n')
}
