import { useEffect, useState } from 'react'
import { useDayPicker, useFocusContext, useNavigation } from 'react-day-picker'
import { Month } from './Month'

/**
 * Render the container with the months according to the number of months to display.
 */
export function Root() {
  const dayPicker = useDayPicker()
  const focusContext = useFocusContext()
  const navigation = useNavigation()

  const [hasInitialFocus, setHasInitialFocus] = useState(false)

  // Focus the focus target when initialFocus is passed in
  useEffect(() => {
    if (!dayPicker.initialFocus) return
    if (!focusContext.focusTarget) return
    if (hasInitialFocus) return

    focusContext.focus(focusContext.focusTarget)
    setHasInitialFocus(true)
  }, [
    dayPicker.initialFocus,
    hasInitialFocus,
    focusContext.focus,
    focusContext.focusTarget,
    focusContext,
  ])

  return (
    <div dir={dayPicker.dir} className='font-display text-black h-full'>
      {navigation.displayMonths.map((month, i) => (
        <Month key={i} displayIndex={i} displayMonth={month} />
      ))}
    </div>
  )
}
