import React from 'react'

import { ReactComponent as TextReminder } from '../../assets/images/text-reminder.svg'

const FileUpload = ({ handleFileUpload }) => {
  return (
    <div className='flex flex-col items-center text-center gap-4 mb-12'>
      <TextReminder className='w-[72px] h-[72px]' />

      <p className='font-display font-medium text-2xl'>
        Import your deadlines from all of your courses and get a text when it's soon.
      </p>

      <small className='font-display font-normal text-sm text-[#9592A1]'>
        Add screenshots, docs or PDFs of all of your test dates, project due dates, etc
      </small>

      <button
        className='font-medium text-sm text-white bg-primary px-10 py-2.5 rounded-xl'
        onClick={handleFileUpload}
      >
        Try it out
      </button>
    </div>
  )
}

export { FileUpload }
