import { useEffect, useRef, useState } from 'react'
import { Navigate, Outlet, useMatches, useNavigate } from 'react-router-dom'
import CareerTabs from '../components/Tabs/CareerTabs'
import TodoTabs from '../components/Tabs/TodoTabs'
import { CAREER_TAB, KEY_CAREER_TAB, KEY_TODOS_TAB, TODOS_TAB } from '../constants'
import useAuth from '../hooks/useAuth'
import { TodoProvider } from '../providers/Todo'
import { FileUploader } from '../components/FileUploader'
import { PromoPopup } from '../components/PromoPopup'
import { useNavbarTabLayout, useNavbarTabs } from '../providers/LayoutTabProvider'

const tabs = {
  [KEY_TODOS_TAB]: {
    label: TODOS_TAB,
    Component: TodoTabs,
    pathUrl: '/home',
  },
  [KEY_CAREER_TAB]: {
    label: CAREER_TAB,
    Component: CareerTabs,
    pathUrl: '/career',
  },
}

const Dashboard = () => {
  const auth = useAuth()
  const { setIsSelected } = useNavbarTabLayout()
  const tabSetOnceRef = useRef(false)
  useNavbarTabs(tabs)

  const matches = useMatches()
  const navigate = useNavigate()
  const currentTabId = matches.find(match => match.handle?.tabId)?.handle.tabId ?? null

  useEffect(() => {
    if (tabSetOnceRef.current || !currentTabId) return

    setIsSelected(currentTabId)
    if (currentTabId) navigate(tabs[currentTabId].pathUrl)
    tabSetOnceRef.current = true
  }, [currentTabId])

  useEffect(() => {
    if (!auth.initialized || tabSetOnceRef.current) return
    // once we initialized the auth state, set the dashboard tab
    // to their default tab
    const { defaultTab = KEY_TODOS_TAB } = auth.user.preferences
    const newSelectedTab = tabs[defaultTab] ? defaultTab : KEY_TODOS_TAB

    setIsSelected(newSelectedTab)
    navigate(tabs[newSelectedTab].pathUrl)
    tabSetOnceRef.current = true
  }, [auth.initialized, auth.user])

  const [showPromoModal, setShowPromoModal] = useState(false)
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false)

  useEffect(() => {
    if (!shouldShowPromoModal()) return

    setTimeout(() => {
      setShowPromoModal(true)
    }, 600)
  }, [])

  if (auth.loadAirtable) {
    return <Navigate to='/onboarding' />
  }

  return (
    <TodoProvider>
      <div className='flex h-full'>
        {/* don't render the child route until we have auth fully configured */}
        {auth.initialized && auth.user && <Outlet />}
      </div>
      <PromoPopup
        show={showPromoModal}
        close={() => setShowPromoModal(false)}
        openFileUploader={() => setShowFileUploadDialog(true)}
      />

      <FileUploader
        show={showFileUploadDialog}
        close={() => setShowFileUploadDialog(false)}
      />
    </TodoProvider>
  )
}

function shouldShowPromoModal() {
  const today = Date.now()
  const promo = localStorage.getItem('eot_promo')

  // if no promo object in local storage, popup has never been shown and user hasn't used file uploader
  // show promo popup and start tracking promo popup display status
  if (!promo) {
    const promoObj = {
      lastDate: today,
      count: 1,
    }
    localStorage.setItem('eot_promo', JSON.stringify(promoObj))
    return true
  }

  // if promo object exists, it means either: 1) user has used file uploader, or 2) user has seen promo popup
  const promoObj = JSON.parse(promo)
  const { fileUploaded, lastDate, count } = promoObj

  // if user hasn't seen promo popup, show it and start tracking display status
  if (!lastDate || !count) {
    promoObj.lastDate = today
    promoObj.count = 1
    localStorage.setItem('eot_promo', JSON.stringify(promoObj))
    return true
  }

  // if user has used file uploader, don't show popup
  if (fileUploaded) return false

  // otherwise, if it's been shown in the last 5 days and if we showed it 5 times already, don't show popup
  const isLastShownMoreThanFiveDaysAgo = today - lastDate >= 1000 * 60 * 60 * 24 * 5
  const isCountLessThanFive = count < 5

  if (isLastShownMoreThanFiveDaysAgo && isCountLessThanFive) {
    promoObj.lastDate = today
    promoObj.count += 1
    localStorage.setItem('eot_promo', JSON.stringify(promoObj))
    return true
  }

  return false
}

export default Dashboard
