function paragraph(children = []) {
  return {
    type: 'paragraph',
    children,
  }
}

function anchor(href, children = []) {
  return {
    type: 'anchor',
    href,
    children,
  }
}

function text(text = '') {
  return {
    text,
  }
}

const RtfFormat = {
  paragraph,
  anchor,
  text,
}

export { RtfFormat }
