import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import classNames from 'classnames'
import { useState } from 'react'
import { renderRtfFormat } from '../../inputs/ContentEditableBox'

import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-empty.svg'
import { CalendarTodoForm } from './CalendarTodoForm'
import { useUpdateTodoMutation } from '../../../providers/Todo'

export const CalendarTodo = ({ todo, date }) => {
  const [open, setOpen] = useState(false)
  const [editing, setEditing] = useState(false)

  const onOpenChange = o => {
    if (!o && editing) setEditing(false)
    setOpen(o)
  }

  const { strategy, x, y, context, refs } = useFloating({
    open,
    onOpenChange,
    placement: 'right-end',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [offset({ mainAxis: -48, crossAxis: -12 }), flip(), shift()],
  })

  /**
   * Interactions need to be disabled when the api call is being made,
   * because if the form is unmounted while running the api request, the
   * app will be left in an undesirable data state.
   */
  const [interactionsLocked, setInteractionsLocked] = useState(false)
  const { isLoading, error, mutateAsync, reset } = useUpdateTodoMutation()

  const role = useRole(context)
  const click = useClick(context, { enabled: !interactionsLocked || isLoading })
  const dismiss = useDismiss(context, { enabled: !interactionsLocked || isLoading })

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

  const deleteTodo = async () => {
    const updatedTodo = { ...todo, isDeleted: true }
    try {
      await mutateAsync(updatedTodo)
      reset()
      setOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div
        tabIndex={0}
        ref={refs.setReference}
        {...getReferenceProps()}
        className={classNames(
          'rounded bg-white text-xs p-1 mb-0.5 text-black truncate cursor-pointer',
          'hover:drop-shadow-[-2px_3px_4px_rgba(66,_144,_243,_0.08)]',
          open && 'drop-shadow-[-2px_3px_4px_rgba(66,_144,_243,_0.08)]',
        )}
      >
        {typeof todo.title === 'string' ? (
          <span dangerouslySetInnerHTML={{ __html: todo.title }} />
        ) : (
          <span>{todo.title.map((n, i) => renderRtfFormat(n, i))}</span>
        )}
      </div>
      {open && (
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            left: x ?? 0,
            top: y ?? 0,
          }}
          {...getFloatingProps()}
          className='z-30 max-w-[250px] rounded bg-white p-2.5
            drop-shadow-[-2px_3px_4px_rgba(66,_144,_243,_0.15)]'
        >
          <div className='flex gap-2'>
            {editing && (
              <div className='min-w-[150px]'>
                <CalendarTodoForm
                  onClose={() => {
                    setEditing(false)
                    setOpen(false)
                  }}
                  todo={todo}
                  date={date}
                  locked={interactionsLocked}
                  setLocked={setInteractionsLocked}
                />
              </div>
            )}
            {!editing && (
              <>
                <div
                  tabIndex={0}
                  onKeyUp={e => {
                    if (e.code !== 'Enter' && e.code !== 'NumpadEnter') return
                    !interactionsLocked && !isLoading && setEditing(true)
                  }}
                  onClick={() => !interactionsLocked && !isLoading && setEditing(true)}
                  title='Click or hit enter to edit todo'
                >
                  {typeof todo.title === 'string' ? (
                    <span dangerouslySetInnerHTML={{ __html: todo.title }} />
                  ) : (
                    <span>{todo.title.map((n, i) => renderRtfFormat(n, i))}</span>
                  )}
                </div>
                {!isLoading && (
                  <button
                    className='self-baseline block pb-0.5 px-1.5 rounded-md
                    text-[#6B667B]
                    enabled:hover:bg-neutral-300
                    enabled:hover:text-[#625a7a]'
                    onClick={deleteTodo}
                    disabled={interactionsLocked || isLoading}
                  >
                    <TrashIcon
                      className='inline-block align-middle w-2.5'
                      title='delete icon'
                    />
                  </button>
                )}
                {isLoading && (
                  <div className='inline-block animate-spin w-5 h-5 border-2 border-transparent bg-white border-t-current rounded-full' />
                )}
              </>
            )}
          </div>
          {error && (
            <p className='w-full text-xs text-red-500'>
              Failed to update the todo. Please try again
            </p>
          )}
        </div>
      )}
    </div>
  )
}
