import { useToasts } from '../context/ToastsContext'
import useAsyncLoading from './useAsyncLoading'
import useAuth from './useAuth'

export const useToggleBooleanUserPreference = () => {
  const { loading, asyncLoading } = useAsyncLoading()
  const { user, updateUser } = useAuth()
  const addToast = useToasts()

  const toggleBooleanUserPreference = asyncLoading(async name => {
    try {
      const { preferences, ...userProps } = user
      const toggledPreference = !preferences[name]
      const newPreferences = { ...preferences, [name]: toggledPreference }
      const updatedUser = { ...userProps, preferences: newPreferences }
      await updateUser(updatedUser)
    } catch (err) {
      console.error(err)

      const parsedErrMsg = err?.message?.charAt(0).toUpperCase() + err?.message?.slice(1)

      addToast({
        description: parsedErrMsg || 'An unknown error occurred, please contact us!',
        variant: 'error',
        isClosable: true,
      })
    }
  })

  return {
    toggleBooleanUserPreference,
    loading,
  }
}
