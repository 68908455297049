import classNames from 'classnames'
import {
  FloatingArrow,
  useFloating,
  offset,
  arrow,
  flip,
  shift,
  autoUpdate,
  useHover,
  useInteractions,
  safePolygon,
} from '@floating-ui/react'
import { useRef, useState } from 'react'
import { useClickDelayedClosing } from './utils'
import { Highlighter } from '../../Highlighter'

const ARROW_HEIGHT = 12
const TOOLTIP_GAP = 9

export const TooltipButton =
  /**
   *
   * @param {import('react').DetailedHTMLProps<import('react').ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} props
   */
  ({
    className,
    children,
    tooltipText,
    active = false,
    id,
    delayClosing = true,
    ...referenceProps
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [timeoutId, setTimeoutId] = useState()

    const arrowRef = useRef()
    const { x, y, reference, floating, strategy, context } = useFloating({
      placement: 'bottom',
      open: isOpen,
      onOpenChange: setIsOpen,
      middleware: [
        arrow({ element: arrowRef }),
        offset(TOOLTIP_GAP + ARROW_HEIGHT),
        flip(),
        shift(),
      ],
      strategy: 'fixed',
      whileElementsMounted: autoUpdate,
    })

    const delayedClosing = useClickDelayedClosing(context, {
      timeoutId,
      setTimeoutId,
      referenceProps,
      enabled: !!tooltipText,
      time: delayClosing ? 10000 : 0,
    })
    const hover = useHover(context, {
      enabled: !timeoutId && !!tooltipText,
      handleClose: safePolygon(),
    })
    const { getReferenceProps, getFloatingProps } = useInteractions([
      hover,
      delayedClosing,
    ])

    return (
      <Highlighter id={id}>
        <button
          ref={reference}
          className={classNames(
            className,
            '!w-10 !h-10 !rounded-full !bg-white-bg-light !border !transition',
            !active && '!drop-shadow-custom hover:!drop-shadow-custom-lg',
            active && 'border-text-green !drop-shadow-custom-md',
          )}
          {...getReferenceProps()}
        >
          {children}
        </button>
        {isOpen && (
          <div
            ref={floating}
            className='z-40 max-w-xxs text-center py-5 px-6 bg-primary-700 rounded-xl text-white'
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            <FloatingArrow
              className='fill-primary-700'
              width={24}
              height={ARROW_HEIGHT}
              ref={arrowRef}
              context={context}
            />
            <span className='text-sm'>{tooltipText}</span>
          </div>
        )}
      </Highlighter>
    )
  }
