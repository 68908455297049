import { createContext } from 'react'

export const STATE_NOT_AUTHENTICATED = 'auth:no-auth'
export const STATE_REGISTERING = 'auth:registering'
export const STATE_LOGGING_IN = 'auth:login'
export const STATE_AUTHENTICATED = 'auth:authenticated'

const AuthContext = createContext({
  state: STATE_NOT_AUTHENTICATED,
  userId: undefined,
  user: undefined,
  cognitoUser: undefined,
  isAuthenticated: false,
  loadAirtable: false,
  initialized: false,
  error: undefined,
  register: async email => {},
  signIn: async email => {},
  answerChallenge: async code => {},
  signOut: async () => {},
  setLoadAirtable: loadAirtable => {},
  refreshUser: async () => {},
  updateUser: async (userData, { onboard = false } = {}) => {},
  trackCareerProgress: async ({ userId, section, value }) => {},
  completeGuidedTour: async ({ userId }) => {},
})

export default AuthContext
