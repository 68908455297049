import classNames from 'classnames'
import { useRef } from 'react'
import { forwardRef, useEffect, useState } from 'react'

export const CloseableWrapper = forwardRef(
  ({ closed, onFinish, children, ...props }, ref) => {
    const [finished, setFinished] = useState(false)
    const animated = useRef()
    useEffect(() => {
      if (!closed) setFinished(false)
    }, [closed])

    return (
      <div className='flex w-full' ref={ref}>
        <div className='w-full'>
          <div
            {...props}
            ref={animated}
            className={classNames(
              'w-full max-h-full',
              closed && 'animate-height-close overflow-hidden',
              finished && ' !max-h-0 overflow-hidden',
            )}
            onAnimationEnd={event => {
              const { target } = event
              if (animated.current !== target) return

              setFinished(true)
              onFinish && onFinish()
            }}
          >
            {children}
          </div>
        </div>
      </div>
    )
  },
)
