import React from 'react'
import { ReactComponent as LeftIcon } from '../../../assets/icons/left.svg'
import { ReactComponent as RightIcon } from '../../../assets/icons/right.svg'

const MonthView = props => {
  // Return the month view
  return (
    <div className='flex justify-between items-center w-auto'>
      {props.canGoHome && (
        <button
          className='text-primary	flex enabled:hover:bg-primary-700/20'
          onClick={() => props.goToCurrentMonth()}
        >
          <LeftIcon className='ml-0 p-1 h-6' />
          <LeftIcon className='-ml-4 mr-0 p-1 h-6' />
        </button>
      )}
      {!props.canGoHome && (
        <button
          disabled={!props.canGoPrev}
          className='text-primary disabled:text-gray-400 enabled:hover:bg-primary-700/20'
          onClick={() => props.goToPreviousMonth()}
        >
          <LeftIcon className='p-1 h-6' />
        </button>
      )}
      <h1 className='m-2 flex items-center gap-4 text-2xl font-bold'>
        {props.date.toLocaleString('default', { month: 'long', year: 'numeric' })}
      </h1>
      <button
        disabled={!props.canGoNext}
        className='text-primary disabled:text-gray-400 enabled:hover:bg-primary-700/20'
        onClick={() => props.goToNextMonth()}
      >
        <RightIcon className='p-1 h-6' />
      </button>
    </div>
  )
}

export default MonthView
