import { useEffect } from 'react'
import { useHighlighting } from '../../../providers/HighlightingProvider'
import { Overlay } from '../../common/Overlay'

export const AddDueDates = ({ onClose, goPrevious }) => {
  const { highlightElement, resetHighlighting } = useHighlighting()

  const closeGuide = event => {
    event.preventDefault()

    resetHighlighting()
    onClose()
  }

  const component = (
    <div className='relative hidden xs:flex flex-col gap-[10px] bg-primary-700 font-display text-white-bg p-[10px] w-[340px] h-[160px] rounded-[10px] bottom-[95%] right-[110%]'>
      <p className='text-xs'>4/4</p>
      <div className='flex flex-col items-center px-[45px]'>
        <p className='text-sm'>
          Adding a due date to your todo will ensure you get a text reminder and it’s
          visible in your Google calendar
        </p>
      </div>
      <div className='flex justify-between items-center'>
        <button
          type='button'
          onClick={goPrevious}
          className='bg-none hover:text-primary-200 active:text-primary-200 text-xs font-normal'
        >
          Previous
        </button>
        <button
          type='button'
          onClick={closeGuide}
          className='bg-primary-300 hover:bg-primary-200 active:bg-primary-200 text-primary-700 w-[100px] h-[30px] rounded-md'
        >
          Got it!
        </button>
      </div>

      <div
        className='absolute w-0 h-0'
        style={{
          borderTop: '24px solid transparent',
          borderBottom: '24px solid transparent',
          borderLeft: '24px solid rgb(var(--primary-700))',
          top: '30%',
          left: '100%',
        }}
      />
    </div>
  )

  useEffect(() => {
    highlightElement('later-column', component)

    return () => {
      resetHighlighting()
    }
  }, [])

  return <Overlay className='hidden xs:flex' isOpen={true} />
}
