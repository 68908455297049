import { useRef } from 'react'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { useToasts } from '../../context/ToastsContext'
import useAuth, { STATE_LOGGING_IN, STATE_NOT_AUTHENTICATED } from '../../hooks/useAuth'

const SignIn = () => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const addToast = useToasts()
  const auth = useAuth()
  const formRef = useRef(null)

  if (auth.initialized && auth.isAuthenticated) {
    return <Navigate to='/' />
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (loading) return
    const method = e.nativeEvent.submitter.name || 'register/login'
    const user = Object.fromEntries(
      [...e.target.elements]
        .filter(e => e.type && e.type !== 'submit' && e.name)
        .map(e => [e.name, e.value]),
    )
    try {
      setLoading(true)
      if (method === 'register/login') {
        try {
          await auth.register(user.email)
        } catch (err) {
          if (err.name !== 'UsernameExistsException') {
            throw err
          }
          await auth.signIn(user.email)
        }
      }
      if (method === 'answerCustomChallenge') {
        await auth.answerChallenge(user.code)
      }
    } catch (err) {
      setError(err)
      addToast({
        description: err?.message || 'An unknown error occurred, please contact us!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
      formRef.current?.reset()
    }
  }
  /**
   * @type {import("react").FormEventHandler<HTMLInputElement}
   */
  const onInvalid = e => {
    let errorMessage
    try {
      errorMessage = e.target.dataset.errorMessage
    } catch (err) {
      errorMessage = `Something went wrong: ${err.message}`
    } finally {
      addToast({
        description: errorMessage || 'An unknown error occurred, please contact us!',
        variant: 'error',
      })
    }
  }

  const isLoggingIn = auth.state === STATE_LOGGING_IN

  return (
    <>
      <h2 className='text-white font-display font-medium text-3xl'>Sign In or Sign Up</h2>
      <p className='text-white font-display mt-4'>
        {isLoggingIn ? 'Check your email' : 'Email'}
      </p>

      <form onSubmit={onSubmit} ref={formRef}>
        <small className='text-white text-xs font-light font-display block mb-3'>
          We'll send a verification code to this email.
        </small>

        <input
          className={`min-h-[50px] px-5 py-2.5 bg-white-bg rounded-md mb-5 text-xs font-display w-full outline-none
              transition-all focus:border-[3px] focus:border-[#BCF4F5]
              ${error ? 'border-red-500' : ''}`}
          placeholder={isLoggingIn ? 'Enter the code' : 'example@example.com'}
          type={isLoggingIn ? 'text' : 'email'}
          name={isLoggingIn ? 'code' : 'email'}
          data-error-message={
            isLoggingIn ? 'Please enter the code' : 'Please enter a valid email address'
          }
          required
          onInvalid={onInvalid}
        />

        {(auth.state === STATE_NOT_AUTHENTICATED || auth.state === STATE_LOGGING_IN) && (
          <button
            className='w-full min-h-[50px] px-5 py-2.5 bg-[#6CD4FF] text-[#013590] rounded-md font-display font-semibold text-center
          disabled:bg-neutral-200 disabled:text-neutral-50 hover:bg-[#BCF4F5]'
            type='submit'
            name={
              auth.state === STATE_NOT_AUTHENTICATED
                ? 'register/login'
                : 'answerCustomChallenge'
            }
          >
            {loading ? (
              <div className='animate-spin rounded-full mx-auto border-2 h-6 w-6 border-transparent border-t-primary' />
            ) : auth.state === STATE_NOT_AUTHENTICATED ? (
              'Send code'
            ) : (
              'Submit'
            )}
          </button>
        )}
      </form>
    </>
  )
}

export { SignIn }
