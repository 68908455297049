import { apiFetch } from '.'

export async function calendarSync() {
  const response = await apiFetch('/api/calendar-sync', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.ok) {
    const { redirectUrl } = await response.json()
    window.location.href = redirectUrl
  }

  return response
}
