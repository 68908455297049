import { Thumb } from './Thumb'

interface PageThumbsProps {
  numberOfPages: number
  currentPage: number
  goToPage: (page: number) => void
}

export const PageThumbs = ({ numberOfPages, currentPage, goToPage }: PageThumbsProps) => {
  return (
    <div className='flex items-center justify-center gap-2.5'>
      {[...Array(numberOfPages)].map((_, pageNo) => (
        <Thumb
          key={pageNo}
          onClick={() => goToPage(pageNo)}
          isFilled={pageNo < currentPage}
        />
      ))}
    </div>
  )
}
