import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import AuthProvider from './components/AuthProvider'
import RequireAuth from './components/RequireAuth'
import Layout from './components/Layout'
import { ToastsProvider } from './context/ToastsContext'
import Dashboard from './routes/Dashboard'
import { Identify } from './routes/Identify'
import AnswerChallenge from './routes/AnswerChallenge'
import 'react-day-picker/dist/style.css'
import Onboarding from './routes/Onboarding'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { HighlightingProvider } from './providers/HighlightingProvider'
import { NavbarTabLayoutProvider } from './providers/LayoutTabProvider'
import { KEY_CAREER_TAB, KEY_TODOS_TAB } from './constants'
import CareerTabs from './components/Tabs/CareerTabs'
import TodoTabs from './components/Tabs/TodoTabs'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: (
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        ),
        children: [
          {
            path: 'home',
            handle: {
              tabId: KEY_TODOS_TAB,
            },
            element: <TodoTabs />,
          },
          {
            path: 'career',
            handle: {
              tabId: KEY_CAREER_TAB,
            },
            element: <CareerTabs />,
          },
        ],
      },
      {
        path: 'answer-challenge',
        element: <AnswerChallenge />,
      },
    ],
  },
  {
    path: '/identify',
    element: <Identify />,
  },
  {
    path: '/onboarding',
    element: <Onboarding />,
  },
])

const queryClient = new QueryClient()

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ToastsProvider>
            <HighlightingProvider>
              <NavbarTabLayoutProvider>
                <RouterProvider router={router} />
              </NavbarTabLayoutProvider>
            </HighlightingProvider>
          </ToastsProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </AuthProvider>
      </QueryClientProvider>
    </DndProvider>
  )
}

export default App
