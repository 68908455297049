import { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import useAuth from '../../hooks/useAuth'
import { useResponsiveWindow } from '../../hooks/useResponsiveWindow'
import { CalendarConnect } from '../CalendarSync'
import { Modal } from '../common/Modal'
import { AddDueDates } from './AddDueDates'
import { Footer } from './Footer'
import { Header } from './Header'
import { StepOne } from './StepOne'
import { SyllabusUpload } from './SyllabusUpload'
import { TextReminders } from './TextReminders'
import { Carousel } from './Carousel'
import { MobileAddDueDate } from './MobileAddDueDate'
import classNames from 'classnames'

const STEPS = [
  <StepOne />,
  <CalendarConnect />,
  <TextReminders isOnboarding={true} />,
  <SyllabusUpload />,
  <MobileAddDueDate />,
]

export const GuidedTour = () => {
  const {
    user: { id: userId, guidedTourComplete },
    completeGuidedTour,
  } = useAuth()
  const { isMobile } = useResponsiveWindow()
  const [isGuidedTourOpen, setIsGuidedTourOpen] = useState(!guidedTourComplete)
  const [currentStep, setCurrentStep] = useLocalStorage('guided_tour_current_step', 0)

  const isLastStep = currentStep === STEPS.length - 1
  const isModalOpen = isGuidedTourOpen && (isMobile || !isLastStep)

  const goToNextStep = event => {
    event.preventDefault()

    if (currentStep + 1 === STEPS.length) {
      return
    }

    setCurrentStep(currentStep => currentStep + 1)
  }

  const goBackToPreviousStep = event => {
    event.preventDefault()

    if (currentStep === 0) {
      return
    }

    setCurrentStep(currentStep => currentStep - 1)
  }

  const goToPage = step => {
    if (step > STEPS.length) {
      return
    }

    setCurrentStep(step + 1)
  }

  const completeTour = async () => {
    setIsGuidedTourOpen(false)

    await completeGuidedTour({ userId })
  }

  const closeModal = async event => {
    event && event.preventDefault()

    if (isMobile && isLastStep) {
      return await completeTour()
    }

    setCurrentStep(STEPS.length - 1)
  }

  const CURRENT_STEP = STEPS[currentStep]

  if (isLastStep && isGuidedTourOpen && !isMobile) {
    return <AddDueDates onClose={completeTour} goPrevious={goBackToPreviousStep} />
  }

  if (!CURRENT_STEP) {
    return null
  }

  return (
    <Modal
      isOpen={isModalOpen}
      titleEl={<Header currentStep={currentStep} />}
      onClose={closeModal}
      className={classNames(
        'bg-white-bg font-display w-screen max-h-[70vh] overflow-auto pt-4 pb-16 px-7 rounded-t-[20px] xs:pt-6 xs:px-9 xs:pb-8 xs:rounded-[20px] xs:w-screen-1/2',
      )}
    >
      <div className='flex flex-col justify-between gap-7'>
        {CURRENT_STEP}
        <Footer
          currentStep={currentStep}
          goNext={goToNextStep}
          goPrevious={goBackToPreviousStep}
        />

        {currentStep > 0 ? (
          <Carousel
            numberOfPages={STEPS.length - 1}
            currentPage={currentStep}
            goNext={goToNextStep}
            goPrevious={goBackToPreviousStep}
            goToPage={goToPage}
            complete={completeTour}
            showPreviousButton={currentStep > 1}
          />
        ) : null}
      </div>
    </Modal>
  )
}
