import Toggle from '../../../Toggle'

export const SmsToggle = ({
  loading,
  smsEnabled,
  toggleSmsNotifications,
  smsToggleText,
}) => {
  return (
    <div
      className='flex items-center gap-2.5 px-2 py-4 active:bg-primary-600/10'
      onClick={() => {
        if (loading) return
        toggleSmsNotifications()
      }}
    >
      <span className='grow'>Receive text notifications</span>
      <Toggle
        disabled={loading}
        checked={smsEnabled}
        onChange={toggleSmsNotifications}
        title={smsToggleText}
        srText={smsToggleText}
        className='self-end'
      />
    </div>
  )
}
