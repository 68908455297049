import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import SectionHeader, { SECTION_HEADER_TEXT_COLORS } from '../SectionHeader'
import { CARD_MAX_HEIGHT, CARD_MIN_WIDTH, ProblemItem } from './ProblemItem'

import { ReactComponent as BottomLeftBackground } from './assets/bottom-left.svg'
import { ReactComponent as PurpleDotsBackground } from '../assets/purple-dots.svg'
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus_bold.svg'
import { Modal } from '../../../common/Modal'
import ProblemModal from './ProblemModal'
import { getDifficultyLevels } from '../PracticeSkills/ProblemAssessment/utils'

export const difficulties = getDifficultyLevels()

/**
 *
 * @param {object} param0
 * @param {import('react-query').UseQueryResult<object[]>} param0.problemListQuery
 */
const Problems = ({ problemListQuery }) => {
  const gridRef = useRef()
  const [columns, setColumns] = useState(3)
  const [viewAllProblems, setViewAllProblems] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [editingProblemId, setEditingProblemId] = useState()

  const { data: problems = [] } = problemListQuery

  useEffect(() => {
    const handleResize = () => {
      const el = gridRef.current
      // 40 is the gap size in pixels 40px === 2.5rem === gap-10
      const minWidthForThreeCols = 2 * 40 + CARD_MIN_WIDTH * 3
      const minWidthForTwoCols = 40 + CARD_MIN_WIDTH * 2
      setColumns(
        el.offsetWidth >= minWidthForThreeCols
          ? 3
          : el.offsetWidth >= minWidthForTwoCols
          ? 2
          : 1,
      )
    }
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const numberOfRows = columns === 1 ? 3 : 2
  const visibleOffset = numberOfRows * columns - 1

  const visibleProblems = viewAllProblems ? problems : problems.slice(0, visibleOffset)

  return (
    <section className='relative bg-primary-700 px-56 pt-[90px] pb-[70px] font-display not-italic leading-[120%]'>
      <BottomLeftBackground className='absolute bottom-0 right-0' />
      <PurpleDotsBackground className='absolute -top-6 right-40' />
      <SectionHeader
        sectionNumber={3}
        color={SECTION_HEADER_TEXT_COLORS.white}
        title='Continue prepping for interviews'
        subtitle={
          <span>
            Practicing data structures and algorithms questions will set you up well for
            future coding challenges.
            <br />
            We recommend 2x a week!
          </span>
        }
      />
      <div className='font-sans text-primary-200 text-xl'>
        Log the problems you’ve practiced here each week and your learnings
      </div>
      <div
        ref={gridRef}
        role='grid'
        className='grid gap-10 mb-40'
        style={{
          gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
        }}
      >
        <button
          onClick={() => {
            setModalOpen(true)
            setEditingProblemId()
          }}
          className='flex flex-col justify-center items-center gap-3 font-sans bg-black/5 hover:bg-black/20 rounded-xl border-2 border-dashed border-spacing-8 border-primary-300 shadow-md shadow-primary-700/20'
          style={{ height: CARD_MAX_HEIGHT }}
        >
          <span className='p-3 rounded-full bg-black/30'>
            <PlusIcon className='text-primary-300 h-5' />
          </span>
          <div className='text-white text-xl'>
            Add new
            <br />
            problem
          </div>
        </button>
        {visibleProblems.map((problem, key) => (
          <ProblemItem
            key={key}
            problem={problem}
            onEdit={() => {
              setModalOpen(true)
              setEditingProblemId(problem.id)
            }}
          />
        ))}
        {problems.length > visibleOffset && (
          <div className='col-span-full text-center'>
            <button className='text-white' onClick={() => setViewAllProblems(v => !v)}>
              View {viewAllProblems ? 'less' : 'more'}
            </button>
          </div>
        )}
      </div>
      <Modal
        className='bg-white-bg pt-6 px-9 pb-8 min-h-[346px] rounded-[20px] w-[652px] max-sm:w-screen max-h-screen font-display'
        titleEl={
          <h1 className='text-2xl font-bold font-display text-primary-700'>
            {!editingProblemId ? 'Add a new problem' : 'Edit problem'}
          </h1>
        }
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setEditingProblemId()
        }}
      >
        <ProblemModal
          problem={editingProblemId && problems.find(p => p.id === editingProblemId)}
          onSubmitted={() => setModalOpen(false)}
        />
      </Modal>
    </section>
  )
}

export default Problems
