import { ChevronRightIcon } from '@heroicons/react/20/solid'

interface RightArrowProps {
  onClick: () => void
}

export const RightArrow = ({ onClick }: RightArrowProps) => {
  return (
    <button
      className='flex items-center justify-center w-10 h-10 bg-primary-300 rounded-full'
      type='button'
      onClick={onClick}
      aria-label='Go to next step'
    >
      <ChevronRightIcon className='text-primary-700 h-7' />
    </button>
  )
}
