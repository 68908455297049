import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import useAuth from '../../hooks/useAuth'
import MetricsApi from '../../services/MetricsApi'
import {
  AddCompleteTodoCountMetricOperation,
  AddEditTodoCountMetricOperation,
} from '../../utils/metrics'
import todoApi from '../../utils/api/todo'
import { getCustomTimeHeader, to0001, to0800 } from '../../utils/time'
import { binaryInsert } from '../../utils/arrays'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createExponentialBackOffFunction } from '../../utils/functions'
import {
  closestDueTodosFirst,
  constructNewDateForTodoInColumn,
  getTodoDateValue,
  getTodoWeightFromDirectSiblings,
  sortOrderTodoWeightAndTodoDate,
} from './utils'
import ApiResponseError from '../../utils/errors/ApiResponseError'
import { GoogleCalendarSyncModal } from './GoogleCalendarSyncModal'
import { DAY_IN_MS, WEIGHT_INCREMENT } from '../../constants'
import { TodoContext } from './context'

/**
 * @type {import('react').Context<{
 *   lastEditedTodoId: null | string,
 *   setLastEditedTodoId: React.Dispatch<React.SetStateAction<null | string>>,
 *   editedWithStartDateRef: React.MutableRefObject<boolean>
 * }>}
 */
const LastEditedTodoContext = createContext()

function useLastEditedTodo() {
  const context = useContext(LastEditedTodoContext)
  if (context === undefined) {
    throw new Error('useLastEditedTodo must be used within a TodoProvider')
  }
  return context
}

function useTodos() {
  const context = useContext(TodoContext)
  if (context === undefined) {
    throw new Error('useTodos must be used within a TodoProvider')
  }
  return context
}

/**
 * @typedef {{
 *   open: boolean,
 *   setOpen: import('react').Dispatch<import('react').SetStateAction<boolean>>
 * }} GoogleCalendarSyncModalContextValue
 *
 * @type {import('react').Context<GoogleCalendarSyncModalContextValue>}
 */
const GoogleCalendarSyncModalContext = createContext()

function useGoogleCalendarSyncModal() {
  const context = useContext(GoogleCalendarSyncModalContext)
  if (context === undefined) {
    throw new Error('useGoogleCalendarSyncModal must be used within a TodoProvider')
  }
  return context
}

const TodoProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { isAuthenticated, userId, user } = useAuth()
  const { data: userTodos, loading: loadingTodos } = useQuery(
    ['todos', userId],
    ({ signal }) => todoApi.getUserList(userId, { signal }),
    {
      enabled: isAuthenticated,
      initialData: [],
    },
  )
  const [lastEditedTodoId, setLastEditedTodoId] = useState(null)
  const editedWithStartDateRef = useRef(false)

  const queryClient = useQueryClient()
  const timeoutRef = useRef()
  useEffect(() => {
    // add timeout for next day at 8am in order to remove any day old todos
    const tomorrowAt8am = () => to0800().getTime() + DAY_IN_MS + 1 - Date.now()
    const timeoutDuration = tomorrowAt8am()
    const forceUpdateTodos = () => {
      // forces a state change to catch any day old todos
      queryClient.invalidateQueries(['todos', userId])
      // create a new timeout for the next day
      const timeoutDuration = tomorrowAt8am()
      const timeoutId = setTimeout(forceUpdateTodos, timeoutDuration)
      timeoutRef.current = timeoutId
    }
    const timeoutId = setTimeout(forceUpdateTodos, timeoutDuration)
    timeoutRef.current = timeoutId

    return () => clearTimeout(timeoutRef.current)
  }, [userId, queryClient])

  // computed property for determining renderable todos
  const showCompletedTasks = user?.preferences?.showCompletedTasks || false

  const allNonPastDueTodosWithDueDatesSorted = useMemo(() => {
    const todosAll = []
    const insertOptions = { duplicate: true }
    const today = to0001()

    for (let index = 0; index < userTodos.length; index++) {
      const todo = { ...userTodos[index], index }
      // don't include if the todo doesn't have a due date
      if (!todo.dueDate) continue

      const todoDate = to0001(todo.dueDate)
      const pastDue = todoDate.getTime() < today.getTime()

      // excluding todos that dont meet the criteria
      if (
        // the todo is past due
        pastDue ||
        // the todo is deleted
        todo.isDeleted ||
        // the todo is completed and we don't want to show completed todos
        (todo.isComplete && !showCompletedTasks) ||
        // the todo is complete, and was last updated at least one day ago at 8am
        (todo.isComplete &&
          to0800().getTime() - new Date(todo.updatedAt).getTime() > DAY_IN_MS)
      ) {
        continue
      }

      binaryInsert(todosAll, { ...todo }, sortOrderTodoWeightAndTodoDate, insertOptions)
    }
    return {
      todosAll,
    }
  }, [userTodos, showCompletedTasks])

  const userTodosWithDueDates = useMemo(
    () =>
      userTodos
        .filter(
          t =>
            // order of operation here is important. doesn't work without the ()
            t.dueDate && (showCompletedTasks ? true : !t.isComplete),
        )
        .sort(closestDueTodosFirst()),
    [userTodos, showCompletedTasks],
  )

  const getTodoDate = todo => {
    const today = to0001()
    const todoDate = to0001(getTodoDateValue(todo))
    const pastDue = getTodoDateValue(todo) && todoDate.getTime() < today.getTime()
    const todoHeader = getCustomTimeHeader(getTodoDateValue(todo))

    if (pastDue || todoHeader === 'today') {
      return to0001().getTime()
    }

    if (todoHeader === 'tomorrow') {
      return new Date(to0001().getTime() + DAY_IN_MS).getTime()
    }

    return getTodoDateValue(todo)
  }

  const getLastWeight = date => {
    const header = getCustomTimeHeader(date)
    const todoWeightList =
      header === 'today'
        ? filteredTodos.todosToday
        : header === 'tomorrow'
        ? filteredTodos.todosTomorrow
        : filteredTodos.todosLater
    const lastWeight =
      todoWeightList.length > 0 ? todoWeightList[todoWeightList.length - 1].weight : 0

    return { lastWeight, lastIncrementedWeight: lastWeight + WEIGHT_INCREMENT }
  }

  const getNewTodoWeight = todo => {
    const todoDate = getTodoDate(todo)

    const { lastIncrementedWeight: weight } = getLastWeight(todoDate)

    return weight
  }

  const isFirstColumnTodo = ({ header = 'today', todoId }) => {
    const todosList =
      header === 'today'
        ? filteredTodos.todosToday
        : header === 'tomorrow'
        ? filteredTodos.todosTomorrow
        : filteredTodos.todosLater
    const firstColumnTodoId = todosList.length && todosList[0].id

    return firstColumnTodoId === todoId
  }

  const filteredTodos = useMemo(() => {
    const todosToday = []
    const todosTomorrow = []
    const todosLater = []

    const today = to0001()
    for (let index = 0; index < userTodos.length; index++) {
      const todo = { ...userTodos[index], index }
      const todoDate = to0001(getTodoDateValue(todo))
      const pastDue = getTodoDateValue(todo) && todoDate.getTime() < today.getTime()
      if (
        // the todo is deleted
        todo.isDeleted ||
        // the todo is completed and we don't want to show completed todos
        (todo.isComplete && !showCompletedTasks) ||
        // the todo is complete, and was last updated at least one day ago at 8am
        (todo.isComplete &&
          to0800().getTime() - new Date(todo.updatedAt).getTime() > DAY_IN_MS)
      )
        continue

      const todoHeader = getCustomTimeHeader(getTodoDateValue(todo))
      const insertOptions = { duplicate: true }
      if (pastDue || todoHeader === 'today')
        binaryInsert(
          todosToday,
          { ...todo, computedInTodoColumnId: 'today' },
          sortOrderTodoWeightAndTodoDate,
          insertOptions,
        )
      else if (todoHeader === 'tomorrow')
        binaryInsert(
          todosTomorrow,
          {
            ...todo,
            computedInTodoColumnId: 'tomorrow',
          },
          sortOrderTodoWeightAndTodoDate,
          insertOptions,
        )
      else
        binaryInsert(
          todosLater,
          { ...todo, computedInTodoColumnId: 'later' },
          sortOrderTodoWeightAndTodoDate,
          insertOptions,
        )
    }

    return {
      todosToday,
      todosTomorrow,
      todosLater,
    }
  }, [userTodos, showCompletedTasks])

  return (
    <LastEditedTodoContext.Provider
      value={{ lastEditedTodoId, setLastEditedTodoId, editedWithStartDateRef }}
    >
      <GoogleCalendarSyncModalContext.Provider
        value={{ open: modalOpen, setOpen: setModalOpen }}
      >
        <TodoContext.Provider
          value={{
            loadingTodos,
            userTodos,
            filteredTodos,
            allNonPastDueTodosWithDueDatesSorted,
            userTodosWithDueDates,
            getTodoDate,
            getLastWeight,
            getNewTodoWeight,
            isFirstColumnTodo,
          }}
        >
          {children}
          <GoogleCalendarSyncModal open={modalOpen} onClose={() => setModalOpen(false)} />
        </TodoContext.Provider>
      </GoogleCalendarSyncModalContext.Provider>
    </LastEditedTodoContext.Provider>
  )
}

function useCreateTodoMutation({
  mutationKey,
  onError = async error => error,
  onSuccess = async newTodo => newTodo,
} = {}) {
  const { setOpen } = useGoogleCalendarSyncModal()
  const { userId, user } = useAuth()
  const queryClient = useQueryClient()

  const userCalendarSynced = user?.preferences?.isCalendarSynced

  return useMutation(todoApi.post, {
    mutationKey,
    onMutate: async newTodo => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['todos', userId])
      // optimistically update the todos so there's no lag on the frontend
      const previousTodos = queryClient.getQueryData(['todos', userId])
      queryClient.setQueryData(['todos', userId], old => [...old, newTodo])
      return { previousTodos }
    },
    onSettled: async (newTodo, error, _variables, context) => {
      queryClient.invalidateQueries(['todos', userId])

      if (error) {
        // if fails, revert to the previous todos
        queryClient.setQueryData(['todos', userId], context.previousTodos)
        return await onError(error)
      } else {
        MetricsApi.queue({
          userId,
          operation: new AddEditTodoCountMetricOperation(1),
        })

        if (newTodo?.redirectUrl) {
          // If response is a redirect url, user needs to re-authenticate
          return (window.location.href = newTodo.redirectUrl)
        }
        const userTodos = queryClient.getQueryData(['todos', userId])
        if (userTodos?.length === 0 && !userCalendarSynced) {
          setOpen(true)
        }

        return await onSuccess(newTodo)
      }
    },
  })
}

function useUpdateTodoMutation() {
  const { userId } = useAuth()
  const queryClient = useQueryClient()
  const { setLastEditedTodoId } = useLastEditedTodo()

  return useMutation(todoApi.put, {
    onMutate: async todoToUpdate => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['todos', userId])

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData(['todos', userId])
      const previousTodoVersion = previousTodos.find(todo => todo.id === todoToUpdate.id)

      // Optimistically update to the new value
      queryClient.setQueryData(['todos', userId], prev => {
        const todoList = prev.map(obj =>
          obj.id === todoToUpdate.id ? todoToUpdate : obj,
        )
        // just incase the todo didn't exist prior
        if (!todoList.includes(todoToUpdate)) todoList.push(todoToUpdate)
        return todoList
      })

      // Return a context object with the snapshotted value
      return { previousTodos, previousTodoVersion }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(['todos', userId], context.previousTodos)
      queryClient.invalidateQueries(['todos', userId])
      return error
    },
    onSuccess: (updatedTodo, _, context) => {
      queryClient.invalidateQueries(['todos', userId])
      const jobs = [
        {
          userId,
          operation: new AddEditTodoCountMetricOperation(1),
        },
      ]
      if (updatedTodo.isComplete && !context.previousTodoVersion.isComplete) {
        jobs.push({
          userId,
          operation: new AddCompleteTodoCountMetricOperation(1),
        })
      }
      MetricsApi.queue(...jobs)

      if (updatedTodo?.redirectUrl) {
        // If response is a redirect url, user needs to re-authenticate
        return (window.location.href = updatedTodo.redirectUrl)
      }

      setLastEditedTodoId(updatedTodo.id)
      queryClient.setQueryData(['todos', userId], prev => {
        const todoList = prev.map(obj => (obj.id === updatedTodo.id ? updatedTodo : obj))
        // just incase the todo didn't exist prior
        if (!todoList.includes(updatedTodo)) todoList.push(updatedTodo)
        return todoList
      })
    },
  })
}

const batchUpdate = async todoUpdatesList => {
  let response = await todoApi.patch(todoUpdatesList)
  let body = await response.json()

  /**
   * We create a backoff function for the batch update function since
   * any unprocessed todos will be returned in the response.
   *
   * returned items can be caused by either exceeding the write throughtput set in ddb
   * or because there were too many items to process in one request.
   *
   * To account for exceeding the write throughput, we will exponentially delay consecutive batch update requests
   * in order to not overload the api server and avoid other errors.
   *
   * so calling the backOffFunction will result in the following delays
   * backOffFunction() -> delays for ~1s
   * backOffFunction() -> delays for ~2s
   * backOffFunction() -> delays for ~4s
   *
   * This gives the backend time to not exceed the write throughput cap.
   */
  const backOffFunction = createExponentialBackOffFunction(async backOffTodos => {
    const response = await todoApi.patch(backOffTodos)
    const body = await response.json()
    return {
      response,
      body,
    }
  })
  while (response.ok && body.unprocessedTodos.length > 0) {
    ;({ response, body } = await backOffFunction(body.unprocessedTodos))
  }

  if (!response.ok) throw new ApiResponseError({ response, body })
}

function useMoveTodoMutation({ mutationKey } = {}) {
  const { userId } = useAuth()
  const { userTodos } = useTodos()
  const queryClient = useQueryClient()

  const handleMove = async ({
    todoToUpdate,
    toPositionIndex,
    weightIncrement,
    todoIdList,
    formId,
  }) => {
    const todos = todoIdList.map(id => userTodos.find(todo => todo.id === id))
    const { before, newWeight, after } = getTodoWeightFromDirectSiblings(
      todos,
      toPositionIndex,
      weightIncrement,
    )
    const weightCollisionDetected = newWeight === before || newWeight === after

    const todoDraggedId = todoToUpdate.id
    const todoDate = constructNewDateForTodoInColumn(todoToUpdate, formId)
    // update a single weight if its neighbours have different weight
    if (toPositionIndex >= todos.length || !weightCollisionDetected) {
      // do not pass the old todoDate properties to the API
      const {
        date: _,
        legacyDate: __,
        ...todo
      } = userTodos.find(todo => todo.id === todoDraggedId)
      const newTodo = { ...todo, weight: newWeight, todoDate }
      return await todoApi.put(newTodo)
    } else {
      const index = todos.findIndex(todo => todo.id === todoDraggedId)
      if (index > -1) {
        todos.splice(index, 1)
        todos.splice(
          toPositionIndex -
            // offsets the insertion index by one if the todo's
            // original index was before it's new one.
            (index < toPositionIndex),
          0,
          todoToUpdate,
        )
      } else {
        // insert if it didn't exist in the todo list before
        todos.splice(toPositionIndex, 0, todoToUpdate)
      }

      const reorderedTodos = todos
      // we detected a weight collision, rebalance the weights
      const rebalancedWeightedTodos = reorderedTodos.map(
        // do not pass the old todoDate properties to the API
        ({ date, legacyDate, ...todo }, index) => {
          const baseUpdate = {
            ...todo,
            weight: (index + 1) * weightIncrement,
          }
          if (
            todo.id === todoToUpdate.id &&
            todoToUpdate.computedInTodoColumnId !== formId
          )
            return { ...baseUpdate, todoDate }
          return { ...baseUpdate, date, legacyDate }
        },
      )
      return await batchUpdate(rebalancedWeightedTodos)
    }
  }

  return useMutation(handleMove, {
    mutationKey,
    onMutate: async ({
      todoToUpdate,
      toPositionIndex,
      weightIncrement,
      todoIdList,
      formId,
    }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['todos', userId])

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData(['todos', userId])

      const todos = todoIdList.map(id => userTodos.find(todo => todo.id === id))
      const { before, newWeight, after } = getTodoWeightFromDirectSiblings(
        todos,
        toPositionIndex,
        weightIncrement,
      )
      const weightCollisionDetected = newWeight === before || newWeight === after

      // Optimistically update the single todo
      if (!weightCollisionDetected) {
        const todoId = todoToUpdate.id
        const todoDate = constructNewDateForTodoInColumn(todoToUpdate, formId)
        const update = {
          weight: newWeight,
          todoDate,
        }
        // Optimistically update to the new value
        queryClient.setQueryData(['todos', userId], prevTodos =>
          prevTodos.map(
            // do not pass the old todoDate properties to the API
            ({ date, legacyDate, ...todo }) =>
              todo.id === todoId ? { ...todo, update } : { ...todo, date, legacyDate },
          ),
        )
      }

      return { previousTodos, weightCollisionDetected }
    },
    onSuccess: (data, _variables, context) => {
      MetricsApi.queue({
        userId,
        operation: new AddEditTodoCountMetricOperation(1),
      })

      // Single todo update to ensure metadata freshness
      if (!context.weightCollisionDetected)
        queryClient.setQueryData(['todos', userId], prevTodos =>
          prevTodos.map(todo => (todo.id === data.id ? data : todo)),
        )
    },
    onSettled: (_, error, _variables, context) => {
      // invalidate on either a batch update or error to
      // try and fix the error on repeat operation
      if (error || context.weightCollisionDetected) {
        queryClient.invalidateQueries(['todos', userId])
      }
    },
  })
}

export {
  TodoProvider,
  useTodos,
  useLastEditedTodo,
  useCreateTodoMutation,
  useUpdateTodoMutation,
  useMoveTodoMutation,
}
