export function getCustomTimeHeader(utcDate) {
  if (!utcDate) {
    return 'later'
  }
  const todoUtc = new Date(utcDate)

  const today = new Date()

  let tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  //get & format the Date object's month, day, and year for comparison to determine headers
  const todayDate = [today.getMonth(), today.getDate(), today.getFullYear()].join('/')
  const tomorrowDate = [
    tomorrow.getMonth(),
    tomorrow.getDate(),
    tomorrow.getFullYear(),
  ].join('/')
  const todoDate = [todoUtc.getMonth(), todoUtc.getDate(), todoUtc.getFullYear()].join(
    '/',
  )

  let todoHeader = ''

  if (todoDate === todayDate) {
    todoHeader = 'today'
  } else if (todoDate === tomorrowDate) {
    todoHeader = 'tomorrow'
  } else {
    todoHeader = 'later'
  }

  return todoHeader
}

/**
 * This function creates a new date object with it's time
 * adjusted to 8am local time.
 *
 * This utility function is used to get the 8am date quickly
 * for conditionally rendering reminders.
 *
 * @param {Date} date date
 * @returns the date 8am
 */
export function to0800(date = new Date()) {
  const clone = new Date(date)
  clone.setHours(8, 0, 0, 0)
  return clone
}

/**
 * This function creates a new date object with it's time
 * adjusted to 12:01am local time.
 *
 * This utility function is used for formatting the time for
 * both a todo's/reminder's due date to ensure uniform ddb entries.
 *
 * @param {Date} date date
 * @returns the date 12:01am
 */
export function to0001(date = new Date()) {
  const clone = new Date(date)
  clone.setHours(0, 1, 0, 0)
  return clone
}

/**
 * his function creates a new date object with it's time
 * adjusted to 12:59:59:999pm local time.
 *
 * This utility function is used in order to apply conditional
 * rendering on reminder due dates to check if it's before the
 * output date of this function.
 *
 * @param {Date} date date
 * @returns the date 12:59:59:999pm
 */
export function to2359(date = new Date()) {
  const clone = new Date(date)
  clone.setHours(23, 59, 59, 999)
  return clone
}
