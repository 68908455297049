import RadioInput from '../../../../RadioInput'
import { useDefaultInterviewPrepProblemListQuery } from '../../hooks/useDefaultInterviewPrepProblemListQuery'
import { useWriteInterviewPrepProblemMutation } from '../../hooks/useWriteInterviewPrepProblemMutation'
import Notes from './Notes'
import { getDifficultyLevels } from './utils'

const difficultyLevels = getDifficultyLevels()

const ProblemAssessment = ({ problem }) => {
  const { data: defaultProblems } = useDefaultInterviewPrepProblemListQuery()

  const shouldCreateProblem =
    defaultProblems.some(dProblem => dProblem.id === problem.id) &&
    !problem.difficulty &&
    !problem.notes

  const updateProblemMutation = useWriteInterviewPrepProblemMutation(
    shouldCreateProblem,
    false,
  )

  const updateDifficulty = difficulty => {
    const { notes, ...remainingProblemAttributes } = problem

    updateProblemMutation.mutate({ ...remainingProblemAttributes, difficulty })
  }

  const updateNotes = notes => {
    const { difficulty, ...remainingProblemAttributes } = problem

    updateProblemMutation.mutate({ ...remainingProblemAttributes, notes })
  }

  return (
    <div className='font-display not-italic leading-[120%]'>
      <div className='flex flex-col items-start gap-2.5 mb-5'>
        <h6 className='text-primary-black text-sm font-medium'>
          How hard was this problem for you?
        </h6>

        {difficultyLevels.map(({ id, value, label }) => (
          <RadioInput
            key={id}
            id={id}
            name='difficulty'
            label={label}
            checked={problem.difficulty === value}
            onClick={() => updateDifficulty(value)}
          />
        ))}
      </div>
      <div className='flex flex-col items-start'>
        <p className='text-primary-black text-sm font-normal'>
          Notes on the problem (for your future self!)
        </p>
        <Notes notes={problem.notes} updateNotes={updateNotes} />
      </div>
    </div>
  )
}

export default ProblemAssessment
