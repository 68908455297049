import React, { useState } from 'react'

import { Modal } from '../common/Modal'
import { FileUpload } from './FileUpload'
import { CalendarSync } from './CalendarSync'

const NavButton = ({ text, onClick, disabled }) => (
  <button
    className='py-2.5 px-4 bg-primary/10 text-primary text-sm font-medium rounded-xl border border-solid border-primary enabled:hover:bg-primary/20 disabled:border-[#DFDFE1] disabled:text-[#DFDFE1] disabled:bg-[#F9F9F9]'
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
)

const PromoPopup = ({ show, close, openFileUploader }) => {
  const [step, setStep] = useState(1)

  const titleEl = (
    <small className='text-base font-medium font-display text-[#C0BEC7]'>
      <span className='text-[#6B667B]'>{step}</span>/2
    </small>
  )

  const handleFileUpload = () => {
    close()
    openFileUploader()
  }

  return (
    <Modal isOpen={show} onClose={close} titleEl={titleEl}>
      <>
        {step === 1 && <FileUpload handleFileUpload={handleFileUpload} />}

        {step === 2 && <CalendarSync />}

        <div className='flex justify-between items-center'>
          <NavButton
            text={'Prev.'}
            onClick={() => setStep(step - 1)}
            disabled={step <= 1}
          />

          <div className='flex gap-2 items-center'>
            {[1, 2].map(i => (
              <span
                key={i}
                className={`h-2 w-2 rounded-full ${
                  i === step ? 'bg-[#999999]' : 'bg-[#DFDFE1]'
                }`}
              />
            ))}
          </div>

          <NavButton
            text={'Next'}
            onClick={() => setStep(step + 1)}
            disabled={step >= 2}
          />
        </div>
      </>
    </Modal>
  )
}

export { PromoPopup }
