import { createContext } from 'react'
import { TodoInterface } from '../../components/Todo/types'

type FirstColumnArgs = { todoId: string } | { header: string; todoId: string }

interface TodoContextValue {
  loadingTodos: boolean
  userTodos: TodoInterface[]
  filteredTodos: {
    todosToday: TodoInterface[]
    todosTomorrow: TodoInterface[]
    todosLater: TodoInterface[]
  }
  allNonPastDueTodosWithDueDatesSorted: {
    todosAll: TodoInterface[]
  }
  userTodosWithDueDates: TodoInterface[]
  getTodoDate: (todo: TodoInterface) => {}
  getLastWeight: (date: number | string | Date) => {
    lastWeight: number
    lastIncrementedWeight: number
  }
  getNewTodoWeight: (todo: TodoInterface) => number
  isFirstColumnTodo: (args: FirstColumnArgs) => boolean
}

export const TodoContext = createContext<undefined | TodoContextValue>(undefined)
