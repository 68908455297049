import { useRef, useEffect } from 'react'
import { useSlate, useFocused, ReactEditor } from 'slate-react'
import { Editor, Path, Range, Transforms } from 'slate'
import LinkAdder from './LinkAdder'
import { RtfFormat } from './RtfFormat'

const HoveringToolbar = () => {
  const linkAdderRef = useRef()
  const editor = useSlate()
  const inFocus = useFocused()
  const selectionRef = useRef()

  const selectedText = editor.selection ? Editor.string(editor, editor.selection) : ''

  useEffect(() => {
    const el = linkAdderRef.current
    const { selection } = editor

    if (!el) {
      return
    }

    if (
      !selectionRef.current &&
      (!selection || !inFocus || Range.isCollapsed(selection) || selectedText === '')
    ) {
      el.removeAttribute('style')
      return
    }

    selectionRef.current = selection

    const domSelection = window.getSelection()
    if (domSelection?.rangeCount === 0) return
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    // eslint-disable-next-line
    const { x, y, width } = rect
    const finalY = y * 0.01 - 50
    const finalX = width / 2 - 16

    el.style.top = `${finalY}px`
    el.style.left = `${finalX}px`
  })

  const isOpen = selectedText !== ''

  const onCancel = () => {
    selectionRef.current = null
    Transforms.collapse(editor, { edge: 'end' })
    ReactEditor.focus(editor)
  }
  const onSubmit = url => {
    Transforms.wrapNodes(editor, RtfFormat.anchor(url), { split: true })
    selectionRef.current = null
    const parentPath = Path.parent(editor.selection.focus.path)
    parentPath[parentPath.length - 1] = parentPath[parentPath.length - 1] + 1
    const point = {
      path: parentPath,
      offset: 0,
    }
    Transforms.select(editor, {
      anchor: point,
      focus: point,
    })
    ReactEditor.focus(editor)
  }

  return (
    <>
      {isOpen && <LinkAdder ref={linkAdderRef} onCancel={onCancel} onSubmit={onSubmit} />}
    </>
  )
}

export default HoveringToolbar
