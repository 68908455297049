import { useCallback, useRef, useState } from 'react'

const isTouchEvent = event => {
  return 'touches' in event
}

const preventDefault = event => {
  if (!isTouchEvent(event)) return

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault()
  }
}

export const longPressExemptProps = () => ({
  'data-not-long-press-targetable': true,
})

const parseBooleanDatasetValue = value => {
  let parsedValue = undefined

  if (value && value.trim().toLowerCase() === 'false') parsedValue = false
  else if (value && value.trim().toLowerCase() === 'true') parsedValue = true

  return parsedValue
}

/**
 *
 * @param {Element} element
 * @returns
 */
const elementHasExemptedParent = element => {
  while (element) {
    if (parseBooleanDatasetValue(element.dataset.notLongPressTargetable)) {
      return true
    }
    element = element.parentElement
  }

  return false
}

export const useLongPress = (
  onLongPress,
  onClick,
  { shouldPreventDefault = true, delay = 1000 } = {},
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false)
  const timeout = useRef()
  const target = useRef()

  const start = useCallback(
    event => {
      if (elementHasExemptedParent(event.target)) return

      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        })
        target.current = event.target
      }
      timeout.current = setTimeout(() => {
        onLongPress(event)
        setLongPressTriggered(true)
      }, delay)
    },
    [onLongPress, delay, shouldPreventDefault],
  )

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      clearTimeout(timeout.current)
      shouldTriggerClick && !longPressTriggered && onClick()
      setLongPressTriggered(false)
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault)
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered],
  )

  return {
    onMouseDown: e => start(e),
    onTouchStart: e => start(e),
    onMouseUp: e => clear(e),
    onMouseLeave: e => clear(e, false),
    onTouchEnd: e => clear(e),
  }
}
