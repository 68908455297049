import { RootProvider } from 'react-day-picker'
import { Root } from './Root'

/**
 *
 * @param {import("react-day-picker").DayPickerDefaultProps
 *  | import("react-day-picker").DayPickerSingleProps
 *  | import("react-day-picker").DayPickerMultipleProps
 *  | import("react-day-picker").DayPickerRangeProps} props
 * @returns
 */
export const Calendar = props => (
  <RootProvider {...props}>
    <Root />
  </RootProvider>
)
